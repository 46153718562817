import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { removeHashtagChar } from '../../helpers'
import styled, { css } from 'styled-components'
import { cssvar } from '../../styles/var'

import TrashIcon from '../../images/Trash.svg'
import SettingsIcon from '../../images/Settings.svg'
import InjuryIcon from '../../images/hospital.svg'
import WorldIcon from '../../images/World.svg'
import HashtagIcon from '../../images/Hashtag.svg'
import DopingIconLight from '../../images/dopingPillLight.svg'
import { continentImages } from '../../config/continentImages'

const ListItem = ({
  tag, image, label, currentInjury, isHashtag, isFullText, customId, onDelete, onEdit, currentPageTag,
}) => {
  const id = tag || customId

  let rowOptions

  if (isFullText) {
    rowOptions = {
      to: `/explore/fulltext/${tag}`,
      profileImage: WorldIcon,
    }
  } else if (isHashtag) {
    rowOptions = {
      to: `/explore/hashtag/${removeHashtagChar(tag)}`,
      profileImage: HashtagIcon,
    }
  } else {
    rowOptions = {
      to: `/explore/${tag}`,
      profileImage: (continentImages[label] ? continentImages[label] : image) || WorldIcon,
    }
  }

  const handleEdit = () => { onEdit(tag) }
  const handleDelete = () => { onDelete(tag, { hashtagDelete: isHashtag }) }

  const renderActions = () => (
    <Actions>
      {onEdit && !isHashtag && <Action image={SettingsIcon} onClick={(e) => { e.preventDefault(); handleEdit() }} />}
      {onDelete && isHashtag && <Action image={TrashIcon} onClick={(e) => { e.preventDefault(); handleDelete() }} />}
    </Actions>
  )

  const { to, profileImage } = rowOptions

  return (
    <ListItemStyled id={id} to={to} disabled={tag === currentPageTag}>
      <Image src={profileImage} alt="" onError={(e) => { e.target.src = WorldIcon }} />
      <ListItemName injury={currentInjury}>{label}</ListItemName>
      {(onDelete || onEdit) && renderActions()}
    </ListItemStyled>
  )
}

ListItem.propTypes = {
  tag: PropTypes.string.isRequired,
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
  currentInjury: PropTypes.object,
  isHashtag: PropTypes.bool,
  isFullText: PropTypes.bool,
  customId: PropTypes.string,
  onEdit: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  onDelete: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
}

ListItem.defaultProps = {
  isHashtag: false,
  isFullText: false,
  image: '',
  currentInjury: null,
  onDelete: false,
  onEdit: false,
}

export default ListItem

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  padding: 10px 0;
  position: absolute;
  top: 0;
  right: 5px;
  opacity: 0;
  transition: .3s;
  @media (max-width: 1024px) {
    opacity: .8;
  }
`
const ListItemStyled = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 5px 10px 15px;
  font-size: 13px;
  color: ${cssvar('mainColor')};
  position: relative;
  transition: .3s;
  ${props => props.disabled && 'pointer-events: none;'}
  &:hover {
    color: ${cssvar('mainColor')};
    background-color: #f0f8fb;
    ${Actions} {
      opacity: 1;
    }
  }
  @media (max-width: 1024px) {
    border-bottom: 1px solid ${cssvar('borderColor')};
    &:hover {
      background-color: transparent;
    }
  }
`
const Image = styled.img`
  flex: 0 0 auto;
  width: 40px;
  margin-right: 15px;
`
const ListItemName = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  ${props => props.injury && css`
    color: ${props.injury._source.injury_category.includes('doping') ? '#FF8B18' : '#C72E1F'};
    font-weight: 550;
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      margin-left: 7px;
      margin-top: 1px;
      background-image: url(${props.injury._source.injury_category.includes('doping') ? DopingIconLight : InjuryIcon});
      background-size: cover;
    }
  `}
`
const Action = styled.div`
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  background-image: url(${props => props.image});
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: .5;
  transition: .3s;
  &:hover {
    opacity: 1;
  }
`