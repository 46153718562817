import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import { withTranslation } from 'react-i18next'
import { Form, Input } from 'antd'
import { checkTicket, resetPassword } from '../../api/auth'
import history from '../../history'
import LoginPage, { Message, FormStyled, FormTitle, FormBody, FormItem, FormLabel, Submit, BackToLogin } from './LoginPage'

const PasswordReset = ({ form, t }) => {
  const [email, setEmail] = useState()
  const [confirmDirty, setConfirmDirty] = useState(false)
  const [resetStatus, setResetStatus] = useState()
  const { ticket } = useParams()
  const { getFieldDecorator } = form

  useEffect(() => {
    const executeCheckTicket = async () => {
      const { status, data: { email: emailFromData } } = await checkTicket(ticket)

      setEmail(emailFromData)
      setResetStatus(status === 200 ? null : 'reset_invalid_token')
    }

    executeCheckTicket()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err && email) {
        try {
          const { data: { status } } = await resetPassword({ ...values, ticket, email })

          if (status === 'success') {
            history.push('/login?success_msg=reset_password_success')
          }
        } catch (error) {
          setResetStatus('reset_generic_error')
        }
      }
    })
  }

  const handleConfirmBlur = (e) => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter are inconsistent!')
    } else {
      callback()
    }
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  return (
    <LoginPage>

      <Message success show={ resetStatus === true }>{ t('reset_password_success') }</Message>
      <Message show={ resetStatus === false }>{ t(resetStatus) }</Message>


      <FormStyled layout="inline" onSubmit={ handleSubmit }>
        <FormTitle>{ t('reset_request_recover_password') }</FormTitle>
        <FormBody>
          <FormItem hasFeedback>
            <FormLabel>{ t('login_password') }</FormLabel>
            { getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  min: 6,
                  message: 'Please input your password'
                },
                { validator: validateToNextPassword }
              ]
            })(<Input.Password />) }
          </FormItem>
          <FormItem placeholder="Confirm Password" hasFeedback>
            <FormLabel>{ t('confirm_password') }</FormLabel>
            { getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  min: 6,
                  message: 'Please confirm your password'
                },
                { validator: compareToFirstPassword }
              ]
            })(<Input.Password onBlur={ handleConfirmBlur } />) }
          </FormItem>
          <Submit type="submit">{ t('reset_request_recover_password') }</Submit>
        </FormBody>
        <BackToLogin href="/login">{ t('back_to_login') }</BackToLogin>
      </FormStyled>
    </LoginPage>
  )
}

PasswordReset.propTypes = {
  form: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired
}

const WrappedPasswordReset = Form.create()(PasswordReset)
export default withTranslation('translation')(WrappedPasswordReset)
