import axios from 'axios';
import { BASE_URL } from '../api';
// import history from '../history'

function adapterUserInfo(res) {
  const { data } = res;
  const { auth_data } = data;
  return auth_data;
}

// gets user data from db
export function getUserInfo() {
  const url = `${BASE_URL}/user`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {})
      .then((res) => {
        resolve(adapterUserInfo(res));
      })
      .catch((error) => {
        //   const { response } = error
        //   const { status } = response
        //   if (status === 401) {
        //     history.push('/login')
        //   } else if (status === 400) {
        //     return false
        //   }
        if (error.message === 'PAYMENT REQUIRED!') {
          reject(error);
        }
        console.log('profile error: ', error);
      });
  });
}
