import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { hasInjuriesPermissionSelector } from '../../redux/selectors/user'
import { BASE_V2_URL } from '../../api'
import { ListSectionTitle } from './List'
import AvailabilityChart from '../Widgets/Availability'
import T from '../Generic/T'


const Availability = ({ tag }) => {
  const hasInjuriesPermission = useSelector(hasInjuriesPermissionSelector)
  const [availability, setAvailability] = useState({ matches: [] })

  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        `${BASE_V2_URL}/players/${tag}/graph?include_other_events=true`,
      )
      setAvailability(result.data)
    }
    fetchData()
  }, [tag])

  if (!availability) return null
  if (_.isEmpty(availability.matches)) return null
  const matches = _.slice(availability.matches, -8)

  // if (_.isEqual(_.uniq(_.map(matches, 'skippedReason')), ['unknown']) || !hasInjuriesPermission) return null
  

  return (
    <div>
      <ListSectionTitle><T i18nKey='Availability' /></ListSectionTitle>
      <AvailabilityChart mini matches={ matches } />
    </div>
  )
}

export default Availability