import { createContext, useContext } from 'react'

const InjuryContext = createContext({})
const InjuryProvider = InjuryContext.Provider
const useInjury = () => useContext(InjuryContext)

const SelectedRowContext = createContext([])
const SelectedRowProvider = SelectedRowContext.Provider
const useSelectedRow = () => useContext(SelectedRowContext)

export {
  InjuryContext,
  InjuryProvider,
  SelectedRowContext,
  SelectedRowProvider,
  useInjury,
  useSelectedRow
}
