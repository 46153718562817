import React from 'react'
import T from '../../Generic/T'

const DataLoading = () => (
  <div className="no-stats">
    <T i18nKey="data_loading" />
  </div>
)

export default DataLoading
