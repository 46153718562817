import React from 'react'
import { connect } from 'react-redux'
import { LocaleProvider } from 'antd'
import moment from 'moment'
import es from 'antd/lib/locale-provider/es_ES'
import en from 'antd/lib/locale-provider/en_GB'
import it from 'antd/lib/locale-provider/it_IT'
import {
  setFilter,
  toggleFilter,
  applyFiltersUpdate,
  setIgnoreTimeframe,
} from '../../redux/ducks/filters'

import DatePickerWrapper from './DatePicker'
import FiltersDialog from './FiltersDialog'

import ReactSVG from 'react-svg'
import FiltersIcon from '../../images/filters.svg'

import styled, { css } from 'styled-components'
import { cssvar } from '../../styles/var'

class FiltersWrapper extends React.Component {
  constructor() {
    super()

    this.state = {
      dates: null,
      filtersModal: false,
    }
  }

  setFiltersModal = (value) => {
    this.setState({ filtersModal: value })
  }

  handleDates = (dates) => {
    this.setState({ dates })
  }

  render() {
    const {
      filters, updateFilter, applyUpdate, language, showOnlyPicker,
    } = this.props
    const { dates, filtersModal } = this.state

    const localeLang = {
      'en-GB': en,
      'es-ES': es,
      'it-IT': it,
    }

    return (
      <LocaleProvider locale={localeLang[language]}>
        <Wrapper>
          <DatePickerWrapper
            updatePeriod={(k, v) => updateFilter(k, v)}
            applyUpdate={v => applyUpdate(v)}
            currentLanguage={language}
            dates={dates}
            defaultDates={[moment().clone().subtract(6, 'months'), moment()]}
            handleDates={this.handleDates} />
          {!showOnlyPicker && (
            <FilterTrigger
              active={filters.type.length || filters.lang.length}
              onClick={() => this.setFiltersModal(true)}>
              <ReactSVG src={FiltersIcon} />
            </FilterTrigger>
          )}
        </Wrapper>
        <FiltersDialog open={filtersModal} onClose={() => this.setFiltersModal(false)} />
      </LocaleProvider>
    )
  }
}

const mapStateToProps = ({ filters }) => ({ filters })
function mapDispatchToProps(dispatch) {
  return {
    updateFilter: (key, value) => {
      const action = ['name', 'from', 'to'].includes(key)
        ? setFilter({ id: key, data: value })
        : toggleFilter({ id: key, data: value })

      dispatch(action)
    },
    applyUpdate: (value) => {
      const action = applyFiltersUpdate(value)
      dispatch(action)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersWrapper)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
const FilterTrigger = styled.div`
  width: 20px;
  height: 20px;
  line-height: 1;
  cursor: pointer;
  svg {
    width: 20px;
    * {
      fill: ${({ active }) => (active ? cssvar('primaryColor') : '#ccc')};
      transition: .2s;
    }
  }
  @media (max-width: 640px) {
    width: 16px;
    height: 16px;
    svg {
      width: 16px;
    }
  }
  ${props => !props.active && css`
    &:hover svg * {
      fill: #aaa;
    }
  `}
`