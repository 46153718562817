const severityColors = [
  { max: 20, color: '#fff19f' },
  { min: 21, max: 40, color: '#ffdb43' },
  { min: 41, max: 60, color: '#ffc866' },
  { min: 61, max: 80, color: '#ff6510' },
  { min: 81, max: 100, color: '#ff0000' },
  { min: 101, color: '#ad0000' },
];

export default severityColors;
