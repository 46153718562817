import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import VideoItemList from './VideoItemList'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'
import { languageToTranslatedDescriptionLanguage } from "../Injury/DetailedInfo"

const Video = ({ data }) => {
  const { t, i18n } = useTranslation()

  const mediaData = useMemo(
    () =>
      data
        .filter(({ media }) => media)
        .reduce(
          (accumulator, injury) => [
            ...accumulator,
            ...injury.media.map((media) => ({ ...injury, media: { ...media, id: media.name } })),
          ],
          []
        ),
    [data]
  )

  const firstVideoId = useMemo(() => mediaData[0].media.id, [mediaData])
  const [currentVideoId, setCurrentVideoId] = useState(firstVideoId)
  const currentVideo = mediaData.find(({ media: { id } }) =>
    currentVideoId ? id === currentVideoId : id === firstVideoId
  )

  const currentLang = i18n.languages[0].toLowerCase()
  const targetLang = currentLang === 'pt-pt'  || currentLang === 'fr-fr'  ? 'en-gb' : currentLang
  const title = ({ translated_description, description }) => {
    return translated_description ? translated_description[languageToTranslatedDescriptionLanguage[targetLang]] : description
  }

  const description = ({ injuried_part, injury_type, side, trauma }) => {
    const sideAndPart = () => {
      if (!side || side === 'both') return t(`injury-part-${injuried_part}`)
      return t(`injury-part-${injuried_part}-${side}`)
    }
    const injuryTrauma = trauma !== 'na' ? t(`injury-contact-${trauma}`) : null
    let typeAndTrauma = _.filter([injuryTrauma, t(`injury-type-${injury_type}`)])
    if (i18n.language === 'it-IT' || i18n.language === 'es-ES') {
      typeAndTrauma = _.reverse(typeAndTrauma)
    }
    return `${sideAndPart()} – ${_.capitalize(_.join(typeAndTrauma, ' '))}`
  }

  const meta = ({ game_context, date }) => {
    return `${moment(date).format('DD.MM.YYYY')}, ${game_context}`
  }

  const handleSetVideo = (injuryId) => () => {
    setCurrentVideoId(injuryId)
  }

  if (!currentVideo) return null

  return (
    <VideoContainer>
      <VideoContent>
        <ReactPlayer url={`${currentVideo.media.url}#t=0.1`} width='100%' height='auto' controls />
        <VideoInfo>
          <VideoTitle>{title(currentVideo)}</VideoTitle>
          <VideoDescription>{description(currentVideo)}</VideoDescription>
          <VideoMeta>{meta(currentVideo)}</VideoMeta>
        </VideoInfo>
      </VideoContent>
      <div>
        {mediaData.map((injury) => (
          <VideoItemList
            key={injury.id}
            title={title(injury)}
            description={description(injury)}
            meta={meta(injury)}
            selected={injury.media.id === currentVideoId}
            onClick={handleSetVideo(injury.media.id)}
          />
        ))}
      </div>
    </VideoContainer>
  )
}

Video.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
}

Video.defaultProps = {
  data: [],
}

export default Video

const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 15px;
  align-items: flex-start;
  position: relative;
  @media (max-width: 1366px) {
    grid-template-columns: 2fr 1fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`
const VideoContent = styled.div`
  position: sticky;
  top: 15px;
  @media (max-width: 1024px) {
    padding-bottom: 15px;
    border-bottom: 30px solid ${cssvar('background')};
    position: static;
  }
  @media (max-width: 640px) {
    border-width: 15px;
  }
`
const VideoInfo = styled.div`
  @media (max-width: 1024px) {
    padding: 0 15px;
  }
`
const VideoTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 550;
  line-height: 1.3;
  color: ${cssvar('mainColor')};
  @media (max-width: 640px) {
    font-size: 18px;
  }
`
const VideoDescription = styled.p`
  margin: 0;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 450;
  @media (max-width: 640px) {
    font-size: 14px;
    margin-top: 5px;
  }
`
const VideoMeta = styled.p`
  margin: 0;
  margin-top: 3px;
  color: ${cssvar('secondaryTextColor')};
  @media (max-width: 640px) {
    font-size: 12px;
    margin-top: 0;
  }
`
