import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import { Empty } from 'antd'
import ReactSVG from 'react-svg'

import StreamSkeleton from './StreamSkeleton'
import instagramIcon from '../../../images/Instagram.svg'
import Linkifier from '../../Generic/Linkify'
import PostModal from './PostModal'
import T from '../../Generic/T'

import missingImage from '../../../images/missing-preview-image.jpg'
import missingVideo from '../../../images/missing-preview-video.jpg'
import missingAvatar from '../../../images/World.svg'
import reachIcon from '../../../images/reach-icon.svg'

import { getIconID, nFormatter, getImageFromSource } from '../../../helpers'

import styled, { css } from 'styled-components/macro'
import { cssvar } from '../../../styles/var'
import video from '../../../images/Video.svg'

const videoType = 'video'

class Stream extends React.Component {
  state = {
    currentStream: {},
    modalOpen: false
  }

  handleCancel() {
    this.setState({ modalOpen: false })
  }

  openModal(e, stream) {
    e.preventDefault()
    this.setState({ currentStream: stream, modalOpen: true })
  }

  isVideoType = (source, type) => source[type].type === videoType

  handlePreviewError = e => {
    e.target.onerror = null
    e.target.src = missingImage
  }
  handleAvatarError = e => {
    e.target.onerror = null
    e.target.src = missingAvatar
  }
  handleVideoPreviewError = e => {
    e.target.onerror = null
    e.target.src = missingVideo
  }

  renderPreview = (source, type, previewImage) => {
    if (this.isVideoType(source, type)) {
      return (
        <PreviewImageContainer>
          { type === 'ig' && <VideoImage /> }
          <PreviewImage src={type === 'ig' ? source.ig.picture : missingVideo } onError={this.handleVideoPreviewError} />
        </PreviewImageContainer>
      )
    }

    if (previewImage) {
      return (
        <PreviewImageContainer>
          <PreviewImage src={ previewImage } onError={ this.handlePreviewError } />
        </PreviewImageContainer>
      )
    }

    return null
  }

  render() {
    const { update, streams = [], isSingleColumn, isLoading, language } = this.props
    const { currentStream, modalOpen } = this.state

    if (streams.length === 0) {
      if (isLoading) {
        return (
          <div className="streams-row-wrapper no-scroll">
            <StreamSkeleton data={streams} isSingleColumn={ isSingleColumn } />
          </div>
        )
      }
      return (
        <div className="streams-row-wrapper no-streams">
          <Empty description={ <T i18nKey="empty-feed-livestream" /> } />
        </div>
      )
    }

    const formatMessage = (message) => {
      return message.replace(/\s/g, ' ')
    }

    return (
      <React.Fragment>
        { streams.map((stream = {}, idx) => {
          const { _source = {} } = stream
          const { type, date, message, owner = {}, fb = {}, additionalOwners = [], reach } = _source
          
          let ownersNumber = 0
          if (additionalOwners && additionalOwners.length > 0) {
            ownersNumber = additionalOwners.length
          }

          const { image, name } = owner
          const previewImage = getImageFromSource(_source)

          const icon = getIconID(type)

          return (
            <FeedPost
              key={ idx }
              onClick={ e => this.openModal(e, stream) }
              opened={ modalOpen && currentStream._id === stream._id }>
              <AuthorAndMessage>
                <Author>
                  <AuthorAvatar src={ image || missingAvatar } onError={ this.handleAvatarError } />
                  <AuthorInfo>
                    <AuthorName>
                      { name }
                      { ownersNumber > 0 && (
                        <PlusOthers>+ { ownersNumber }</PlusOthers>
                      ) }
                    </AuthorName>
                    <PostMeta>
                      <PostMetaItem className={ type }>
                        {type === 'ig'
                          ? <ReactSVG className="instagram-svg" src={ instagramIcon } />
                          : <FontAwesomeIcon icon={ icon } />
                        }
                        <PostMetaValue>
                          { DateTime.fromISO(date).toRelative({ locale: language }) }
                        </PostMetaValue>
                      </PostMetaItem>
                      { reach && (
                        <PostMetaItem>
                          <ReactSVG src={ reachIcon } />
                          <PostMetaValue>{ nFormatter(reach) }</PostMetaValue>
                        </PostMetaItem>
                      ) }
                    </PostMeta>
                  </AuthorInfo>
                </Author>
                <Message>
                  <Linkifier>
                    { formatMessage(fb.type === 'link' ? fb.link_description : message) }
                  </Linkifier>
                </Message>
              </AuthorAndMessage>
              { this.renderPreview(_source, type, previewImage) }
            </FeedPost>
          )
          })
        }
        <PostModal
          data={ currentStream }
          open={ modalOpen }
          update={ update }
          language={ language }
          close={ () => this.handleCancel() } />
      </React.Fragment>
    )
  }
}


export default Stream

const FeedPost = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 15px;
  border-bottom: 1px solid ${ cssvar('borderColor') };
  cursor: pointer;
  transition: .3s;
  position: relative;
  &:hover {
    background-color: #f5f5f5;
  }
  @media (min-width: 1025px) {
    transition: box-shadow 3s ease-in;
    ${ props => props.opened && css`
      box-shadow: inset 0 0 4px 0 ${ cssvar('oldPrimaryColor') };
      transition: box-shadow .3s;
    ` }
  }
  @media (max-width: 640px) {
    flex-direction: column;
  }
`
const AuthorAndMessage = styled.div`
  flex: 1 1 auto;
  align-self: flex-start;
`
const Author = styled.div`
  display: flex;
  align-items: center;
`
const AuthorAvatar = styled.img`
  flex: 0 0 auto;
  height: 40px;
  margin-right: 15px;
  border-radius: 4px;
  @media (max-width: 640px) {
    height: 30px;
  }
`
const AuthorInfo = styled.div`
  flex: 1 1 auto;
`
const AuthorName = styled.div`
  font-size: 16px;
  font-weight: 550;
  line-height: 1;
  @media (max-width: 640px) {
    font-size: 15px;
  }
`
const PlusOthers = styled.span`
  margin-left: 5px;
  color: ${ cssvar('secondaryTextColor') };
`
const PostMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 5px;
`
const PostMetaItem = styled.span`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  .instagram-svg {
    height: 12px;
    margin-top: 3px;
  }
  svg {
    width: auto !important;
    height: 12px !important;
    margin-top: -2px;
  }
`
const PostMetaValue = styled.span`
  font-size: 12px;
  color: ${ cssvar('secondaryTextColor') };
`
const Message = styled.div`
  margin-top: 10px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  overflow-wrap: anywhere;
`
const PreviewImageContainer = styled.div`
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  border: 1px solid ${ cssvar('borderColor') };
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  @media (max-width: 640px) {
    width: 100%;
    height: 200px;
  }
`
const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const VideoImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${video});
    background-position: center;
    background-size: 30%;
    background-repeat: no-repeat;
  }
`