import React from 'react'
import { connect } from 'react-redux'
import { Container, Main, H1 } from '../styledComponents'
import styled from 'styled-components'
import OtherEventsOverall from './Overall'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import DopingIconLight from '../../../images/dopingPillLight.svg'
import News from './News'
import { cssvar } from '../../../styles/var'
import OkIcon from '../../../images/ok.png'

const { Option } = Select

const OtherEvents = ({
  data,
  coverageStatus,
  playerName,
  playerId,
  coverageProgress,
}) => {
  const { t } = useTranslation()

  const [selectedType, setSelectedType] = React.useState('')

  const filteredData = data
    .filter(({ injury_type }) => !selectedType || injury_type === selectedType)
    .map(row => ({
      ...row,
      key: row.id,
      dateEnd:
        row.recovery_days > 0 && moment(row.date).add(parseInt(row.recovery_days, 10), 'days'),
    }))

  function handleChange(value) {
    setSelectedType(value)
  }

  console.log(filteredData)


  return (
    <Container>
      <MainStyled>
        <PageHeader>
          <LeftSide>
            <H1Styled>{t('Other events')}</H1Styled>
            <Select defaultValue='' style={{ width: 150 }} onChange={handleChange}>
              <Option value=''>{t('other-events-all')}</Option>
              <Option value='doping'>{t('other-events-doping')}</Option>
              <Option value='other'>{t('other-events-other')}</Option>
            </Select>
          </LeftSide>
          <OtherEventsOverall
            data={filteredData}
            coverageStatus={coverageStatus}
            coverageProgress={coverageProgress}
            playerId={playerId}
            playerName={playerName}
          />
        </PageHeader>
        {filteredData.map(row => (
          <EventContainer key={row.key}>
            <EventInfo>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                boxSizing: 'border-box',
                marginBottom: '5px',
              }}>
                <div>
                  {row.injury_type === 'doping' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={DopingIconLight} style={{ width: '18px', marginRight: '5px' }} alt='Doping' />
                      <span style={{ color: '#FF8B18', fontSize: '16px', fontWeight: 'bold' }}>{t('other-events-doping')}</span>
                    </div>
                  )

                    : (
                      <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                        <div style={{
                          width: '9px', height: '9px', borderRadius: '50%', background: '#3F8CCA',
                        }} />
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{t('other-events-other')}</span>
                      </div>
                    )
                  }
                </div>
                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{(moment(row.date).format('DD.MM.YYYY'))}</span>
              </div>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                {row.description}
              </span>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ fontSize: '14px', fontWeight: '500' }}>
                  {t('other-events-recovery-days')}
                  :
                </span>
                <span style={{ fontSize: '14px', fontWeight: '500' }}>{row.recovery_days != null ? row.recovery_days : 'In corso'}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ fontSize: '14px', fontWeight: '500' }}>
                  {t('other-events-skipped-matches')}
                  :
                </span>
                <span style={{ fontSize: '14px', fontWeight: '500' }}>{row.missed_matches != null ? row.missed_matches : t('other-events-nd')}</span>
              </div>
            </EventInfo>
            <EventContainerDivider><span /></EventContainerDivider>
            <div style={{ flex: 65 }}>
              <News news={row.news} />
            </div>
          </EventContainer>
        ))}

        {
          filteredData.length === 0 && (
            <NoEventsContainer>
              <img src={OkIcon} alt='Ok' />
              <span>{t('other-events-no-events')}</span>
            </NoEventsContainer>
          )
        }
      </MainStyled>
    </Container>
  )
}

function mapStateToProps({ filters }) {
  return { filters }
}
export default connect(mapStateToProps)(OtherEvents)

const MainStyled = styled(Main)`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 30px;
    @media (max-width: 1024px) {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 800px) {
    padding-left: 0;
    padding-right: 0;
  }
  .sentiment-thumb-neutral svg path {
    fill: #e8004d
  }
`
const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    gap: 30px;
    align-items: flex-end;
  }
`
const LeftSide = styled.div`
  flex: 0 0 auto;
   @media (max-width: 800px) {
  padding-right: 15px;
  }
`
const H1Styled = styled(H1)`
  @media (max-width: 1024px) {
    display: none;
  }
`

const EventContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid  #666;
  gap: 10px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  &:not(:last-child) {
    @media (max-width: 800px) {
      margin-bottom: 35px;
    }
  }
`

const EventContainerDivider = styled.div`
  width: 1px;
  align-self: stretch;
  position: relative;
  padding: 10px 0;
  &:after {
    content '';
    position: absolute;
    top: 50%; 
    transform: translateY(-50%);
    background-color: ${cssvar('borderColor')};
    display: block;
    height: calc(100% - 20px);
    width: 1px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`


const EventInfo = styled.div`
  flex: 35;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  row-gap: 5px;
  padding: 10px 15px;
  padding-top: 20px;
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
`

const NoEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  row-gap: 20px;
  img {
    width: 100px;
    opacity: 0.7;
  }
  span {
    font-size: 18px;
    font-weight: 550;
    text-align: center;
    color: ${cssvar('secondaryTextColor')};
  }
`