import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Dropdown, { DropdownItem } from './Dropdown'

import Italy from '../../images/language-it.svg'
import Spain from '../../images/language-es.svg'
import England from '../../images/language-en.svg'
import Portugal from '../../images/language-pt.png'
import France from '../../images/language-fr.png'
import Germany from '../../images/language-de.png'
import Brasil from '../../images/language-br.png'

export const availableLanguages = ['en-gb', 'it', 'es', 'pt-pt', 'br', 'fr-fr', 'de']

export const flags = {
  'en-gb': England,
  it: Italy,
  es: Spain,
  'pt-pt': Portugal,
  br: Brasil,
  'fr-fr': France,
  de: Germany,
}

export const languages = {
  'en-gb': 'English',
  it: 'Italian',
  es: 'Spanish',
  'pt-pt': 'Português',
  br: 'Português brasileiro',
  'fr-fr': 'Français',
  de: 'Deutsch',
}

const ChangeLanguage = () => {
  const { i18n } = useTranslation()

  const handleClick = (lang) => {
    i18n.changeLanguage(lang)
    const locale = lang.slice(0, 2)
    DateTime.local().setLocale(locale)
  }

  let language = i18n.language.toLowerCase()
  if (!languages[language]) language = availableLanguages[0]
  console.log('language', language)
  return (
    <Dropdown
      trigger={
        <img width='24px' src={flags[language]} alt={languages[language]} />
      }
    >
      {availableLanguages.map(lang => (
        <DropdownItem key={lang} onClick={() => handleClick(lang)}>
          <img width='20px' src={flags[lang]} alt={flags[lang]} />
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

export default ChangeLanguage
