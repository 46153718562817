import React from 'react'
import { DetailContainer, MainInfo, Image, NameAndDesc, Name, Description } from './Detail'
import TextIcon from '../../images/TextIcon.svg'

class FullTextDetail extends React.Component {
  render() {
    const { fulltext } = this.props
    return (
      <DetailContainer>
        <MainInfo>
          <Image src={ TextIcon } />
          <NameAndDesc>
            <Name>{ fulltext }</Name>
            <Description>Text search</Description>
          </NameAndDesc>
        </MainInfo>
      </DetailContainer>
    )
  }
}
export default FullTextDetail