import React from 'react'
import T from '../Generic/T'
import Column from '../Generic/Column'

class AdvancedResearchInfo extends React.Component {
  render() {
    const parts = [
      'and_operator',
      'or_operator',
      'not_operator',
      'suffix_operator',
      'phrase_operator',
      'precedence_operator',
      'escaping_operator',
    ]

    return (
      <Column>
        {
          parts.map(p => (
            <Column className='info-col'>
              <h1 className='title'>
                <T i18nKey={`info_${p}_title`} />
              </h1>
              <span className='description'>
                <T i18nKey={`info_${p}_description`} />
              </span>
            </Column>
          ))
          }
        <Column className='escaping-details info-col'>
          <li>
            <T i18nKey='info_escaping_not_operator' />
          </li>
          <li>
            <T i18nKey='info_escaping_suffix_operator' />
          </li>
        </Column>
      </Column>
    )
  }
}
export default AdvancedResearchInfo
