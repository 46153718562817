import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import SideNav from './SideNavigation'
import Header from './Header'
import Main from './Main'
import MobileMenu from './MobileMenu'
import ScrollToTop from '../utils/ScrollToTop'
import styled from 'styled-components/macro'
import { cssvar } from '../styles/var'

import { getReadList } from '../redux/readList/actions'


const Home = ({ data }) => {
  const contentRef = useRef(null)
  const dispatch = useDispatch()
  useEffect(() => { dispatch(getReadList()) }, [])

  return (
    <AppContainer>
      <Header data={ data } />
      <AppContent ref={ contentRef }>
        <ScrollToTop componentRef={ contentRef } />
        <SideNav />
        <Main />
      </AppContent>
      <MobileMenu />
    </AppContainer>
  )
}

export default Home

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
  height: -webkit-fill-available;
  @media (max-device-width: 900px) and (orientation: landscape) {
    height: auto;
  }
  @media (display-mode: standalone) {
    background-color: ${ cssvar('mainColor') };
  }
`
const AppContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  @media (max-width: 1024px) {
    overflow: scroll;
  }
`