import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Button } from 'antd'
import styled from 'styled-components'
import { Line as LineChart } from 'react-chartjs-2'
import moment from 'moment'
import {
  groupPartialsBySocial,
  nFormatter,
  groupSentimentPartialsBySocial,
  groupSentimentPartialsAtanBySocial,
} from '../../../helpers'
import DataLoading from './DataLoading'

const SwitchButton = styled(Button)`
  border: 0;
  cursor: pointer;
  box-shadow: none !important;
  margin-top: 0;
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f7f7f7;
  color: #052533;
  height: 20px;
  line-height: 1;
  font-size: 0.6rem;
  transition: 200ms;
  &.active, &:hover {
    background-color: #13BEE8;
    color: white;
  }
`

function generateDiff(exclude, data) {
  if (exclude) {
    return ''
  }
  let index = data.length - 1
  for (index = data.length - 1; index >= 0; index--) {
    if (data[index] != null) {
      break
    }
  }
  const last = data[index]
  const first = data.find(k => k !== null)
  const diff = (((last - first) / first) * 100)

  if (isNaN(diff)) {
    return ' ---'
  }

  return `${(diff > 0 ? ' +' : ' ') + diff.toFixed(2)}%`
}

const LineCharts = ({
  data, t, type, loading,
}) => {
  const [logarithmicScale, setLogarithmicScale] = useState(true)
  const socials = [
    'ig',
    'tw',
    'rss',
  ]
  const isSocialTrend = type === 'social-trend'

  const dati = data || []
  const labels = dati.map((row) => {
    const d = row.key
    const dt = moment(d).format('DD MMM YYYY')
    return dt
  })
  const values = dati.map(row => row.partials)

  const getPartials = () => {
    if (isSocialTrend) return groupPartialsBySocial(values, socials)
    if (logarithmicScale) return groupSentimentPartialsAtanBySocial(values, socials)
    return groupSentimentPartialsBySocial(values, socials)
  }

  const {
    ig = [],
    tw = [],
    rss = [],
  } = getPartials()

  const set = {
    labels,
    datasets: [{
      label: `Instagram${generateDiff(type === 'sentiment-trend', ig)}`,
      data: ig || [],
      borderColor: '#9965ff',
      borderWidth: 1,
      backgroundColor: 'transparent',
      // backgroundColor: '#ebe0ff',
      hoverBackgroundColor: '#9965ff',
      hoverBorderWidth: 1,
      hoverBorderColor: '#7b52cd',
    },
    {
      label: `X${generateDiff(type === 'sentiment-trend', tw)}`,
      data: tw || [],
      borderWidth: 1,
      borderColor: '#36a2eb',
      hoverBorderColor: '#2b87c5',
      backgroundColor: 'transparent',
      // #d6ecfb
      hoverBackgroundColor: '#36a2eb',
      hoverBorderWidth: 1,
    },
    {
      label: `${t('web_article')}${generateDiff(type === 'sentiment-trend', rss)}`,
      data: rss || [],
      borderWidth: 1,
      // backgroundColor: '#feecd9',
      backgroundColor: 'transparent',
      borderColor: '#fdb976',
      hoverBorderWidth: 1,
      hoverBackgroundColor: '#fc6500c2',
      hoverBorderColor: '#fc6500',
    }],
  }
  if (type !== 'sentiment-trend') {
    set.datasets.splice(set.datasets.length - 1)
  }

  const scales = type === 'sentiment-trend'
    ? ({
      yAxes: [
        {
          ticks: {
            callback: (value) => {
              const valuesMap = [
                { number: 100, label: t('stats_very_positive') },
                { number: logarithmicScale ? 80 : 40, label: t('stats_positive') },
                { number: 0, label: t('stats_neutral') },
                { number: logarithmicScale ? -80 : -40, label: t('stats_negative') },
                { number: -100, label: t('stats_very_negative') },
              ]

              const findValue = ({ number }) => number === value

              return valuesMap.find(findValue) && valuesMap.find(findValue).label
            },
            min: -100,
            max: 100,
          },
        },
      ],
    })
    : (
      {
        yAxes: [
          {
            ticks: {
              callback(value) {
                return nFormatter(value)
              },
            },
          },
        ],
      }
    )

  const chartOptions = {
    spanGaps: true,
    beginAtZero: true,
    scaleShowGridLines: true,
    scaleGridLineColor: 'rgba(0,0,0,.05)',
    scaleGridLineWidth: 1,
    scaleShowHorizontalLines: true,
    scaleShowVerticalLines: true,
    bezierCurve: true,
    bezierCurveTension: 0.4,
    pointDot: true,
    pointDotRadius: 4,
    pointDotStrokeWidth: 1,
    pointHitDetectionRadius: 20,
    datasetStroke: true,
    datasetStrokeWidth: 2,
    datasetFill: false,
    responsive: true,
    maintainAspectRatio: false,
    scales,
    legend: { position: isSocialTrend ? 'right' : 'top' },
  }

  const renderChart = () => {
    if (loading) {
      return <DataLoading />
    }

    if (data && data.length > 0) {
      return <LineChart data={set} options={chartOptions} />
    }

    return (
      <div className="no-stats">
        {t('stats_not_available')}
      </div>
    )
  }

  return (
    <div className="line-chart">
      <h1 className="line-title">
        {isSocialTrend
          ? t('analytics-line-chart')
          : t('analytics-media-relation')
        }
      </h1>
      <span className="line-subtitle">
        {!isSocialTrend
          ? ([
            <SwitchButton
              key="logarithmicButton"
              onClick={() => { setLogarithmicScale(true) }}
              className={logarithmicScale ? 'active' : ''}
            >
              {t('analytics-logarithmic-scale')}
            </SwitchButton>,
            <SwitchButton
              key="linearButton"
              onClick={() => { setLogarithmicScale(false) }}
              className={!logarithmicScale ? 'active' : ''}
            >
              {t('analytics-linear-scale')}
            </SwitchButton>,
          ])
          : null
        }
      </span>
      {renderChart()}
    </div>
  )
}
export default withTranslation('translation')(LineCharts)
