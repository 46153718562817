import React from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import isEqual from 'lodash/isEqual';

import Row from '../../Generic/Row';
import Column from '../../Generic/Column';
import Panel from '../../Generic/Panel';
import GeoChart from './GeoChart';
import DonutChart from './DonutChart';
import RadarChart from './RadarChart';
import PillarChart from './PillarChart';
import StackedPillarChart from './StackedPillarChart';
import CloudChart from './CloudChart';
import LineCharts from './LineCharts';
import CrossFeed from './CrossFeed';
import { saveCrossFeedOpts } from '../../../redux/ducks/feeds';
import { removeHashtagChar } from '../../../helpers';
import { getStatistics } from '../../../redux/statistics/actions';
import { withTranslation } from 'react-i18next';

class LiveStats extends React.Component {
  state = {
    CrossFeedVisible: false,
  }

  componentDidMount() {
    this.fetchAllStats();
  }

  componentDidUpdate(prevProps) {
    const {
      tag,
      filters,
      hashtag,
      fulltext,
      currentHashtagTag,
    } = this.props;

    const filtersToExclude = ['fromSocials', 'aboutSocials', 'analyticsSocials'];
    const requiredFilters = filter => !filtersToExclude.includes(filter);
    const reduceFilters = filtersSource => (accumulator, filter) => {
      accumulator[filter] = filtersSource[filter];
      return accumulator;
    };

    if (
      !isEqual(
        Object.keys(prevProps.filters)
          .filter(requiredFilters)
          .reduce(reduceFilters(prevProps.filters), {}),
        Object.keys(filters)
          .filter(requiredFilters)
          .reduce(reduceFilters(filters), {}),
      )
      || tag !== prevProps.tag
      || prevProps.currentHashtagTag !== currentHashtagTag
      || prevProps.hashtag !== hashtag
      || prevProps.fulltext !== fulltext
    ) {
      this.fetchAllStats();
    }
  }

  fetchAllStats = () => {
    const {
      // activeKey,
      filters = {},
      isFavourite,
      getStats,
      tag,
      hashtag,
      fulltext,
    } = this.props;
    const {
      from,
      to,
      category,
      type,
      lang,
    } = filters;

    const isCustom = hashtag || fulltext;

    const isSection = tag
      ? tag.indexOf('a_') > -1 || tag.indexOf('ct_') > -1 || tag.indexOf('cp_') > -1
      : !isCustom;
    let options = {
      d: { from, to },
      filters: {
        category,
        type,
        lang,
      },
    };
    // console.log('fetchAllStats')
    if (fulltext) {
      options = {
        ...options,
        h: false,
        f: true,
        q: fulltext,
      };
    } else if (hashtag) {
      options = {
        ...options,
        h: true,
        f: false,
        q: hashtag,
      };
    } else if (tag) {
      options = {
        ...options,
        h: false,
        f: false,
        q: tag,
      };
    }

    const statsToFetch = [
      { type: 'geo-country', size: 15, stateName: 'geo' },
      { type: 'type', stateName: 'donut' },
      {
        type: 'hashtags',
        filters: 'al',
        size: 30,
        stateName: 'hashtag_cloud',
      },
    ];

    if (tag && tag.indexOf('x_') > -1) {
      statsToFetch.push(
        { type: 'tam2', stateName: 'mentions' },
        { type: 'social-trend', stateName: 'trend' },
        { type: 'sentiment-trend', stateName: 'sentiment' },
        { type: 'sentiment-type', stateName: 'sentimentDonut' },
      );
    } else if (tag && (tag.indexOf('ps_') > -1 || tag.indexOf('p_') > -1)) {
      statsToFetch.push(
        { type: 'tam2', stateName: 'mentions' },
        { type: 'social-trend', stateName: 'trend' },
        { type: 'sentiment-trend', stateName: 'sentiment' },
        { type: 'sentiment-type', stateName: 'sentimentDonut' },
      );
    } else if (isSection) {
      statsToFetch.push(
        { type: 'tam1', stateName: 'most_active' },
        { type: 'most-followed', stateName: 'most_followed' },
      );
    } else {
      statsToFetch.push(
        { type: 'tam1', stateName: 'most_active' },
        { type: 'most-followed', stateName: 'most_followed' },
        { type: 'tam2', stateName: 'mentions' },
      );
    }

    getStats({ options, statsToFetch, isFavourite });
  }

  showCrossFeed = () => { this.setState({ CrossFeedVisible: true }); }

  hideCrossFeed = () => { this.setState({ CrossFeedVisible: false }); }

  triggerModal = (label, sourceTag, { isCrossTag, isGeo } = {}) => {
    const { setCrossFeedOpts } = this.props;

    this.setState({ currentHashtagLabel: label, isCrossTag, isGeo });
    setCrossFeedOpts(sourceTag);

    // Toggle CrossFeed
    this.setState(({ CrossFeedVisible }) => ({ CrossFeedVisible: !CrossFeedVisible }));
  }

  render() {
    const {
      hashtag,
      fulltext,
      tag,
      name,
      stats,
      feeds,
      isFavourite,
      loadingStats,
      t
    } = this.props;
    const {
      geo,
      donut,
      most_followed,
      most_active,
      mentions,
      trend,
      sentiment,
      sentimentDonut,
      hashtag_cloud,
    } = stats;
    const { cross } = feeds;
    const currentHashtagTag = cross;
    const {
      CrossFeedVisible,
      currentHashtagLabel,
      isCrossTag,
      isGeo,
    } = this.state;


    const isMobile = window.innerWidth <= 799;

    const cleanHashtagLabel = removeHashtagChar(currentHashtagLabel);

    const isCustom = hashtag || fulltext;

    const isSection = tag
      ? tag.indexOf('a_') > -1 || tag.indexOf('ct_') > -1 || tag.indexOf('cp_') > -1
      : !isCustom;
    const isTeam = tag
      ? tag.indexOf('t_') > -1
      : false;

    return (
      <Column className="livestats">
        <Column className="charts-container">
          <div className="scrolling-container">
            {
            isMobile
              ? (
                <Column>
                  <Row>
                    <Panel type="geo" color="#f7f7f7">
                      <GeoChart
                        data={geo}
                        tag={tag}
                        name={name}
                        hashtag={hashtag}
                        fulltext={fulltext}
                        isFavourite={isFavourite}
                        loading={loadingStats}
                        trigger={this.triggerModal}
                      />
                    </Panel>
                  </Row>
                  <Row>
                    <Panel type="donut" color="#f7f7f7">
                      <DonutChart
                        doughnut={donut}
                        type="social"
                        loading={loadingStats}
                      />
                    </Panel>
                  </Row>
                </Column>
              )
              : (
                <Row className="two-chart-wrapper">
                  <Panel type="geo" color="#f7f7f7">
                    <GeoChart
                      data={geo}
                      tag={tag}
                      name={name}
                      hashtag={hashtag}
                      fulltext={fulltext}
                      isFavourite={isFavourite}
                      loading={loadingStats}
                      trigger={() => {}}
                      // trigger={this.triggerModal}
                    />
                  </Panel>
                  <Panel type="donut" color="#f7f7f7">
                    <DonutChart
                      doughnut={donut}
                      type="social"
                      loading={loadingStats}
                    />
                  </Panel>
                </Row>
              )
          }
            {
                isSection
                  ? (
                    <div>
                      <Row className="pillar">
                        <StackedPillarChart
                          data={most_active}
                          type="active"
                          hashtag={hashtag}
                          fulltext={fulltext}
                          tag={tag}
                          name={name}
                          isFavourite={isFavourite}
                          loading={loadingStats}
                        />
                      </Row>
                      <Row className="pillar">
                        <PillarChart
                          data={most_followed}
                          type="followed"
                          hashtag={hashtag}
                          fulltext={fulltext}
                          tag={tag}
                          name={name}
                          isFavourite={isFavourite}
                          loading={loadingStats}
                        />
                      </Row>
                    </div>
                  )
                  : isTeam
                    ? (
                      <div>
                        <Row className="pillar">
                          <StackedPillarChart
                            data={mentions}
                            type="mentions"
                            hashtag={hashtag}
                            fulltext={fulltext}
                            tag={tag}
                            name={name}
                            trigger={this.triggerModal}
                            isFavourite={isFavourite}
                            loading={loadingStats}
                          />
                        </Row>
                        <Row className="pillar">
                          <StackedPillarChart
                            data={most_active}
                            type="active"
                            hashtag={hashtag}
                            fulltext={fulltext}
                            tag={tag}
                            name={name}
                            isFavourite={isFavourite}
                            loading={loadingStats}
                          />
                        </Row>
                        <Row className="pillar">
                          <PillarChart
                            data={most_followed}
                            type="followed"
                            hashtag={hashtag}
                            fulltext={fulltext}
                            tag={tag}
                            name={name}
                            isFavourite={isFavourite}
                            loading={loadingStats}
                          />
                        </Row>
                      </div>
                    )
                    : (
                      <Row className="pillar">
                        <StackedPillarChart
                          data={mentions}
                          type="mentions"
                          hashtag={hashtag}
                          fulltext={fulltext}
                          tag={tag}
                          name={name}
                          trigger={this.triggerModal}
                          isFavourite={isFavourite}
                          loading={loadingStats}
                        />
                      </Row>
                    )
          }
            {
            tag
              ? tag.indexOf('x_') > -1 || tag.indexOf('ps_') > -1 || tag.indexOf('p_') > -1
                ? ([
                  <Row key="trend" className="line-wrapper">
                    <LineCharts
                      data={trend}
                      type="social-trend"
                      hashtag={hashtag}
                      fulltext={fulltext}
                      tag={tag}
                      name={name}
                      isFavourite={isFavourite}
                      loading={loadingStats}
                    />
                  </Row>,
                  <Row key="title" style={{ justifyContent: 'center', marginTop: 20 }}>
                    <h2 style={{ textAlign: 'center' }}>{ t('sentiment-analysis') }</h2>
                  </Row>,
                  <Row key="sentiment" className="two-chart-wrapper">
                    <Panel type="geo" color="#f7f7f7">
                      <LineCharts
                        data={sentiment}
                        type="sentiment-trend"
                        hashtag={hashtag}
                        fulltext={fulltext}
                        tag={tag}
                        name={name}
                        isFavourite={isFavourite}
                        loading={loadingStats}
                      />
                    </Panel>
                    <Panel type="donut" color="#f7f7f7">
                      <RadarChart data={sentimentDonut} />
                    </Panel>
                  </Row>,
                ])
                : ''
              : ''
            }
            <Row>
              <CloudChart
                hashtag={hashtag_cloud}
                fulltext={fulltext}
                trigger={this.triggerModal}
              />
            </Row>
          </div>
        </Column>
        <CrossFeed
          visible={CrossFeedVisible}
          close={this.hideCrossFeed}
          open={this.showCrossFeed}
          hashtagLabel={cleanHashtagLabel}
          hashtagTag={currentHashtagTag}
          fulltext={fulltext}
          tag={tag}
          name={name}
          isCrossTag={isCrossTag}
          isGeo={isGeo}
          hashtag={hashtag}
          isHashtag={!tag}
        />
      </Column>
    );
  }
}

const mapStateToProps = ({
  stats: { data: stats, loading: loadingStats },
  filters,
  feeds,
  navDrilldownHeader: { data },
}) => ({
  stats,
  filters,
  feeds,
  name: data ? data.name : '',
  loadingStats,
});
function mapDispatchToProps(dispatch) {
  return {
    getStats: bindActionCreators(getStatistics, dispatch),

    setCrossFeedOpts: (option) => {
      const action = saveCrossFeedOpts({ data: option });
      dispatch(action);
    },
  };
}

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveStats));
