import _groupBy from 'lodash/groupBy'
import _isObject from 'lodash/isObject'
import _isEmpty from 'lodash/isEmpty'
import _difference from 'lodash/difference'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _trim from 'lodash/trim'
import _ from 'lodash'
import { BASE_URL } from './api'


export function imgError(image) {
  image.onerror = '';
  image.className = 'fas fa-globe-americas';
  return true;
}

export function nFormatter(num) {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}B`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num.toFixed(0);
}


export function toTimestamp(strDate) {
  const datum = Date.parse(strDate);
  return Math.round(datum / 1000);
}

export function toCamelCase(string) {
  return string
    .toLowerCase()
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substr(1))
    .join(' ');
}
export function urlBuilder(opts = {}) {
  const {
    url,
    tag,
    isFavourite,
    hashtag,
  } = opts;
  // console.log('url ', url)
  if (tag) {
    switch (url) {
      case '/profile': {
        return `${BASE_URL}${url}?t=${tag}&include_other_events=true`;
      }
      case '/nav-drilldown': {
        return `${BASE_URL}${url}?t=${tag}`;
      }
      default: {
        return `${BASE_URL}${url}?q=${tag}`;
      }
    }
  }
  if (hashtag) {
    return `${BASE_URL}${url}?t=${hashtag}`;
  }
  if (isFavourite) {
    return `${BASE_URL}/favourites${url}`;
  }
  return `${BASE_URL}${url}`;
  // }
  // const URL = (tag)
  //   ? `${url}?t=${tag}`
  //   : (isFavourite)
  //     ? `/favourites${url}`
  //     : `${url}`
  // return BASE_URL + URL
}

export function adapterFavourites(res) {
  const { data = {} } = res;
  const { favourited } = data;
  return favourited;
}

export function adapterDetails(res) {
  return res.data;
}
export function streamAdapter(res) {
  const { data } = res;
  return data;
}
export function pillarsStatsAdapter(res) {
  const { data } = res;
  return data;
}

export function donutStatsAdapter(res) {
  const { data } = res;
  return data;
}

export const geoStatsAdapter = data => data.map(({ key, doc_count: count, id }) => (
  [key, count, id]
));

export function hashtagStatsAdapter(res) {
  const { data } = res;
  return data;
}

export function statsAdapter(type, res) {
  switch (type) {
    case 'geo-country': return geoStatsAdapter(res);
    case 'tam1': return pillarsStatsAdapter(res);
    case 'type': return donutStatsAdapter(res);
    case 'most_followed': return pillarsStatsAdapter(res);
    case 'hashtag': return hashtagStatsAdapter(res);
    case 'tam2': return pillarsStatsAdapter(res);
    default: return res;
  }
}

function getSortField(type) {
  const table = {
    player: ['meta.lastname'],
    competition: ['meta.display_ord', 'label'],
    team: ['meta.common_name'],
    title: ['_source.label'],
  };

  return table[type] || 'label';
}

function sortSection(title, values) {
  if (title === 'player') {
    return _sortBy(values, player => _.includes(player.label, '. ') ? _.split(player.label, '. ')[1] : _trim(player.label))
  }
  return _sortBy(values, getSortField(title))
}

function sortGroup(plain_sections, groups) {
  return plain_sections.reduce(
    (res, section) => ({
      ...res,
      [section]: sortSection(section, groups[section]),
    }),
    {},
  );
}

const groupsOrdered = [
  'continent',
  'competition',
  'country',
  'team',
  'x_national_team',
  'self_player',
  'player',
  'x_coach',
  'x_executive',
  'x_team',
  'x_press',
  'x_agent',
  'x_legend',
  'x_old_players',
  'x_referee',
  'x_business',
  'x_newspaper',
  'x_journalist',
  'x_influencer',
  'x_tv',
  'x_family'
]

function sortSections(plain_sections) {
  return plain_sections.sort((a, b) => (groupsOrdered.indexOf(a) - groupsOrdered.indexOf(b)));
}


export function adapterMenuList(data, url) {
  const groups = _groupBy(data, 'type')
  const sectionKeys = _filter(Object.keys(groups), group => group !== 'undefined')
  const sortedGroups = sortGroup(sectionKeys, groups)
  const sections = sortSections(sectionKeys)

  return {
    groups: sortedGroups,
    sections,
    url
  }
}

export function adapterFavouritesList(res) {
  const { data } = res;
  const result = data.reduce(
    (res, curr) => ({
      ...res,
      [curr.tag]: curr,
    }),
    {},
  );
  // console.log('adapter favourites list ', result)
  return result;
}

export function getIconID(arg = '') {
  switch (arg) {
    case 'tw': {
      return ['fab', 'x-twitter'];
    }
    case 'ig': {
      return ['fab', 'instagram'];
    }
    case 'fb': {
      return ['fab', 'facebook-f'];
    }
    case 'rss': {
      return 'rss';
    }
    default: {
      return 'globe-americas';
    }
  }
}

export function adapterAutocompleteData(res) {
  const { data } = res;
  const plain_groups = _groupBy(data, '_type');
  // console.log('raggruppati per tipo', sections)
  const plain_sections = Object.keys(plain_groups);
  // console.log('adapterFn', { sections, groups })
  const groups = sortGroup(plain_sections, plain_groups);

  const sections = sortSections(plain_sections);
  // se ho lo score ordino per score altrimenti uso lo stesso metodo del menu
  return {
    sections,
    groups,
  };
}

function toDateString(dateObj = {}) {
  const stringPeriod = JSON.stringify(dateObj);
  const encodedPeriod = encodeURI(stringPeriod);
  const fixedEncodedPeriod = encodedPeriod.replace(/\+/g, '%2B');
  return fixedEncodedPeriod;
}

export function applyFilters(path, opts = {}) {
  const queryString = Object.keys(opts)
    .map((k) => {
      if (k === 'social_types') {
        return `${[k]}=${opts[k]}`;
      }
      if (k === 'q') {
        return opts[k]
          ? `${[k]}=${opts[k]}`
          : null;
      }
      if (k === 'd') {
        const dateString = toDateString(opts[k]);
        return `${encodeURI(k)}=${dateString}`;
      }
      if (_isObject(opts[k])) {
        const obj = Object.keys(opts[k])
          .reduce(
            (res, kk) => ({
              ...res,
              [kk]: opts[k][kk].length > 0
                ? opts[k][kk]
                : [],
            }),
            {},
          );

        return `${encodeURI(k)}=${encodeURI(JSON.stringify(obj))}`;
      }
      return `${k}=${opts[k]}`;
    })
    .join('&')
    .replace(/&&/g, '&');
  const baseUrl = urlBuilder(path);
  const URL = `${baseUrl}?${queryString}`;
  return URL;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  const expiryDate = new Date();
  expiryDate.setTime(0);

  cookies.map((cookie) => {
    const [key] = cookie.split('=');
    return document.cookie = `${key}=;expire=${expiryDate.toGMTString()}`;
  });
}
export function toShortDate(string) {
  return string.split(' ')[0];
}

export function readlistAdapter(res) {
  const { data } = res;
  return data;
}

export function removeHashtagChar(string = '') {
  if (string && string.indexOf('#') > -1) {
    return string.substring(1, string.length);
  }
  return string;
}

export function getDifference(arr1, arr2) {
  const id1 = arr1.map(a => a._id);
  const id2 = arr2.map(a => a._id);
  const delta = _difference(id1, id2);
  // console.log('delta --->', delta)
  if (_isEmpty(delta)) {
    return false;
  }
  return delta;
}

// token-expiry-dates.js
export function saveExpiryDate(value) {
  window.localStorage.setItem('expiration', value);
}

export function loadExpiryDate() {
  return window.localStorage.getItem('expiration');
}

export function isTokenElapsed() {
  const raw = loadExpiryDate();
  const expiryDate = new Date(raw).getTime();
  const now = Date.now();

  return (expiryDate - now);
}

// end

export function getFeedId(opts) {
  const {
    tag,
    original,
    hashtag,
    isCrossFeed,
    isFavourite,
    hashtagTag,
    fulltext,
  } = opts;

  if (isCrossFeed) {
    return `${tag}-${hashtagTag}`;
  }
  if (isFavourite) {
    return `favourites-${original}`;
  }
  if (hashtag) {
    return hashtag;
  }
  if (fulltext) {
    return fulltext;
  }
  if (tag) {
    return `${original}-${tag}`;
  }

  return 'World';
}
// function getFeedId(feedOpts = {}) {
// }

export function getFeedOptions(options) {
  const {
    tag,
    original,
    hashtag,
    hashtagLabel,
    hashtagTag,
    isCrossFeed,
    isCrossTag,
    isFavourite,
    // fromSocials,
    // aboutSocials,
    // filters,
  } = options;

  let opts;
  if (isCrossFeed) {
    if (isCrossTag) {
      opts = {
        ...opts,
        o: 0,
        h: true,
        q: hashtagLabel,
        r: tag,
        t: 0,
      };
    } else {
      opts = {
        ...opts,
        o: 0,
        q: hashtagTag,
        r: tag,
        t: 0,
      };
    }
  } else if (isFavourite) {
    opts = {
      q: tag,
      ...opts,
      original,
    };
  } else if (hashtag) {
    opts = {
      q: hashtag,
      h: true,
    };
  } else if (tag) {
    opts = {
      q: tag,
      ...options,
      original,
      // filters: {
      //   ...filters,
      //   social_types: original === 1
      //     ? fromSocials
      //     : aboutSocials,
      // },
    };
  }
  return opts;
}

export function getSelectedFilters(filter) {
  const allowedFilters = [
    'business',
    'press',
    'legend',
    'player',
    'team',
    'national_team',
    'coach',
    'family',
  ];
  const res = allowedFilters.reduce(
    (tot, f) => ((filter.includes(f))
      ? tot + 1
      : tot),
    0,
  );
  return res;
}

export function getSelectedElement(args) {
  const {
    cursor,
    sections = [],
    groups = {},
    input,
  } = args;
  const list = sections
    .reduce(
      (res, section) => [...res, ...groups[section]],
      [],
    );
  const finalList = [
    ...list.map(e => _get(e, '_id', -1)),
    `#${input}`,
    input,
  ];
  const labelList = [
    ...list.map(e => _get(e, '_source.label')),
    `#${input}`,
    input,
  ];

  return {
    urlTag: finalList[cursor],
    label: labelList[cursor],
  };
}

export function groupPartialsBySocial(partials = [], socials = []) {
  return socials.reduce(
    (res, social) => ({
      ...res,
      [social]: partials.map(partial => partial[social] || null),
    }),
    {},
  );
}
export function groupSentimentPartialsBySocial(partials = [], socials = []) {
  return socials.reduce(
    (res, social) => ({
      ...res,
      [social]: partials.map(partial => (partial[social] * 20).toFixed(1) || null),
    }),
    {},
  );
}

export function groupSentimentPartialsAtanBySocial(partials = [], socials = []) {
  return socials.reduce(
    (res, social) => ({
      ...res,
      [social]: partials.map(partial => (Math.atan(partial[social] * 20) * 50).toFixed(1) || null),
    }),
    {},
  );
}

export function decodeHTML(html) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export function browserDetect() {
  // Chrome 1 - 71
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Blink engine detection
  const isBlink = (isChrome) && !!window.CSS;
  return isBlink;
}

export const getFilterNameFromOriginal = (origin) => {
  switch (origin) {
    case 1: return 'fromSocials';
    case 2: return 'aboutSocials';
    case 3: return 'analyticsSocials';
    default: return false;
  }
};

export const getImageFromSource = ({
  type,
  ig: { picture: igPicture, type: igType } = {},
  fb: { picture: fbPicture } = {},
  tw: { media_url: mediaUrl } = {},
}) => {
  let parsedPreviewImage;

  /**
   * We are checking if instagram type is not video
   * because IG videos returns also an image
   */
  if (igType !== 'video') {
    switch (type) {
      case 'ig':
        parsedPreviewImage = igPicture;
        break;

      case 'tw':
        parsedPreviewImage = mediaUrl;
        break;

      case 'fb':
        parsedPreviewImage = fbPicture;
        break;

      default:
        parsedPreviewImage = null;
    }

    if (
      parsedPreviewImage
      && parsedPreviewImage.includes('&')
      && !parsedPreviewImage.includes('?')
    ) {
      const [justLink] = parsedPreviewImage.split('&');
      parsedPreviewImage = justLink;
    }
  }

  return parsedPreviewImage;
};

export const PLAYER_ND_IMAGE_URL = 'https://cdn5.wyscout.com/photos/players/public/ndplayer_100x130.png';