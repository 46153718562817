const GET_READLIST_REQUEST = 'getReadList/REQUEST';
const GET_READLIST_SUCCESS = 'getReadList/SUCCESS';
const GET_READLIST_FAILURE = 'getReadList/FAILURE';
const POST_READLIST_REQUEST = 'postReadList/REQUEST';
const POST_READLIST_SUCCESS = 'postReadList/SUCCESS';
const POST_READLIST_FAILURE = 'postReadList/FAILURE';
const DELETE_READLIST_REQUEST = 'deleteReadList/REQUEST';
const DELETE_READLIST_SUCCESS = 'deleteReadList/SUCCESS';
const DELETE_READLIST_FAILURE = 'deleteReadList/FAILURE';

const get = {
  request: GET_READLIST_REQUEST,
  success: GET_READLIST_SUCCESS,
  failure: GET_READLIST_FAILURE,
};

const post = {
  request: POST_READLIST_REQUEST,
  success: POST_READLIST_SUCCESS,
  failure: POST_READLIST_FAILURE,
};

const remove = {
  request: DELETE_READLIST_REQUEST,
  success: DELETE_READLIST_SUCCESS,
  failure: DELETE_READLIST_FAILURE,
};

export { get, post, remove };
