import React from 'react'

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
}

const Column = (props = {}) => {
  const {
    children,
    style,
    className,
    id,
    title,
    onClick,
  } = props

  return (
  <div
    id={id}
    style={{ ...baseStyle, ...style }}
    className={className}
    title={title}
    onClick={onClick}
  >
      {children}
    </div>
  )
}

Column.defaultProps = {
  children: null,
  style: {},
}


export default Column
