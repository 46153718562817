import { useState } from 'react'

const storageKey = 'lastSearchesInjuries'

const useLastSearches = () => {
  const localStorage = window.localStorage.getItem(storageKey)
  const defaultValue = localStorage ? JSON.parse(localStorage) : []
  const [lastSearches, setLastSearches] = useState(defaultValue)

  const handleLastSearches = (value) => {
    let tempState = [...lastSearches]

    if (value) {
      if (tempState.length === 20) {
        tempState.pop()
      }
      if (tempState.map(v => v.toLowerCase()).includes(value.toLowerCase())) {
        tempState = tempState.filter(v => v.toLowerCase() !== value.toLowerCase())
      }
      tempState.unshift(value)

      window.localStorage.setItem(storageKey, JSON.stringify(tempState))
      setLastSearches(tempState)
    }
  }

  return [lastSearches, handleLastSearches]
}

export default useLastSearches
