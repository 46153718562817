import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function ScrollToTop({ componentRef, history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (componentRef.current) {
        componentRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    })
    return () => {
      unlisten()
    }
  }, [])

  return null
}

export default withRouter(ScrollToTop)
