import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { hasInjuriesPermissionSelector } from '../redux/selectors/user'
import Explore from './Sections/Explore/Explore'
import Bookmarked from './Sections/Bookmarked'
import Favourites from './Sections/Favourites'
import Search from './Sections/Search'

const Main = () => {
  const hasInjuriesPermission = useSelector(hasInjuriesPermissionSelector)
  if (hasInjuriesPermission === undefined) return null

  return (
    <Switch>
      <Redirect push exact from="/" to="/search" />
      <Route path="/search" component={ Search } />

      <Route path="/explore/fulltext/:fulltext/" render={ props => <Explore {...props} /> } />
      <Route path="/explore/hashtag/:hashtag/" render={ props => <Explore {...props} /> } />
      <Route path="/explore/:tag/" render={ props => <Explore {...props} /> } />
      <Route path="/explore" render={ props => <Explore {...props} /> } />
      
      <Route path="/favourites" render={ props => <Favourites {...props} /> } />
      <Route path="/bookmarked" component={ Bookmarked } />
    </Switch>
  )
}

export default Main
