import React from 'react';
import { withTranslation } from 'react-i18next';
import { Chart } from 'react-google-charts';
import DataLoading from './DataLoading';

const GeoChart = ({
  data,
  name,
  hashtag,
  fulltext,
  isFavourite,
  t,
  loading,
  trigger,
}) => {
  let title = `${t('analytics-geo-chart')} ${t('world')}`;

  if (hashtag) {
    title = `${t('analytics-geo-chart')} #${hashtag}`;
  } else if (fulltext) {
    title = `${t('analytics-geo-chart')} ${fulltext}`;
  } else if (isFavourite) {
    title = t('favourite-analytics-geo-chart');
  } else if (name) {
    title = `${t('analytics-geo-chart')} ${name}`;
  }

  const renderChart = () => {
    if (loading) {
      return <DataLoading />;
    }

    if (data && data.length > 0) {
      return (
        <Chart
          chartType="GeoChart"
          data={[
            ['Country', 'Posts', 'ID'],
            ...data,
          ]}
          options={{
            displayMode: 'region',
            colorAxis: { colors: ['#C4D5E4', '#052532'] },
            backgroundColor: '#f7f7f7',
            datalessRegionColor: '#fff',
          }}
          mapsApiKey="AIzaSyCIgcwom2FNaBV7mH5fkVc5IZRq61pFIS0"
          rootProps={{ 'data-testid': '2' }}
          chartEvents={[
            {
              eventName: 'select',
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length !== 0) {
                  const region = data[selection[0].row];
                  trigger(region[0], region[2], { isGeo: region[2] });
                }
              },
            },
          ]}
        />
      );
    }

    return (
      <div className="no-stats">
        {t('stats_not_available')}
      </div>
    );
  };

  return (
    <div className="geo-chart-container">
      <h1 className="geo-title">
        { title }
      </h1>
      {renderChart()}
    </div>
  );
};
export default withTranslation('translation')(GeoChart);
