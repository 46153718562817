import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components/macro'
import { cssvar } from '../../styles/var'
import CloseIcon from '../../images/close.svg'


export default function Dialog({ open, title, subtitle, header = null, footer, dialogStyle = "", onClose, closable = true, fullscreenOnPhone, children, closeBtnRightDistance = '15px' }) {

  useEffect(() => {
    document.addEventListener('keydown', onEscape)
  })
  const onEscape = ({ keyCode }) => {
    if (keyCode === 27) onClose()
  }

  return (
    <CSSTransition
      in={ open }
      classNames="dialog-animation"
      timeout={ 300 }
      mountOnEnter
      unmountOnExit>
      <DialogWrapper>
        <DialogBody 
          className="dialog-body"
          dialogStyle={ dialogStyle }
          fullscreenOnPhone={ fullscreenOnPhone }>
          <DialogHeader>
            { header }
            { title && <Title>{ title }</Title> }
            { subtitle && <SubTitle>{ subtitle }</SubTitle> }
            {closable && <Close onClick={onClose} closeBtnRightDistance={closeBtnRightDistance} />}
          </DialogHeader>
          <DialogContent>
            { children }
          </DialogContent>
          { footer && <DialogFooter fullscreenOnPhone={ fullscreenOnPhone }>{ footer }</DialogFooter> }
        </DialogBody>
        <Background className="dialog-bg" onClick={ closable && onClose } />
      </DialogWrapper>
    </CSSTransition>
  )
}

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
`
const Background = styled.div`
  animation-duration: .3s;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 12, 16, .75);
`
const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  min-width: 300px;
  max-width: 100vw;
  margin: 0 15px;
  background-color: #fff;
  color: ${ cssvar('mainColor') };
  border-radius: 4px;
  font-weight: 420;
  text-align: left;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  z-index: 100;
  ${ ({ dialogStyle }) => dialogStyle };
  ${ ({ fullscreenOnPhone }) => fullscreenOnPhone && css`
    @media (max-device-width: 640px) {
      margin: 0;
      max-height: none;
      height: -webkit-fill-available;
    }
  ` }
`
const DialogHeader = styled.div`
  flex: 0 0 auto;
  padding: 15px;
  padding-right: 55px;
  background-color: ${ cssvar('mainColor') };
  color: #fff;
  text-align: left;
  position: relative;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: 550;
  line-height: 1.4;
`
const SubTitle = styled.div`
  font-size: 12px;
  color: rgba(255,255,255, .75);
  line-height: 1.4;
`
const Close = styled.div`
  width: 26px;
  height: 26px;
  background-image: url(${ CloseIcon });
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  position: absolute;
  right: ${ ({ closeBtnRightDistance }) => closeBtnRightDistance };
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: .7;
  transition: .3s;
  &:hover {
    background-color: rgba(255,255,255, .1);
    opacity: 1;
  }
`
const DialogContent = styled.div`
  flex: 1 1 auto;
  padding: 15px;
  padding-bottom: 5px;
  overflow: scroll;
`
const DialogFooter = styled.div`
  flex: 0 0 auto;
  padding: 15px;
  border-top: 1px solid ${ cssvar('borderColor') };
  @media (display-mode: standalone) {
    ${ props => props.fullscreenOnPhone && css`
      height: 80px;
    ` }
  }
`