import { configureStore } from 'redux-starter-kit'

/**
 * Reducers
 */
import feeds from './ducks/feeds'
import stats from './statistics/reducer'
import filters from './ducks/filters'
import auth from './ducks/auth'
import navDrilldown from './navDrilldown/reducer'
import navDrilldownHeader from './navDrilldownHeader/reducer'
import favourites from './favourites/reducer'
import readList from './readList/reducer'

const { NODE_ENV } = process.env

const store = configureStore({
  reducer: {
    feeds,
    stats,
    filters,
    auth,
    favourites,
    navDrilldown,
    navDrilldownHeader,
    readList
  },
  devTools: NODE_ENV !== 'production'
})

export default store
