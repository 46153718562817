import { get as getNavDrilldown } from './constants'

const initialState = {
  loading: false,
  error: false,
  data: []
}

const navDrilldown = (
  state = initialState,
  { type, data, error }
) => {
  switch (type) {
    case getNavDrilldown.request:
      return {
        loading: true,
        error: false,
        data: state.data
      }

    case getNavDrilldown.success:
      return {
        loading: false,
        error: false,
        data: data,
      }

    case getNavDrilldown.failure:
      return {
        loading: false,
        error,
        data: null
      }

    default:
      return state
  }
}

export default navDrilldown