import axios from 'axios'
import { BASE_V2_URL } from '../api'
import history from '../history'
// import { readToken } from './auth'

export const createEnquiry = (playerId, type = 'injury') => (
  axios.post(`${BASE_V2_URL}/enquiries`, { playerId, type })
)

export const readEnquiry = (id, tag) => {
  axios
  .put(`${BASE_V2_URL}/enquiries/${id}/markasread`)
  .then(() => {
    history.push(`/explore/${tag}/injuries`)
  })
}

export const readAllEnquiries = () => {
  axios.put(`${BASE_V2_URL}/enquiries/markasread`)
}