import React from 'react'
import styled from 'styled-components'
import missingImage from '../../../images/missing-preview-image.jpg'
import missingVideo from '../../../images/missing-preview-video.jpg'
import video from '../../../images/Video.svg'
import { cssvar } from '../../../styles/var'
import ImageSkeleton from './ImageSkeleton'

const SocialPostDetail = ({ caption, type, picture, link, social }) => {
  const handlePreviewError = (e) => {
    e.target.onerror = null
    e.target.src = missingImage
  }
  const handleVideoPreviewError = (e) => {
    e.target.onerror = null
    e.target.src = missingVideo
  }
  const renderPreview = (type, previewImage) => {
    if (type === 'video') {
      return (
        <PreviewImageContainer href={link}>
          <VideoImage />
          <ImageSkeleton
            src={social === 'ig' ? previewImage : missingVideo}
            height={200}
            onError={handleVideoPreviewError}
          />
        </PreviewImageContainer>
      )
    }

    if (previewImage) {
      return (
        <PreviewImageContainer href={link}>
          <ImageSkeleton src={previewImage} height={200} onError={handlePreviewError} />
        </PreviewImageContainer>
      )
    }

    return null
  }
  return (
    <>
      {renderPreview(type, picture)}
      <Caption>{caption}</Caption>
    </>
  )
}

export default SocialPostDetail

const Caption = styled.div`
  padding-top: 10px;
  font-size: 15px;
  font-weight: 450;
  line-height: 1.5;
  overflow-wrap: anywhere;
`
const PreviewImageContainer = styled.a`
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  border: 1px solid ${cssvar('borderColor')};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`
const VideoImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${video});
    background-position: center;
    background-size: 20%;
    background-repeat: no-repeat;
  }
`
const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const VideoPreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
