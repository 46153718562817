import React, { useState, useEffect, useRef } from 'react'
import { Router, Route, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import i18next from '../../i18n/index'
import T from '../Generic/T'

import Sidebar from '../Sidebar'
import Toolbar from '../Toolbar'

import NewsFeed from './NewsFeed'
import LiveStats from './LiveStats/LiveStats'

import ScrollToTop from '../../utils/ScrollToTop'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components/macro'

const Favourites = ({ match, location, navDrilldownHeader, history }) => {
  const router = useHistory()
  const contentRef = useRef(null)
  const [language, setLanguage] = useState(i18next.language)
  i18next.on('languageChanged', (lang) => {
    setLanguage(lang)
  })
  const { params = {}, data, path, url } = match
  const { tag, starred, hashtag, fulltext } = params
  const navDrilldownHeaderData = navDrilldownHeader.data || {}
  const navDrilldownHeaderLoading = navDrilldownHeader.loading
  const { name } = navDrilldownHeaderData

  useEffect(() => {
    if (location.pathname !== url && !_.includes(location.pathname, 'news')) return
    return router.push(`${url}/${favouritesPages[0].link}`)
  }, [navDrilldownHeaderData])

  const favouritesPages = [
    {
      link: 'news',
      name: <T i18nKey="tab_details" />,
      component: () => (
        <NewsFeed
          tag={ tag }
          starred={ starred }
          hashtag={ hashtag }
          isFavourite
          name={ name }
          fulltext={ fulltext }
          language={ language } />
      )
    }, {
      link: 'drilldown',
      name: <T i18nKey="tab_favourites" />,
      condition: true,
      onlyDevices: true,
      component: () => (
        <Sidebar
          showAsSection
          data={ data }
          isFavourite
          language={ language } />
      )
    }, {
      link: 'media_stats',
      name: <T i18nKey="tab_analytics" />,
      notResponsive: true,
      component: () => (
        <LiveStats
          tag={ tag }
          hashtag={ hashtag }
          name={ name }
          fulltext={ fulltext }
          isFavourite />
      )
    }
  ]

  return (
    <PageContainer>
      <Sidebar
        data={ data }
        isFavourite
        language={ language } />
      { !_.includes(location.pathname, 'drilldown') && navDrilldownHeaderLoading ? (
        <ContentContainer>
          <ProgressWrapper>
            <CircularProgress />
          </ProgressWrapper>
        </ContentContainer>
      ) : (
        <ContentContainer>
          <Toolbar
            links={ favouritesPages }
            params={ params }
            linkURL={ url }
            fulltext={ fulltext }
            language={ language } />
          <Content ref={ contentRef }>
            <ScrollToTop componentRef={ contentRef } />
            <Router history={ history }>
              { favouritesPages.map(page => (
                <Route path={ `${path}/${page.link}` } render={ () => page.component() } />
              )) }
            </Router>
          </Content>
        </ContentContainer>
      ) }
    </PageContainer>
  )
}

const mapStateToProps = ({ navDrilldownHeader }) => ({
  navDrilldownHeader
})
export default connect(mapStateToProps)(Favourites)

const PageContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
`
const ContentContainer = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7f7;
`
const Content = styled.div`
  flex: 1 1 auto;
  overflow: scroll;
  padding: 0 max(0px, calc(calc(100% - 1440px) / 2));
`
const ProgressWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`