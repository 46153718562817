import { createReducer, createAction } from 'redux-starter-kit';
import { getDifference } from '../../helpers';

export const feedSnapshot = createAction('FEED_SNAPSHOT');
export const feedUpdateTop = createAction('FEED_UPDATE_TOP');
export const feedUpdateBottom = createAction('FEED_UPDATE_BOTTOM');
export const feedRemoveAllNew = createAction('FEED_REMOVE_ALL_NEW');
export const feedRemoveAll = createAction('FEED_REMOVE_ALL');
export const flushNews = createAction('FEED_FLUSH');
export const saveCrossFeedOpts = createAction('FEED_CROSS_SAVE_OPTS');

export default createReducer({}, {
  [feedSnapshot]: (state, action) => {
    const { payload } = action;
    const { id, data } = payload;
    // const idString = id.toString()
    state[id] = data;
  },

  [feedUpdateTop]: (state, action) => {
    const { id, data } = action.payload;
    state[`${id}-new`] = data;
  },

  [flushNews]: (state, action) => {
    const { id } = action.payload;
    const news = state[`${id}-new`] || [];
    const res = news.concat(state[id]);
    res.length = 20;
    state[id] = res;
    state[`${id}-new`] = [];
  },

  [feedUpdateBottom]: (state, action) => {
    const { id, data } = action.payload;
    const prevFeed = [...state[id]] || [];
    const delta = getDifference(data, prevFeed);
    state[id] = delta
      ? prevFeed.concat(data)
      : prevFeed;
  },

  [feedRemoveAllNew]: (state, action) => {
    const { id } = action.payload;
    state[`${id}-new`] = [];
  },
  [feedRemoveAll]: (state, action) => {
    const { id } = action.payload;
    state[id] = [];
  },

  [saveCrossFeedOpts]: (state, action) => {
    const { data } = action.payload;
    state.cross = data;
  },
});
