import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import includes from 'lodash/includes'
import ReactSVG from 'react-svg';

import Row from './Row';
import ProfilePicture from './ProfilePicture';

import { removeHashtagChar } from '../../helpers';
import TrashIcon from '../../images/Trash.svg';
import SettingsIcon from '../../images/Settings.svg';
import InjuryIcon from '../../images/hospital.svg';

const linkStyle = { width: '100%' };
const injuriedStyle = { marginRight: -47, color: 'rgb(199, 46, 31)', fontWeight: 600 }

const ListElement = ({
  tag,
  image,
  label,
  team,
  currentInjury,
  isHashtag,
  isFullText,
  customId,
  onDelete,
  onEdit,
}) => {
  const router = useHistory()
  const id = tag || customId;
  const location = window.location.pathname;
  const active = includes(location, tag) ? 'active' : 'inactive';
  let rowOptions;

  const handleEdit = () => { onEdit(tag); };
  const handleDelete = () => { onDelete(tag, { hashtagDelete: isHashtag }); };

  const renderIcons = () => {
    const toRender = [];

    if (onEdit && !isHashtag) {
      toRender.push(
        <ReactSVG
          key="editSVG"
          src={SettingsIcon}
          onClick={handleEdit}
        />,
      );
    }

    if (onDelete) {
      toRender.push(
        <ReactSVG
          key="deleteSVG"
          src={TrashIcon}
          onClick={handleDelete}
        />,
      );
    }

    return <div className="icons-container">{toRender}</div>;
  };

  if (isFullText) {
    rowOptions = {
      to: `/explore/fulltext/${tag}`,
      profileImage: 'fulltext',
    };
  } else if (isHashtag) {
    rowOptions = {
      to: `/explore/hashtag/${removeHashtagChar(tag)}`,
      profileImage: 'hastag',
    };
  } else {
    rowOptions = {
      to: `/explore/${tag}`,
      profileImage: image,
      teamContent: team,
    };
  }

  const { to, profileImage, teamContent } = rowOptions;
  const linkHref = includes(router.location.pathname, to) ? router.location.pathname : to
  const cellStyle = currentInjury ? { ...linkStyle, ...injuriedStyle } : linkStyle

  return (
    <Row className={`list-element ${active}`}>
      <Link id={id} to={linkHref} style={cellStyle}>
        <ProfilePicture image={profileImage} label={label} isFulltext={profileImage === 'fulltext'} />
        <span className="label-container">
          {label}
          { currentInjury && <img
            src={InjuryIcon}
            className="injury-icon"
            title={ currentInjury.label }
            alt={ currentInjury.label }
            height={ 18 }
          /> }
          {teamContent && <span className="team-name">{teamContent && ` - ${teamContent}`}</span>}
        </span>
      </Link>

      {(onDelete || onEdit) && renderIcons()}
    </Row>
  );
};

ListElement.propTypes = {
  tag: PropTypes.string.isRequired,
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
  team: PropTypes.string,
  isHashtag: PropTypes.bool,
  isFullText: PropTypes.bool,
  customId: PropTypes.string,
  onDelete: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  onEdit: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
};

ListElement.defaultProps = {
  isHashtag: false,
  isFullText: false,
  customId: '',
  team: '',
  image: '',
  onDelete: false,
  onEdit: false,
};

export default ListElement;
