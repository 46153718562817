import React from 'react'
import styled from 'styled-components'


const setBars = (fi) => {
  let value = { number: 5, color: '#43aa8b' }
  if (fi < 80) value = { number: 4, color: '#90be6d' }
  if (fi < 60) value = { number: 3, color: '#f9c74f' }
  if (fi < 40) value = { number: 2, color: '#f8961e' }
  if (fi < 20) value = { number: 1, color: '#f3722c' }
  return value
}

const FitnessIndex = ({ fitness, stats }) => {
  if (!(fitness >= 0) || !stats) return '-'
  const bars = setBars(fitness)
  return (
    <FitnessIndexWidget>
      <Bars>
        {[...Array(bars.number)].map((n, index) => <Bar key={`recovery-cell-bar-${index}`} color={bars.color} />)}
      </Bars>
      {fitness}
      %
    </FitnessIndexWidget>
  )
}

export default FitnessIndex

const FitnessIndexWidget = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
`
const Bars = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
`
const Bar = styled.div`
  flex: 0 0 auto;
  width: 5px;
  height: 12px;
  margin-top: 1px;
  margin-right: 2px;
  background-color: ${({ color }) => color};
  &:last-child {
    margin: 0;
  }
  @media (max-width: 1440px) {
    height: 11px;
    margin-top: 0;
  }
`