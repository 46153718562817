import React from 'react'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import Dialog from '../Dialog'

import { connect } from 'react-redux'
import {
  setFilter,
  toggleFilter,
  resetAllFilters,
  applyFiltersUpdate
} from '../../redux/ducks/filters'

import styled, { css } from 'styled-components'
import { cssvar } from '../../styles/var'

const origins = [
  'player',
  'team',
  'national_team',
  'coach',
  ['legend', 'old_players'],
  'family',
  ['business', 'executive', 'agent'],
  ['press', 'tv', 'newspaper', 'journalist', 'influencer']
]
const langs = ['en', 'it', 'es', 'zh', 'ar','pt', 'ja', 'kr', 'fr', 'de']

class FiltersDialog extends React.Component {
  
  handleReset = () => {
    this.props.resetAll()
    this.props.applyUpdate(true)
    this.props.onClose()
  }
  handeApply = () => {
    this.props.applyUpdate(true)
    this.props.onClose()
  }

  render() {
    const { t, open, onClose, filters, updateFilter } = this.props

    return (
      <Dialog
        open={ open }
        onClose={ onClose }
        title={ t('filters') }
        subtitle={ t('filters_info') }
        footer={
          <Buttons>
            <Button red onClick={ this.handleReset }>{ t('reset_filters') }</Button>
            <Button onClick={ this.handeApply }>{ t('apply_filters') }</Button>
          </Buttons>
        }>
        <FilterTitle>{ t('filter-type') }</FilterTitle>
        <FilterOptions>
          {
            origins.map((o) => {
              let key
              _.isArray(o) ? o[0] === 'legend' ? key = _.last(o) : key = o[0] : key = o
              return (
              <FilterOption
                key={`filter-origin-${ key }`}
                active={ filters.type.indexOf(key) > -1 }
                onClick={ () => updateFilter('type', o.toString()) }>
                { t(`f_${key}`) }
              </FilterOption>
              )
            })
          }
        </FilterOptions>
        <FilterTitle>{ t('filter-lang') }</FilterTitle>
        <FilterOptions>
        {
          langs.map(l => (
            <FilterOption
              key={`filter-lang-${ l }`}
              active={ filters.lang.indexOf(l) > -1 }
              onClick={ () => updateFilter('lang', l) }>
              { t(`f_${l}`) }
            </FilterOption>
          ))
        }
        </FilterOptions>
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  const { filters } = state
  return { filters }
}
function mapDispatchToProps(dispatch) {
  return {
    resetAll: () => {
      const action = resetAllFilters()
      dispatch(action)
    },
    updateFilter: (key, value) => {
      const action = ['name', 'from', 'to'].includes(key)
        ? setFilter({ id: key, data: value })
        : toggleFilter({ id: key, data: value })
      dispatch(action)
    },
    applyUpdate: (value) => {
      const action = applyFiltersUpdate(value)
      dispatch(action)
    }
  }
}

export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(FiltersDialog))

const FilterTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 15px;
  &:first-child {
    margin-top: 0;
  }
`
const FilterOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 150px);
  gap: 10px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
`
const FilterOption = styled.div`
  padding: 5px 0;
  border: 1px solid ${ cssvar('borderColor') };
  border-radius: 4px;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background-color: ${ cssvar('background') };
  }
  ${ props => props.active && css`
    background-color: ${ cssvar('primaryColor') };
    color: #fff;
    &:hover {
      background-color: ${ cssvar('primaryHoverColor') };
    }
  ` }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
const Button = styled.div`
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  background-color: ${ props => cssvar(props.red ? 'injuryColor' : 'primaryColor') };
  border-radius: 4px;
  transition: .3s;
  cursor: pointer;
  &:hover {
    background-color: ${ props => props.red ? '#a92518' : cssvar('primaryHoverColor') };
  }
`