import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import history from './history';

class Analytics {
  static init() {
    const { NODE_ENV } = process.env;

    ReactGA.initialize('UA-146509916-1');
    mixpanel.init(NODE_ENV === 'development' ? '6adf66be601ac349232060c23cc2aed5' : '095b5fb0e69c6f74b85047ddc3ed4c0e');
  }

  static start(user = {}) {
    const {
      email,
      name,
      userId,
      planName,
    } = user;

    if (email) {
      mixpanel.identify(userId);
      mixpanel.people.set({
        name,
        email,
        planName,
      });

      this.set({ userID: email });
      this.set({ dimension1: email });
      history.listen((location) => {
        setTimeout(() => {
          Analytics.pageView(location.pathname);
        }, 0);
      });
    }
  }

  static stop() {
    this.set({ userID: null });
    this.set({ dimension1: null });
    history.listen()();
  }

  static set(options) { ReactGA.set(options); }

  static pageView(location) {
    ReactGA.pageview(location);
    mixpanel.track(location);
  }
}

export default Analytics;
