import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { get, post, remove } from './constants';

const initialState = {
  loading: false,
  error: false,
  data: [],
  stream: [],
};

const readList = (
  state = initialState,
  { type, data },
) => {
  switch (type) {
    case get.success:
      return {
        loading: false,
        error: false,
        data: data.map(({ _id }) => _id),
        stream: data,
      };

    case post.success:
      return {
        loading: false,
        error: false,
        data: uniq([...state.data, data]),
        stream: state.stream,
      };

    case remove.success:
      return {
        loading: false,
        error: false,
        data: without(state.data, data),
        stream: state.stream,
      };

    default:
      return state;
  }
};

export default readList;
