import React from 'react'
import PropTypes from 'prop-types'
import NavButton from './NavButton'
import styled from 'styled-components/macro'
import { cssvar } from '../../styles/var'

const SideNav = () => {

  const navData = [
    {
      string: 'Search',
      link: '/search',
      icon: 'HomeSearch'
    }, {
      string: 'Home',
      link: '/explore/world',
      icon: 'Home'
    }, {
      string: 'Favourites',
      link: '/favourites',
      icon: 'Favourite'
    }, {
      string: 'Bookmarked',
      link: '/bookmarked',
      icon: 'Book'
    }
  ]

  return (
    <SideNavStyled>
      <Buttons>
        { navData.map(nav => <NavButton key={ nav.link } data={ nav } disabled={ nav.filter && nav.filter() } />) }
      </Buttons>
    </SideNavStyled>
  )
}

SideNav.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape())
}

SideNav.defaultProps = {
  data: []
}

export default SideNav

const SideNavStyled = styled.aside`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60px;
  padding-bottom: 15px;
  background-color: ${ cssvar('mainColor') };
  @media (max-width: 1024px) {
    display: none;
  }
`
const Buttons = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`