import React from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../images/mobile-back.svg'
import { ReactComponent as ForwardIcon } from '../images/mobile-forward.svg'
import { ReactComponent as SearchIcon } from '../images/mobile-search.svg'
import { ReactComponent as FavouritesIcon } from '../images/mobile-favourites.svg'
import { ReactComponent as ReadListIcon } from '../images/Book.svg'
import styled from 'styled-components'
import { cssvar } from '../styles/var'

const MobileMenu = () => {
  let history = useHistory()
  return (
    <Menu>
      <MenuItem onClick={ () => history.goBack() }>
        <BackIcon height="18px" />
      </MenuItem>
      <MenuItem onClick={ () => history.goForward() }>
        <ForwardIcon height="18px" />
      </MenuItem>
      <MenuItem onClick={ () => history.push(`/favourites/drilldown`) }>
        <FavouritesIcon height="20px" />
      </MenuItem>
      <MenuItem onClick={ () => history.push(`/bookmarked`) }>
        <ReadListIcon height="22px" />
      </MenuItem>
      <MenuItem onClick={ () => history.push(`/search`) }>
        <SearchIcon height="20px" />
      </MenuItem>
    </Menu>
  )
}

export default MobileMenu

const Menu = styled.div`
  display: none;
  flex: 0 0 auto;
  align-items: flex-start;
  justify-content: space-between;
  height: 80px;
  padding: 0 10px;
  background-color: ${ cssvar('background') };
  border-top: 1px solid ${ cssvar('borderColor') };
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 11;
  @media (orientation: landscape) {
    height: auto;
  }
  @media (display-mode: standalone) {
    display: flex;
  }
`
const MenuItem = styled.div`
  flex: 0 0 auto;
  padding: 15px;
  line-height: 1;
  svg path {
    fill: ${ cssvar('mainColor') };
  }
`