import React from 'react'
import { useSelector } from 'react-redux'
import { useSearchContext } from './SearchContext'
import { hasInjuriesPermissionSelector } from '../../../redux/selectors/user'
import { ReactComponent as InjuriesLogo } from '../../../images/injuries-logo.svg'
import { ReactComponent as PRAndMediaLogo } from '../../../images/pr-and-media-logo.svg'
import styled, { keyframes } from 'styled-components/macro'

const Logo = () => {
  const { homeSearch } = useSearchContext()
  const hasInjuriesPermission = useSelector(hasInjuriesPermissionSelector)

  return (
    <LogoWrapper smaller={ !homeSearch }>
      { hasInjuriesPermission ? <InjuriesLogo /> : <PRAndMediaLogo /> }
    </LogoWrapper>
  )
}

export default Logo

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`
const LogoWrapper = styled.div`
  animation: ${ fadeIn } .3s ease-in forwards;
  flex: 0 0 auto;
  width: 100%;
  padding: 0 60px;
  margin-top: ${ props => props.smaller ? '0' : '15vh' };
  text-align: center;
  line-height: 0;
  transition: .3s;
  svg {
    width: 100%;
    max-width: ${ props => props.smaller ? '250px' : '500px' };
    transition: .3s;
  }
  @media (max-width: 640px), (max-height: 600px) {
    margin-top: 0;
    svg {
      max-width: 250px;
    }
  }
`