import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import Table from '../../Table'
import PlayerCell, { PlayerCellStyled } from '../../Table/PlayerCell'
import FitnessIndex from './FitnessIndex'
import { Container, Main, H1 } from '../styledComponents'
import { Injury } from './index'
import { useHistory } from 'react-router-dom'
import { BASE_V2_URL } from '../../../api'
import Formatter from '../../../utils/Formatter'
import styled, { css } from 'styled-components'
import { cssvar } from '../../../styles/var'


const Roster = ({ data }) => {
  const { t } = useTranslation()
  const [roster, setRoster] = useState({ callup: { date: null, players: {} } })

  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios(
          `${BASE_V2_URL}/national-teams/${data.tag}/callups`,
        )
        setRoster(result.data)
      } catch (err) {
        console.log('Unable to load national team for', data.tag)
      }
    }
    fetchData()
  }, [])

  if (!roster) return null

  const columns = [
    {
      title: t('Player'),
      render: ({ player: { profile_data } }) => <PlayerCell image={profile_data.image} name={profile_data.label} />,
    }, {
      title: t('Position'),
      hideOnBreakpoint: true,
      width: 'min-content',
      render: row => (row.stats ? _.upperCase(row.stats.primary_position).replace(' 3', '').replace(' 5', '') : '-'),
    }, {
      title: t('Club'),
      hideOnBreakpoint: true,
      render: ({ player: { profile_data: { meta } } }) => (meta.team_name ? (
        <Club>
          <img src={meta.team_image} alt={meta.team_name} />
          <span>{meta.team_name ? meta.team_name : ''}</span>
        </Club>
      ) : null),
    }, {
      title: t('Matches played'),
      dataIndex: ['stats', 'matches', 'played'],
      align: 'right',
    }, {
      title: t('Matches on bench'),
      dataIndex: ['stats', 'matches', 'bench'],
      align: 'right',
      hideOnBreakpoint: true,
    }, {
      title: t('Matches injured'),
      dataIndex: 'stats',
      align: 'right',
      render: stats => stats.matches.injured || 0,
    }, {
      title: t('Minutes played'),
      dataIndex: ['stats', 'minutes_played'],
      align: 'right',
      hideOnBreakpoint: true,
    }, {
      title: t('Goals'),
      dataIndex: ['stats', 'goals'],
      align: 'right',
      hideOnBreakpoint: true,
    }, {
      title: t('Yellow / red cards'),
      dataIndex: 'stats',
      align: 'right',
      hideOnBreakpoint: true,
      render: stats => `${stats.cards.yellow}/${stats.cards.red}`,
    }, {
      title: t('Fitness index'),
      render: ({ stats, current_status }) => (current_status.ongoing_injuries && current_status.ongoing_injuries.length > 0 ? (
        <Injury currentInjury={current_status.ongoing_injuries[0]} />
      ) : (
        <FitnessIndex fitness={current_status.fitness} stats={stats} />
      )),
    },
  ]

  const rowStyle = (player) => {
    if (player.current_status.ongoing_injuries.length > 0) {
      return css`
      td {
        background: ${Formatter.hexToRgba(cssvar('injuryColor'), 0.05)};
        &:hover {
          background: ${Formatter.hexToRgba(cssvar('injuryColor'), 0.08)};
        }
      }
    `
    }
    return {}
  }

  const players = (roster.callup && _.reject(Object.values(roster.callup.players), p => p.player.profile_data.meta.role === 'Unknown')) || []

  const goToPlayer = (row) => {
    history.push(`/explore/${row.tag}`)
  }

  const callupDate = roster.callup && roster.callup.date
  const callupName = roster.callup && roster.callup.nameIntl

  const groupedData = ['Portiere', 'Difensore', 'Centrocampista', 'Attaccante'].map(group => ({
    name: {
      Portiere: t('position-group-goalkeepers'),
      Difensore: t('position-group-defenders'),
      Centrocampista: t('position-group-midfielders'),
      Attaccante: t('position-group-attackers'),
    }[group],
    data: _.filter(players, p => p.player.profile_data.meta.role === group),
  }))

  return (
    <Container>
      <Main>
        <Title>
          {callupName ? `${callupName} ` : ''}
          {t('tab_callup')}
        </Title>
        <CallupDate>{Formatter.formatDate(callupDate)}</CallupDate>

        <Table
          columns={columns}
          groupedData={groupedData}
          rowStyle={rowStyle}
          hideColumnsBreakpoint="max-width: 640px"
          onRowClick={goToPlayer} />

        <Disclaimer>
          Stats in all competitions starting from August 1st 2020.
          <br />
          {t('fitness-index-disclaimer')}
        </Disclaimer>
      </Main>
    </Container>
  )
}


Roster.defaultProps = {
  data: [],
}

export default Roster

const Title = styled(H1)`
  margin-bottom: 0;
`
const CallupDate = styled.p`
  margin: 0;
  margin-bottom: 30px;
  color: ${cssvar('secondaryTextColor')};
  font-weight: 500;
`
const Club = styled(PlayerCellStyled)`
  min-width: auto;
  img {
    height: 20px;
  }
  span {
    font-weight: 420;
  }
  @media (max-width: 1440px) {
    img {
      height: 16px;
    }
  }
  @media (max-width: 1300px) {
    span {
      display: none;
    }
  }
`
const Disclaimer = styled.div`
  margin-top: 1em;
  font-size: 12px;
  color: ${cssvar('secondaryTextColor')};
  font-weight: 320;
`