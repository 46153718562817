import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { Main, H1 } from '../styledComponents'
import MobileTimeframeSelector from '../../Generic/MobileTimeframeSelector'
import Table from '../../Table'
import PlayerCell from '../../Table/PlayerCell'
import FitnessIndex from './FitnessIndex'
import InjuryIcon from '../../../images/hospital.svg'
import CovidIcon from '../../../images/coronavirus.svg'
import DopingIconLight from '../../../images/dopingPillLight.svg'
import { useHistory } from 'react-router-dom'
import { BASE_V2_URL } from '../../../api'
import Formatter from '../../../utils/Formatter'
import styled, { css } from 'styled-components'
import { cssvar } from '../../../styles/var'
import { ActiveTimeFilterOption } from '../../../redux/ducks/filters'

export const Injury = ({ currentInjury }) => {
  const { t } = useTranslation()

  const icon = currentInjury.description.includes('Covid')
    ? CovidIcon
    : currentInjury.category && currentInjury.category.includes('doping')
      ? DopingIconLight
      : InjuryIcon

  return (
    <InjuryCell>
      <img src={icon} alt="" />
      <span>{t(Formatter.formatExpectedDowntime(currentInjury))}</span>
    </InjuryCell>
  )
}

const Roster = ({ data, filters, navDrilldownHeader }) => {
  const { t } = useTranslation()
  const [roster, setRoster] = useState({ players: [] })

  const history = useHistory()

  const fetchData = useCallback(
    _.debounce((filters) => {
      async function fetch(filters) {
        try {
          const timeframe = filters.activeTimeFilter === ActiveTimeFilterOption.timeframe
            ? filters.timeframe
            : `${moment(filters.from).format('YYYY-MM-DD')},${moment(filters.to).format('YYYY-MM-DD')}`

          const result = await axios(
            `${BASE_V2_URL}/teams/${data.tag}?timeframe=${timeframe}`,
          )
          setRoster({ players: result.data.players })
        } catch (err) {
          console.log('Unable to load data for', data.tag)
        }
      }
      fetch(filters)
    }, 300), [],
  )

  useEffect(() => {
    fetchData(filters)
  }, [filters])

  const rosterPlayers = useMemo(() => Object.values(roster.players), [roster])

  if (!roster) return null

  const columns = [
    {
      title: t('Player'),
      width: 'minmax(min-content, 2fr)',
      render: row => <PlayerCell image={row.player.profile_data.image} name={row.player.profile_data.label} />,
    },
    {
      title: t('Position'),
      width: 'min-content',
      hideOnBreakpoint: true,
      render: row => (row.stats
        ? _.upperCase(row.stats.primary_position).replace(' 3', '').replace(' 5', '')
        : '-'),
    },
    {
      title: t('Matches played'),
      dataIndex: ['stats', 'matches', 'played'],
      align: 'right',
    },
    {
      title: t('Matches on bench'),
      dataIndex: ['stats', 'matches', 'bench'],
      align: 'right',
      hideOnBreakpoint: true,
    },
    {
      title: t('Matches injured'),
      dataIndex: ['stats', 'matches', 'injured'],
      align: 'right',
    },
    {
      title: t('Minutes played'),
      dataIndex: ['stats', 'minutes_played'],
      align: 'right',
      hideOnBreakpoint: true,
    },
    {
      title: t('Goals'),
      dataIndex: ['stats', 'goals'],
      align: 'right',
      hideOnBreakpoint: true,
    },
    {
      title: t('Yellow / red cards'),
      dataIndex: 'stats',
      align: 'right',
      hideOnBreakpoint: true,
      render: stats => `${stats ? stats.cards.yellow : '-'}/${stats ? stats.cards.red : '-'}`,
    },
    ...(filters.timeframe === 'current_season'
      ? [{
        title: t('Fitness index'),
        render: ({ stats, current_status }) => (current_status.ongoing_injuries && current_status.ongoing_injuries.length > 0 ? (
          injuryCell(current_status.ongoing_injuries)
        ) : (
          <FitnessIndex fitness={current_status.fitness} stats={stats} />
        )),
      }] : []),
  ]

  const getOngoingInjury = (ongoingInjuries) => {
    let injury

    const otherEvents = ongoingInjuries.filter(injury => injury.other_event)

    if (otherEvents.length > 0) {
      const dopingEvents = otherEvents.filter(injury => injury.category.includes('doping'))
      if (dopingEvents.length > 0) {
        injury = dopingEvents[0]
      } else {
        injury = otherEvents[0]
      }
    } else {
      const injuries = ongoingInjuries.filter(injury => !injury.other_event)
      injury = injuries[0]
    }

    return injury
  }

  const injuryCell = (ongoingInjuries) => {
    const injury = getOngoingInjury(ongoingInjuries)

    return <Injury currentInjury={injury} />
  }

  const rowStyle = (player) => {
    if (!player.current_status.ongoing_injuries) return {}

    const ongoingInjury = getOngoingInjury(player.current_status.ongoing_injuries)

    if (ongoingInjury) {
      if (ongoingInjury.category.includes('doping')) {
        return css`
      td {
        background: ${Formatter.hexToRgba('#FF8B18', 0.05)};
        &:hover {
          background: ${Formatter.hexToRgba('#FF8B18', 0.08)};
        }
      }`
      }
      return css`
      td {
        background: ${Formatter.hexToRgba(cssvar('injuryColor'), 0.05)};
        &:hover {
          background: ${Formatter.hexToRgba(cssvar('injuryColor'), 0.08)};
        }
      }
    `
    }
    return {}
  }

  const goToPlayer = (row) => {
    history.push(`/explore/${row.tag}`)
  }

  const positionGroups = {
    goalkeepers: ['GK'],
    defenders: ['CB', 'RCB', 'LCB', 'LB', 'RB', 'LCB 3', 'RCB 3', 'LB 3', 'RB 3', 'LB 5', 'RB 5', 'LWB', 'RWB', 'DEF'], // DEF: fallback
    midfielders: ['DMF', 'LDMF', 'RDMF', 'AMF', 'LCMF', 'LCMF 3', 'LCMF 5', 'RCMF', 'RCMF 3', 'RCMF 5', 'LAMF', 'RAMF', 'LW', 'RW', 'MID'], // MID: fallback
    attackers: ['LWF', 'RWF', 'CF', 'SS', 'FOR'], // FOR: fallback
  }

  const players = (roster && rosterPlayers) ? rosterPlayers.filter(player => player.player.profile_data.meta.role !== 'Unknown').filter((player) => {
    // return true
    if (filters.timeframe === 'current_season' && player.player.profile_data.meta.team_tag !== data.tag) {
      return false
    }

    // return player.stats.matches.played + player.stats.matches.bench > 0
    return true
  }) : []

  const roleToFallbackPosition = {
    portiere: 'GK',
    difensore: 'DEF',
    centrocampista: 'MID',
    attaccante: 'FOR',
  }
  for (const player of players) {
    if (!player.stats || player.stats.primary_position || !player.profile_data) continue
    player.stats.primary_position = roleToFallbackPosition[player.profile_data.meta.role.toLowerCase()]
  }

  const groupedData = Object.keys(positionGroups)
    .map(group => ({
      group,
      name: t(`position-group-${group}`),
      data: _.reverse(
        _.sortBy(
          _.filter(players, player => player.stats && player.stats.primary_position && _.includes(positionGroups[group], _.upperCase(player.stats.primary_position))),
          player => (player.stats ? Number(player.stats.minutes_played) : 0),
        ),
      ),
    }))

  const timeframeDisclaimer = (timeframe) => {
    const seasons = navDrilldownHeader.data.seasons || []
    const chosenSeason = _.find(seasons, { value: timeframe })
    const from = chosenSeason ? chosenSeason.start_date : ''
    const to = chosenSeason ? chosenSeason.end_date : ''

    if (timeframe === 'year') return `${t('Stats displaying from')} ${moment().clone().subtract(1, 'years').format('DD.MM.YYYY')}`
    if (timeframe === 'current_season') return `${t('Stats displaying from')} ${moment(from).format('DD.MM.YYYY')}`
    return `${t('Stats displaying from')} ${moment(from).format('DD.MM.YYYY')} to ${moment(to).format('DD.MM.YYYY')}`
  }

  return (
    <Container>
      <MobileTimeframeSelector />
      <Main>
        <H1Styled>{t('tab_roster')}</H1Styled>
        <Table
          columns={columns}
          groupedData={groupedData}
          rowStyle={rowStyle}
          hideColumnsBreakpoint="max-width: 640px"
          onRowClick={goToPlayer} />
        <Disclaimer>
          {timeframeDisclaimer(filters.timeframe)}
          <br />
          {t('season-stats-disclaimer')}
          <br />
          {t('fitness-index-disclaimer')}
        </Disclaimer>
      </Main>
    </Container>
  )
}


function mapStateToProps({ filters, navDrilldownHeader }) {
  return { filters, navDrilldownHeader }
}

export default connect(mapStateToProps)(Roster)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px 15px;
  @media (max-width: 640px) {
    padding: 15px 0;
  }
`

const H1Styled = styled(H1)`
  @media (max-width: 1024px) {
    display: none;
  }
`
const InjuryCell = styled.div`
  display: flex;
  align-items: center;
  img {
    flex: 0 0 auto;
    width: 16px;
    margin-right: 5px;
  }
  span {
    flex: 1 1 auto;
    font-weight: 500;
  }
`
const Disclaimer = styled.div`
  margin-top: 1em;
  font-size: 12px;
  color: ${cssvar('secondaryTextColor')};
  font-weight: 320;
`