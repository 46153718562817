import { createSelector } from 'reselect'

const getUserInfoSelector = ({ auth: { userInfo } }) => userInfo || {}

const hasInjuriesPermissionSelector = createSelector(
  getUserInfoSelector,
  ({ account_capabilities: accountCapabilities }) => accountCapabilities && accountCapabilities.injuries === '1'
)

const hasReportsPermissionSelector = createSelector(
  getUserInfoSelector,
  ({ account_capabilities: accountCapabilities }) => accountCapabilities && accountCapabilities.reports === '1'
)

const hasProfileEditPermissionSelector = createSelector(
  getUserInfoSelector,
  ({ account_capabilities: accountCapabilities }) => accountCapabilities && accountCapabilities.editor === '1'
)

export {
  getUserInfoSelector,
  hasInjuriesPermissionSelector,
  hasReportsPermissionSelector,
  hasProfileEditPermissionSelector
}
