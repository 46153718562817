import React from 'react'
import { Icon } from 'antd'
import { nFormatter } from '../../helpers'
import styled from 'styled-components'

const Social = (props = {}) => {
  const { data } = props
  const { instagram_followers, twitter_followers } = data

  if (!instagram_followers && !twitter_followers) return null

  return (
    <SocialContainer>
      { instagram_followers > 0 && (
        <SocialCounter color="#e4405f">
          <Icon type='instagram' theme="filled" />
          <span>{ nFormatter(instagram_followers) }</span>
        </SocialCounter>
      ) }
    </SocialContainer>
  )
}
export default Social

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 15px;
`
const SocialCounter = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.color};
  font-size: 15px;
  font-weight: 600;
  margin-right: 15px;
  span {
    margin-left: 6px;
    margin-top: 1px;
  }
  &:last-child {
    margin: 0;
  }
  @media(max-width: 1280px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    color: #fff;
  }
`