const GET_STATISTICS_REQUEST = 'getStatistics/REQUEST';
const GET_STATISTICS_SUCCESS = 'getStatistics/SUCCESS';
const GET_STATISTICS_FAILURE = 'getStatistics/FAILURE';

const get = {
  request: GET_STATISTICS_REQUEST,
  success: GET_STATISTICS_SUCCESS,
  failure: GET_STATISTICS_FAILURE,
};

// eslint-disable-next-line import/prefer-default-export
export { get };
