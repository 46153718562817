import styled from 'styled-components'
import { cssvar } from '../../styles/var'

export const Container = styled.div`
  padding: 30px 15px;
  @media (max-width: 640px) {
    padding: 15px 0;
  }
`;

export const Main = styled.div`
  background-color: white;
  padding: 15px;
`;

export const H1 = styled.h1`
  margin-left: 0;
  margin-right: -0.9rem;
  font-size: 1.8em;
  font-weight: 500;
  color: ${ cssvar('mainColor') };
`;

export const H2 = styled.h2`
  margin-left: -0.9rem;
  margin-right: -0.9rem;
  padding: 0 1rem 1rem 0.5em;
  font-size: 1.4em;
  font-weight: 600;
  color: ${ cssvar('mainColor') };
  border-bottom: 1px solid ${ cssvar('borderColor') };
`;

export const H3 = styled.h3`
  margin-left: 0;
  margin-right: -0.9rem;
  padding-top: 1em;
  font-size: 1.2em;
  font-weight: 450;
  color: ${ cssvar('mainColor') };
`
export const Disclaimer = styled.div`
  margin-top: 1em;
  font-size: 12px;
  color: ${ cssvar('secondaryTextColor') };
  font-weight: 320;
  p {
    margin: 0;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0
    }
  }
  @media (max-width: 1024px) {
    text-align: center;
  }
`
