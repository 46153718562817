import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import ReactSVG from 'react-svg'
import { Modal, Input, Icon, Drawer } from 'antd'
import Fade from '@material-ui/core/Fade'
import Column from '../Generic/Column'
import { fetchAutocompleteData } from '../../api/autocomplete'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { getSelectedElement, removeHashtagChar } from '../../helpers'
import infoButton from '../../images/customer-care.svg'
import xButton from '../../images/X_Button.svg'
import Suggestion from '../Generic/Suggestion'
import AdvancedResearchInfo from './AdvancedResearchInfo'
import ListElement from '../Generic/ListElement'
import history from '../../history'
import Analytics from '../../Analytics'

const sectionStyle = {
  paddingLeft: '20px',
  color: '#9e9e9e'
}

const fetchAutocompleteDataDebounced = AwesomeDebouncePromise(fetchAutocompleteData, 150)

class SuggestionModal extends React.Component {
  state = {
    visible: false,
    infoVisible: false,
    sections: [],
    cursor: 0,
    suggestions: [],
    search: ''
  }

  getSuggestions(string) {
    fetchAutocompleteDataDebounced(string)
      .then((res) => {
        const { sections = [], groups = [] } = res
        setTimeout(() => {
          if (this.state.search === string) {
            Analytics.pageView(`/search/${string}`)
          }
        }, 1000)
        this.setState({ sections, groups, search: string })
      })
  }

  showDrawer = () => {
    this.setState({ infoVisible: true })
  }

  closeDrawer = () => {
    this.setState({ infoVisible: false })
  }


  handleChange = (e) => {
    const { target = {} } = e
    const { value } = target
    if (value && value.indexOf('#') <= -1) {
      if (value.length > 2) {
        this.getSuggestions(value)
      }
    }
    this.setState({ cursor: 0, input: value })
    if (value.length <= 2) {
      this.setState({ sections: [], groups: [], input: value })
    }
  }

  resetValues = () => {
    this.setState({ clicked: '', groups: {}, sections: [], input: null })
  }

  resetOnClear = () => {
    this.resetValues()
  }

  showModal = () => {
    this.setState({ visible: true })
  }
  handleCancel = () => {
    this.setState({ visible: false })
  }

  handleSelection = (event) => {
    const selectedElement = getSelectedElement(this.state)
    const { label } = selectedElement
    const target = event.currentTarget.id || event.target.id
    const name = (event.keyCode === 13) ? label : target || label
    this.setState({ visible: false, clicked: name })
  }

  handleKeyDown = (e) => {
    const { cursor } = this.state
    const el = document.querySelectorAll('.my-autocomplete .ant-modal-body')[0]
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) {
      this.setState(prevState => ({
        ...prevState,
        cursor: prevState.cursor - 1,
      }))
      // better to use ref instead
      el.scrollBy({
        top: -40,
        left: 0,
        behavior: 'smooth'
      })
    } else if (e.keyCode === 40) {
      this.setState(prevState => ({
        ...prevState,
        cursor: prevState.cursor + 1
      }))
      el.scrollBy({
        top: 40,
        left: 0,
        behavior: 'smooth'
      })
    } else if (e.keyCode === 13) {
      const path = getSelectedElement(this.state).urlTag;
      if (path.indexOf('#') > -1) {
        history.push(`/explore/hashtag/${removeHashtagChar(path)}`)
      } else if (path.indexOf('_') > -1) {
        history.push(`/explore/${path}`)
      } else {
        history.push(`/explore/fulltext/${path}`)
      }
    }
  }

  render() {
    const { visible, sections, groups, input, infoVisible, clicked } = this.state
    const selectedId = getSelectedElement(this.state).urlTag
    const { t, location } = this.props

    return (
      <Fragment>

        <Fade in={ location.pathname !== '/search' }>
          <Input
            className="top-nav-bar-autocomplete"
            placeholder={ t('search') }
            value={ clicked }
            prefix={ <Icon type="search" style={{ color: '#d9d9d9' }} /> }
            suffix={ clicked && clicked !== '' && <Icon type="close-circle" style={ { color: 'white' } } onClick={ this.resetOnClear } /> }
            onClick={ this.showModal } />
        </Fade>

        <Column>
          <Modal
            className="suggestions-modal-autocomplete my-autocomplete"
            visible={ visible }
            onCancel={ this.handleCancel }
            onOk={ this.handleCancel }
            destroyOnClose
            footer={ null }
            title={(
              <Input
                allowClear
                autoFocus
                placeholder={t('search')}
                onChange={e => this.handleChange(e)}
                suffix={<Icon type="search" className={input ? 'hidden' : 'visible'} />}
                onKeyDown={this.handleKeyDown}
                onPressEnter={this.handleSelection} />
                )}>
            <div className="info-advanced-search">
              <ReactSVG className="info-icon" src={ infoButton } />
              <div className="short-info-wrapper">
                <span>
                  <p className="clickable" onClick={ this.showDrawer }>
                    { t('short_info') }
                  </p>
                </span>
              </div>
              <Drawer
                className="info-drawer"
                title={(
                  <div className="title-row">
                    <Column className="left-top-col">
                      <span className="title">
                        {t('advanced_research')}
                      </span>
                      <span className="subtitle">
                        {/* {t('follow-dialog-desc')} */}
                      </span>
                    </Column>
                    <Column className="top-right-icon-container">
                      <div className="close-x" onClick={this.closeDrawer}>
                        <ReactSVG
                          src={xButton}
                        />
                      </div>
                    </Column>
                  </div>
              )}
                placement="right"
                mask={ false }
                closable={ false }
                onClose={ this.closeDrawer }
                visible={ infoVisible }
                onCancel={ this.closeDrawer }>
                <AdvancedResearchInfo />
              </Drawer>
            </div>
            <Column id="autocomplete" style={{ overflowY: 'auto' }}>
              {
              sections.map((section, index) => (
                <div key={index} style={{ paddingTop: '20px' }}>
                  <div style={sectionStyle} className="section-label-container">
                    { t(section) }
                  </div>
                  {
                    groups[section].map((source, idx) => {
                      const { _source = {}, _id } = source;
                      const { image, label, meta } = _source;
                      const tag = meta ? meta.team_name : '';
                      return (
                        <div
                          onClick={e => this.handleSelection(e)}
                          key={idx}
                          id={label}
                          className={(selectedId === _id) ? 'active' : 'inactive'}>
                          <ListElement
                            tag={_id}
                            image={image}
                            label={label}
                            key={index}
                            team={tag} />
                        </div>
                      )
                    })
                    }
                </div>
              ))
            }
              {
              input && input.length
                ? (
                  <div className="static-suggestions">
                    <Suggestion
                      id={`#${input}`}
                      selectedId={selectedId}
                      input={input}
                      selection={e => this.handleSelection(e)}
                      hashtag
                      type="Hashtag"
                    />
                    <Suggestion
                      id={input}
                      selectedId={selectedId}
                      input={input}
                      selection={e => this.handleSelection(e)}
                      fulltext
                      type={ t('full_text_search') }
                    />
                  </div>
                )
                : ''
              }
            </Column>
          </Modal>
        </Column>
      </Fragment>
    )
  }
}

export default withTranslation('translation')(withRouter(SuggestionModal))
