import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled('div')`
  margin-top: 1.2em;
`;

const Label = styled('p')`
  margin-bottom: 0;
  color: #9e9e9e;
  font-size: 0.8em;
`;

const Content = styled('p')`
  color: #092432;
  margin: 0;
`;

const InfoRow = ({ label, children }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Label>{t(label)}</Label>
      <Content>{children}</Content>
    </Container>
  );
};

InfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.string,
};

InfoRow.defaultProps = {
  children: '',
};

export default InfoRow;
