import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { loadProgressBar } from 'axios-progress-bar'
import Profile from './Profile'
import ChangeLanguage from './ChangeLanguage'
import Notifications from './Notifications'
import SuggestionModal from './SuggestionModal'
import Logo from '../../images/logo.svg'
import styled from 'styled-components/macro'
import { cssvar } from '../../styles/var'

export default function Header() {
  useEffect(() => {
    loadProgressBar()
  })

  return (
    <HeaderStyled>
      <LogoLink to={ '/search' } />
      <SuggestionModal />
      <Settings>
        <ChangeLanguage />
        <Notifications />
        <Profile />
      </Settings>
    </HeaderStyled>
  )
}

const HeaderStyled = styled.header`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  padding-left: 20px;
  padding-right: 30px;
  background-color: ${ cssvar('mainColor') };
  color: #fff;
  @media (max-width: 1024px) {
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255, .15);
  }
  @media (max-width: 1024px) and (min-height: 500px) {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 11;
  }
  @media (max-width: 640px) {
    height: 50px;
    padding: 0 15px;
  }
`
const LogoLink = styled(Link)`
  flex: 0 0 auto;
  width: 120px;
  height: 20px;
  margin-top: -3px;
  margin-right: 40px;
  background-image: url(${ Logo });
  background-size: cover;
  @media (max-width: 640px) {
    width: 108px;
    height: 18px;
    margin-right: 0;
  }
`
const Settings = styled.div`
  flex: 1 1 auto;
  align-self: stretch;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, min-content));
  align-items: center;
  justify-content: flex-end;
  grid-gap: 30px;
`