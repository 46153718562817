import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import severityColors from './severityColors'

const Legend = () => {
  const { t } = useTranslation()

  const renderLabel = ({ min = 0, max = '' }) => (
    `${!max ? '> ' : ''}${min}${max ? '-' : ''}${max}`
  )

  return (
    <Container>
      <Title>{ t('injuries-legend-title') }</Title>
      <ColorSampleContainer>
        { severityColors.map(({ min, max, color }) => (
          <ColorSampleItem key={ color }>
            <ColorSample color={ color } />
            { renderLabel({ min, max }) }
          </ColorSampleItem>
        )) }
      </ColorSampleContainer>
    </Container>
  )
}

export default Legend

const Title = styled.div`
  font-size: 12px;
  text-align: center;
`
const Container = styled.div`
  background-color: #fafafa;
  padding: 10px;
`
const ColorSampleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px 3px;
  margin-top: 7px;
 `
const ColorSampleItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  font-weight: 350;
  line-height: 1;
  white-space: nowrap;
`
const ColorSample = styled.div`
  width: 100%;
  height: 7px;
  margin-bottom: 3px;
  background-color: ${({ color }) => color};
`