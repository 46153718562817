import React, { Component } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  EmailShareButton
} from 'react-share'
import ReactSVG from 'react-svg'
import facebook from '../../../images/Facebook.svg'
import whatsapp from '../../../images/Whatsapp.svg'
import email from '../../../images/Mail.svg'
import twitter from '../../../images/Twitter.svg'
import linkedin from '../../../images/Linkedin.svg'
import telegram from '../../../images/Telegram.svg'
import download from '../../../images/Download.svg'
import shareIcon from '../../../images/share.svg'

import styled, { keyframes } from 'styled-components'
import { cssvar } from '../../../styles/var'

class SocialShare extends Component {

  state = {
    expanded: false
  }

  showButtons = () => {
    this.setState({ expanded: true })
  }
  hideButtons = () => {
    this.setState({ expanded: false })
  }

  render() {
    const { id, type, title: toEnhancerTitle, ig, fb, rss, tw = {}, previewImage } = this.props
    function getLink(id, type) {
      switch (type) {
        case 'tw': {
          return `https://twitter.com/${tw.creator}/status/${(id.split('_')[1])}`
        }
        case 'ig': {
          return ig.link
        }
        case 'fb': {
          return fb.link
        }
        default: {
          return rss.link
        }
      }
    }
    const shareUrl = getLink(id, type)
    const title = `noisefeed | ${toEnhancerTitle}`

    return (
      <Container>
        <Trigger onClick={ () => this.showButtons() }>Share</Trigger>
        { this.state.expanded && <Buttons>
          <FacebookShareButton
            url={ shareUrl }
            quote={ title }
            className="share-button_round">
            <ReactSVG src={ facebook } />
          </FacebookShareButton>

          <LinkedinShareButton
            title={ title }
            url={ shareUrl }
            className="share-button_round">
            <ReactSVG src={ linkedin } />
          </LinkedinShareButton>

          <TwitterShareButton
            url={ shareUrl }
            title={ title }
            via="https://twitter.com/NoisefeedReal"
            className="share-button_round">
            <ReactSVG src={ twitter } />
          </TwitterShareButton>

          <TelegramShareButton
            title={ title }
            url={ shareUrl }
            className="share-button_round">
            <ReactSVG src={ telegram } />
          </TelegramShareButton>

          <WhatsappShareButton
            url={ shareUrl }
            title={ title }
            className="share-button_round">
            <ReactSVG src={ whatsapp } />
          </WhatsappShareButton>

          <EmailShareButton
            title={ title }
            url={ shareUrl }
            className="share-button_round">
            <ReactSVG src={ email } />
          </EmailShareButton>
          { previewImage && (
            <a href={ previewImage } target="_blank" download rel="noopener noreferrer" className="share-button_round">
              <ReactSVG src={ download } />
            </a>
          ) }
        </Buttons> }
        { this.state.expanded && <CloseBg onClick={ () => this.hideButtons() } /> }
      </Container>
    )
  }
}

export default SocialShare

const Container = styled.div`
  position: relative;
`
const Trigger = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
  cursor: pointer;
  transition: .3s;
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    margin-right: 7px;
    background-image: url(${ shareIcon });
    background-size: cover;
  }
  &:hover {
    color: ${ cssvar('primaryHoverColor') };
  }
`
const buttonsFadeIn = keyframes`
  from {
    opacity: 0;
    transform: rotate(-120deg) scale(.5);
  }
  to {
    opacity: 1;
    transform: rotate(0) scale(1);
  }
`
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: -10px;
  right: 0;
  transform: translateY(-100%);
  z-index: 102;
  .share-button_round {
    animation: ${ buttonsFadeIn } .3s ease-in forwards;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: ${ cssvar('mainColor') };
    border-radius: 50%;
    line-height: 1;
    cursor: pointer;
    div, svg {
      height: 18px;
    }
    svg path, svg rect {
      fill: #fff;
      transition: .3s;
    }
    &:hover {
      svg path, svg rect {
        fill: ${ cssvar('oldPrimaryColor') };
      }
    }
  }
`
const CloseBg = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
`