import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../../api'
import { decodeHTML } from '../../../helpers'
import styled from 'styled-components'

const RSSFullText = ({ id }) => {
  const [text, setText] = useState('')
  useEffect(() => {
    async function fetchText() {
      const result = await axios(
        `${BASE_URL}/livestream/${id}`,
      )
      setText(result.data._source.message)
    }
    fetchText()
  }, [])
  return (
    <RSSText>
      { decodeHTML(text) }
    </RSSText>
  )
}

export default RSSFullText

const RSSText = styled.div`
  font-size: 15px;
  font-weight: 450;
  line-height: 1.5;
`