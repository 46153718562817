import axios from 'axios'
import { urlBuilder } from '../../helpers'
import history from '../../history'

import { get } from './constants'

const getNavDrilldownRequest = () => ({ type: get.request })
const getNavDrilldownFailure = error => ({
  type: get.failure,
  error
})
const getNavDrilldownSuccess = data => ({
  type: get.success,
  data
})

const getNavDrilldown = ({ url = '/nav-drilldown', tag }) => async (dispatch) => {
  dispatch(getNavDrilldownRequest())

  try {
    const { data } = await axios({
      method: 'get',
      url: urlBuilder({ url, tag })
    })

    dispatch(getNavDrilldownSuccess(data))
  } catch (error) {
    dispatch(getNavDrilldownFailure(error))

    if (error.status === 401) {
      history.push('/login')
    }
  }
}

export default getNavDrilldown
