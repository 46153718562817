import React, { Fragment } from 'react'
import _ from 'lodash'
import { useSearchContext } from './SearchContext'
import { useTranslation } from 'react-i18next'
import Formatter from '../../../utils/Formatter'
import styled, { css, keyframes } from 'styled-components/macro'
import { cssvar } from '../../../styles/var'
import WorldIcon from '../../../images/World.svg'

const SearchResults = () => {
  const { t } = useTranslation()
  const { searchResult, loading } = useSearchContext()

  const renderSkeleton = () => (
    <ResultGroup>
      <Results>
        { [...new Array(5)].map(() => (
          <Result loading>
            <ImageWrapper />
            <Name>
              Player name
              <Team loading>Team</Team>
            </Name>
          </Result>
        )) }
      </Results>
    </ResultGroup>
  )

  const getImageUrl = (image) => {
    // This is to override missing images from wyscout api
     switch (image) {
       case null:
       case undefined:
         return WorldIcon
       case 'https://rest.wyscout.com/v1/media/images/competition/nd.png':
         return WorldIcon
       default:
         return image
    }
  }

  const renderResult = (result) => {
    const { _id, _source } = result
    const { label, image, current_injury, meta } = _source
    return (
      <Result key={ _id } href={ `explore/${_id}` } injury={ !!current_injury }>
        <ImageWrapper>
          <img
            src={getImageUrl(image)}
            alt={label}
            onError={(e) => {
              e.target.src = WorldIcon
            }}
          />
        </ImageWrapper>
        <Name>
          { label }
          { meta.team_name && <Team>{ meta.team_name }</Team> }
        </Name>
      </Result>
    )
  }

  const renderGroup = (type) => {
    const results = _.filter(searchResult, (res) => res._type === type)
    if (results.length === 0) return null

    return (
      <ResultGroup>
        <ResultGroupTitle>{ t(type) }</ResultGroupTitle>
        <Results>
          { results.map(result => renderResult(result)) }
        </Results>
      </ResultGroup>
    )
  }

  if (loading) return renderSkeleton()
  if (!loading && searchResult.length === 0) return null
  
  const sortedExtraTypes = ['x_coach', 'x_executive', 'x_agent']
  const tempExtraTypes = searchResult
        .map((item) => item._type)
        .filter((value, index, self) => self.indexOf(value) === index)
    .filter((type) => type.includes('x_'))
  
  const extraTypes = [
    ...sortedExtraTypes.filter(t => tempExtraTypes.includes(t)),
    ...tempExtraTypes.filter(t => !sortedExtraTypes.includes(t)),
  ]

  return (
    <Fragment>
      {renderGroup('national_team')}
      {renderGroup('competition')}
      {renderGroup('team')}
      {renderGroup('player')}
      {extraTypes.map(type => renderGroup(type))}
    </Fragment>
  )
}

export default SearchResults

const ResultGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  margin-bottom: 45px;
  @media (max-width: 640px) {
    margin-bottom: 15px;
    align-items: flex-start;
  }
  &:last-child {
    margin-bottom: 0;
  }
`
const ResultGroupTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .5px;
  @media (max-width: 640px) {
    font-size: 18px;
    text-align: left;
    padding: 0 15px;
    text-transform: none;
    letter-spacing: initial;
  }
`
const Results = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 170px);
  justify-content: center;
  align-items: stretch;
  gap: 15px;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 10px 0;
  }
`
const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`
const Result = styled.a`
  animation: ${ fadeIn } .4s ease-in forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 15px 7px;
  background-color: ${ ({ injury }) => injury ? Formatter.hexToRgba(cssvar('injuryColor'), .1) : cssvar('background') };
  color: ${ cssvar('mainColor') };
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s;
  &:hover {
    background-color: ${ ({ injury }) => Formatter.hexToRgba(cssvar(injury ? 'injuryColor' : 'primaryColor'), .25) };
    color: ${ cssvar('mainColor') };
  }
  ${ ({ loading }) => loading && css`
    pointer-events: none;
    color: transparent;
  ` };
  @media (max-width: 640px) {
    flex-direction: row;
    padding: 7px 15px;
    background-color: ${ ({ injury }) => injury ? Formatter.hexToRgba(cssvar('injuryColor'), .1) : 'transparent' };
    border-bottom: 1px solid ${ cssvar('borderColor') };
    border-radius: 0;
    text-align: left;
    &:hover {
      background-color: ${ ({ injury }) => injury ? Formatter.hexToRgba(cssvar('injuryColor'), .2) : cssvar('hoverColor') };
    }
  }
`
const ImageWrapper = styled.div`
  flex: 0 0 auto;
  width: 90px;
  height: 90px;
  background-color: #fff;
  border: 2px solid rgba(255,255,255, .5);
  border-radius: 8px;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 640px) {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
`
const Name = styled.div`
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 600;
`
const Team = styled.div`
  font-size: 12px;
  font-weight: 480;
  color: ${ ({ loading }) => loading ? 'transparent' : cssvar('secondaryTextColor') };
`