import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import severityColors from '../Sections/Injury/severityColors'
import styled from 'styled-components'

const RecoveryCell = ({ days }) => {
  const { t } = useTranslation()

  if (days === 0 || days === '0') return t('injury-unknown')
  if (!days) return t('injury-nd')

  const barsNumber = () => {
    if (days <= 40) return 1
    if (days > 40 && days <= 80) return 2
    if (days > 80) return 3
  }

  const severityColor = () => {
    const severity = _.find(severityColors, severity => days >= (severity.min || 0) && days <= (severity.max || 10000))
    if (!severity) return '#444'
    return severity.color
  }

  return (
    <RecoveryWidget>
      <Bars>
        {  [...Array(barsNumber())].map( (n, index) => <Bar key={`recovery-cell-bar-${index}`} color={ severityColor() } /> ) }
      </Bars>
      { days }
    </RecoveryWidget>
  )
}

export default RecoveryCell

const RecoveryWidget = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
`
const Bars = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
`
const Bar = styled.div`
  flex: 0 0 auto;
  width: 5px;
  height: 12px;
  margin-top: 1px;
  margin-right: 2px;
  background-color: ${ ({ color }) => color };
  &:last-child {
    margin: 0;
  }
  @media (max-width: 1440px) {
    height: 11px;
    margin-top: 0;
  }
`