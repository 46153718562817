import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import Feed from '../NewsFeed/Feed';

const CrossFeed = ({
  visible,
  tag,
  hashtag,
  fulltext,
  isHashtag,
  name,
  hashtagLabel,
  hashtagTag,
  isFavourite,
  isCrossTag,
  isGeo,
  close,
}) => {
  const { t } = useTranslation();

  const getNameLabel = () => {
    if (isHashtag) return hashtag;
    if (fulltext) return fulltext;
    return name;
  };

  const getTag = () => {
    if (isHashtag) return hashtag;
    if (fulltext) return fulltext;
    return tag;
  };

  if (!visible) return null;

  return (
    <Drawer
      className="cross-feed-drawer"
      title={(
        <div className="title">
          {`${t(isGeo ? 'analytics_feed_label_from' : 'analytics_feed_label_h')} ${hashtagLabel} ${t('analytics_feed_label_on')} ${t(getNameLabel()) || fulltext}`}
        </div>
      )}
      onClose={() => close()}
      visible={visible}
      getContainer={document.getElementById('livestats-tab')}
      destroyOnClose
    >
      <Feed
        original={3}
        isFavourite={isFavourite}
        isCrossFeed
        isCrossFeedVisible={visible}
        isCrossTag={isCrossTag || isHashtag}
        isGeo={isGeo}
        fulltext={fulltext}
        hashtagLabel={hashtagLabel}
        hashtagTag={hashtagTag}
        name={hashtag}
        tag={getTag()}
      />
    </Drawer>
  );
};

export default CrossFeed;
