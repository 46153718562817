import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Video from './Video'
import { Container, Main, Disclaimer } from '../styledComponents'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'

const InjuryVideo = ({ data }) => {
  const { t } = useTranslation()

  if (!data.length) return null
  const dataMapped = data.map((row, index) => (
    {
      ...row,
      key: index,
      dateEnd: row.recovery_days > 0 && moment(row.date).add(parseInt(row.recovery_days, 10), 'days')
    }
  ))

  return (
    <Container>
      <MainStyled>
        <Video data={ dataMapped } />
        <DisclaimerStyled>{ t('injury-disclaimer') }</DisclaimerStyled>
      </MainStyled>
    </Container>
  )
}

InjuryVideo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    injury_type: PropTypes.string,
    injuried_part: PropTypes.string,
    description: PropTypes.string,
    side: PropTypes.string,
    date: PropTypes.string,
    recovery_days: PropTypes.string
  })),
  playerName: PropTypes.string.isRequired
}

InjuryVideo.defaultProps = {
  data: []
}

export default InjuryVideo

const MainStyled = styled(Main)`
  @media (max-width: 1024px) {
    padding: 0;
  }
`
const DisclaimerStyled = styled(Disclaimer)`
  @media (max-width: 1024px) {
    margin: 0;
    padding: 15px;
    border-top: 30px solid ${ cssvar('background') };
  }
  @media (max-width: 640px) {
    border-width: 15px;
  }
`