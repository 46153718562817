import React from 'react'
import Star from './Star'
import { DetailContainer, MainInfo, Image, NameAndDesc, Name, Description } from './Detail'
import HashtagIcon from '../../images/Hashtag.svg'

class HashtagDetail extends React.Component {
  render() {
    const { hashtag, data = {} } = this.props
    const { favourite, favourite_settings } = data
    return (
      <DetailContainer>
        <MainInfo>
          <Image src={ HashtagIcon } />
          <NameAndDesc>
            <Name>
              <span>{`#${hashtag}`}</span>
              <Star favourite={ favourite } tag={ hashtag } details={ favourite_settings } isHashtag={ true } />
            </Name>
            <Description>Hashtag</Description>
          </NameAndDesc>
        </MainInfo>
      </DetailContainer>
    )
  }
}
export default HashtagDetail
