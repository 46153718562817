import { createReducer, createAction } from 'redux-starter-kit'

export const setAuthToken = createAction('SAVE_AUTH_TOKEN')

export default createReducer({}, {
  [setAuthToken]: (state = {}, action = {}) => {
    const { payload = {} } = action
    const { data, id } = payload
    state[id] = data
  },
})
