import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Icon } from 'antd'
import FavouritesModal from './FavouritesModal'
import {
  postFavourites as postFavouritesAction,
  removeFavourites as removeFavouritesAction
} from '../../redux/favourites/actions'
import styled from 'styled-components'
import { cssvar } from '../../styles/var'

class Star extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentTag: undefined,
      favourited: props.favourite
    }
  }

  handleCancel() {
    this.setState({ currentTag: undefined })
  }
  openModal(tag) {
    this.setState({ currentTag: tag })
  }
  setFavourite = (fav) => {
    this.setState({ favourited: !!fav })
  }

  remove(tag) {
    this.props.removeFavourites(tag, { hashtag: this.props.isHashtag || false })
    this.setFavourite(false)
    this.handleCancel()
  }

  update(tag, opts = {}, type) {
    const { digest, push } = opts
    this.props.postFavourites({ type, tag, digest, push })
    this.setFavourite(true)
    this.handleCancel()
  }

  handleClick = () => {
    const { tag, isHashtag } = this.props
    const { favourited } = this.state

    isHashtag ? (favourited ?
      this.remove(tag, 'hashtag') : this.update(tag, {}, 'hashtag')
    ) : this.openModal(tag)
  }

  render() {
    const { tag, details, inline } = this.props
    const { favourited, currentTag } = this.state

    return (
      <Fragment>
        <StarWrapper inline={ inline }>
          { tag && <IconStyled type="star" theme={ favourited ? 'filled' : 'outlined' } inline={ inline } onClick={ this.handleClick } /> }
        </StarWrapper>
        <FavouritesModal
          details={ details }
          tag={ tag }
          currentTag={ currentTag }
          favourited={ favourited }
          update={ (tag, opts) => this.update(tag, opts, 'tag') }
          remove={ tag => this.remove(tag) }
          cancel={ () => this.handleCancel() } />
      </Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  const { favourites } = state
  return { favourites }
}
const dispatchToProps = dispatch => ({
  postFavourites: bindActionCreators(postFavouritesAction, dispatch),
  removeFavourites: bindActionCreators(removeFavouritesAction, dispatch)
})

export default connect(mapStateToProps, dispatchToProps)(Star)

const StarWrapper = styled.div`
  flex: 0 0 auto;
  align-self: flex-start;
  height: 16px;
  margin-top: ${ ({ inline }) => inline ? '0' : '3px' };
  line-height: 16px;
`
const IconStyled = styled(Icon)`
  vertical-align: middle;
  font-size: 16px;
  margin-top: -2px;
  color: ${ ({ theme, inline }) => theme === 'filled' ? '#ffbc00' : inline ? '#fff' : cssvar('secondaryTextColor') };
  cursor: pointer;
  transition: .3s;
  &:hover {
    color: #f5b70b;
  }
  @media (max-width: 1024px) {
    color: ${ ({ theme }) => theme === 'filled' ? '#ffbc00' : '#fff' };
  }
`