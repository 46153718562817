import React from 'react'
import ListElement from './ListElement'

class Suggestion extends React.Component {
  render() {
    const {
      input,
      selection,
      type,
      fulltext,
      hashtag,
      selectedId,
    } = this.props
    const id = hashtag ? `#${input}` : input

    return (
      <div className='suggestion-container' id={id}>
        <div className='suggestion-section'>
          {type}
        </div>
        <div
          onClick={input => selection(input)}
          id={id}
          className={(selectedId === id) ? 'suggestion-element-wrapper active' : 'suggestion-element-wrapper'}
        >
          <ListElement
            tag={input}
            image='world'
            label={input}
            isFullText={fulltext}
            isHashtag={hashtag}
            customId={id}
          />
      </div>
    </div>
    )
  }
}
export default Suggestion
