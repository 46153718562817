import React from 'react'
import { withTranslation } from 'react-i18next'
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Form, Input, Select, Icon, Tooltip, Checkbox } from 'antd'
import { handleRegistration } from '../../api/registration'
import history from '../../history'
import Analytics from '../../Analytics'
import LoginPage, { Message, FormStyled, FormTitle, FormBody, FormItem, FormLabel, Submit, BackToLogin } from './LoginPage'
import styled from 'styled-components'
import { cssvar } from '../../styles/var'

const { Option } = Select


class RegistrationForm extends React.Component {
  state = {
    showSuccess: false
  }

  componentDidMount() {
    Analytics.pageView('/register')
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const { form, googleReCaptchaProps } = this.props
    const _recaptcha = await googleReCaptchaProps.executeRecaptcha('login')

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { name, type, email, mobile } = values
        const params = {
          client_id: 123,
          client_secret: 456,
          name,
          email,
          mobile,
          type,
          _recaptcha
        }
        handleRegistration(params)
        this.setState({ showSuccess: true })
        setTimeout(() => (history.push('/login')), 3000)
      }
    })
  }

  render() {
    const { form, t } = this.props
    const { getFieldDecorator, getFieldsValue } = form
    const { name, email, type, agreement } = getFieldsValue()
    const { showSuccess } = this.state
    const notEmpty = !!name && !!email && !!type && !!agreement
    return (
      <LoginPage>

        <Message success show={ showSuccess }>{ t('request_sent') }</Message>

        <FormStyled onSubmit={ this.handleSubmit }>
          <FormTitle>{ t('r_request_demo') }</FormTitle>
          <FormBody>

            <FormItem>
              <FormLabel>
                <span>{ t('r_name_and_surname') }</span>
                <Tooltip title={ t('r_example_tip') }>
                  <Icon type="info-circle" />
                </Tooltip>
              </FormLabel>
              {getFieldDecorator('name', {
                rules: [{
                  required: true,
                  message: t('r_required_field'),
                  whitespace: true,
                }],
              })(<Input />)}
            </FormItem>

            <FormItem>
              <FormLabel>Email</FormLabel>
              {getFieldDecorator('email', {
                rules: [{
                  type: 'email',
                  message: t('r_insert_valid_mail'),
                }, {
                  required: true,
                  message: t('r_required_field'),
                }],
              })(<Input />)}
            </FormItem>

            <FormItem>
              <FormLabel>{ t('r_phone_number') }</FormLabel>
              {getFieldDecorator('mobile', {
                rules: [{
                  required: false
                }],
              })(<Input />)}
            </FormItem>

            <FormItem>
              <FormLabel>Account</FormLabel>
              {
              getFieldDecorator('type', {
                rules: [{
                  required: true,
                  message: t('r_select_required')
                }]
              })(
                <SelectStyled>
                  <Option value="forum-agent">{ t('r_agent') }</Option>
                  <Option value="forum-media">{ t('r_media_agency') }</Option>
                  <Option value="forum-marketing">{ t('r_marketing_company') }</Option>
                  <Option value="forum-club">{ t('r_form_club') }</Option>
                </SelectStyled>
              )
            }
            </FormItem>

            {
              getFieldDecorator('agreement', {
                valuePropName: 'checked'
              })(
                <CheckboxStyled>
                  { t('r_user_agreement_read') } <a href="http://noisefeed.it/privacy/" target="_blank" rel="noopener noreferrer">{ t('r_user_agreement_eula') }</a>
                </CheckboxStyled>
              )
            }
            <Submit type="submit" disabled={ !notEmpty }>{ t('send_request') }</Submit>
          </FormBody>
          <BackToLogin href="/login">{ t('back_to_login') }</BackToLogin>
        </FormStyled>
      </LoginPage>
    )
  }
}

const WrappedRegistrationForm = withTranslation('translation')(withGoogleReCaptcha(Form.create({ name: 'register' })(RegistrationForm)))

export default () => (
  <GoogleReCaptchaProvider reCaptchaKey="6Lcyd-QZAAAAAEfgLgAdhte5F0VvVhhQaLY1lDwl">
    <WrappedRegistrationForm />
  </GoogleReCaptchaProvider>
)

const SelectStyled = styled(Select)`
  height: 40px;
  border-bottom: none;
  transition: .3s;
  .ant-select-selection--single {
    height: 100%;
    background-color: rgba(255,255,255, .8);
    border: none;
  }
  .ant-select-selection__rendered {
    color: ${ cssvar('mainColor') };
    font-weight: 600;
    line-height: 40px;
    text-align: center;
  }
  .ant-select-arrow {
    color: #000;
  }
  &.ant-select-focused .ant-select-selection--single {
    background-color: #fff;
  }
`
const CheckboxStyled = styled(Checkbox)`
  margin-top: 20px;
  color: #fff;
  a {
    color: #3cc0f2;
    &:hover {
      text-decoration: underline;
    }
  }
`