import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'

const Article = ({ article }) => {
  const { url, title, excerpt, site_name } = article
  return (
    <ArticleItem href={ url } target="_blank" rel="noopener noreferrer">
      { title && <ArticleTitle>{ title }</ArticleTitle> }
      { site_name && <ArticleSource>{site_name}</ArticleSource> }
      { excerpt && <Excerpt>{ excerpt }</Excerpt> }
      { !title && !site_name && !excerpt && <Url>{ url }</Url> }
    </ArticleItem>
  )
}

const News = ({ injury }) => {
  const { t } = useTranslation()

  return (
    <>
      <NewsTitle>{ t('injury-news') }</NewsTitle>
      { injury.news.map( article => <Article key={ article.url } article={ article } /> ) }
    </>
  )
}

export default News

const NewsTitle = styled.div`
  font-size: 20px;
  font-weight: 550;
  margin-top: 30px;
  @media (max-width: 640px) {
    font-size: 20px;
  }
`
const ArticleItem = styled.a`
  display: block;
  padding: 30px 15px;
  border-bottom: 1px solid ${ cssvar('borderColor') };
  cursor: pointer;
  color: ${ cssvar('mainColor') };
  position: relative;
  transition: .3s;
  &:last-child {
    border-bottom: none;
  }
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    background-color: ${ cssvar('mainColor') };
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: width .3s;
  }
  &:hover {
    color: ${ cssvar('mainColor') };
    background-color: ${ cssvar('hoverColor') };
    &:before {
      width: .33em;
    }
  }
  @media (max-width: 640px) {
    padding: 15px 0;
    &:before {
      display: none;
    }
  }
`
const ArticleTitle = styled.div`
  font-size: 16px;
  font-weight: 550;
  @media (max-width: 640px) {
    font-size: 14px;
  }
`
const ArticleSource = styled.div`
  font-size: 12px;
  color: ${ cssvar('secondaryTextColor') };
`
const Excerpt = styled.p`
  margin: 0;
  margin-top: 1em;
  font-size: 13px;
  @media (max-width: 640px) {
    font-size: 12px;
  }
`
const Url = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`