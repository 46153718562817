import React from 'react'
import _ from 'lodash'

import * as linkify from 'linkifyjs'
import hashtag from 'linkifyjs/plugins/hashtag'
import mention from 'linkifyjs/plugins/mention'
import Linkify from 'linkifyjs/react'

hashtag(linkify)
mention(linkify)

class Linkifier extends React.Component {
  render() {
    const { children } = this.props
    const linkProps = ({
      formatHref: {
        hashtag: val => `https://twitter.com/${val.substr(1)}`,
        mention: val => `https://twitter.com${val}`
      }
    })

    return (
      <Linkify options={{ ...linkProps }}>
        <span dangerouslySetInnerHTML={{ __html: _.replace(children, '&nbsp;', ' ') }} />
      </Linkify>
    )
  }
}

export default Linkifier
