import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import * as LANGUAGES from './locales/index'

const options = {
  debug: false,
  lang: i18next.language,
  fallbackLng: 'en',
  resources: LANGUAGES,
}

i18next.use(LanguageDetector).use(initReactI18next).init(options)

export default i18next
