import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import RectSVG from 'react-svg'
import styled from 'styled-components/macro'
import { cssvar } from '../../styles/var'

import Home from '../../images/Home.svg'
import HomeSearch from '../../images/HomeSearch.svg'
import Favourite from '../../images/Favourite.svg'
import Book from '../../images/Book.svg'

const NavButton = ({ data, disabled }) => {
  const { link, icon } = data;

  const source = {
    Home,
    HomeSearch,
    Favourite,
    Book
  };

  return (
    <Button to={ link } disabled={ disabled } activeClassName="active-nav-link">
      <RectSVG src={source[icon]} alt={icon} />
    </Button>
  )
}

NavButton.propTypes = {
  data: PropTypes.shape().isRequired,
  disabled: PropTypes.bool
}

NavButton.defaultProps = {
  disabled: false
}

export default NavButton

const Button = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  transition: .3s;
  svg {
    width: 25px;
    path {
      fill: ${ cssvar('background') };
      transition: .3s;
    }
  }
  &.active-nav-link svg path {
    fill: ${ cssvar('oldPrimaryColor') };
  }
  &:hover {
    background-color: rgba(255,255,255, .1);
  }
  &:disabled {
    opacity: .25;
    pointer-events: none;
  }
`