import _ from 'lodash'

export default class Formatter {

  static formatExpectedDowntime({ expected_return_date, description, date }) {
    // let expectedReturn = moment(expected_return_date)
    // if (_.includes(description, 'Covid')) {
    //   expectedReturn = moment(moment(date).add(2, 'weeks').format('YYYY-MM-DD'))
    // }
  
    // if (expectedReturn.isAfter(moment().add(5, 'months'))) return '5-6 months'
    // if (expectedReturn.isAfter(moment().add(4, 'months'))) return '4-5 months'
    // if (expectedReturn.isAfter(moment().add(3, 'months'))) return '3-4 months'
    // if (expectedReturn.isAfter(moment().add(2, 'months'))) return '2-3 months'
    // if (expectedReturn.isAfter(moment().add(1, 'months'))) return '4-6 weeks'
    // if (expectedReturn.isAfter(moment().add(3, 'weeks'))) return '3-4 weeks'
    // if (expectedReturn.isAfter(moment().add(1, 'weeks'))) return '1-2 weeks'
    // if (
    //   expectedReturn.isAfter(moment().add(1, 'days'))
    //   &&
    //   expectedReturn.isBefore(moment().add(1, 'weeks'))
    // ) return '< 1 week'
  
    return 'injury-progress'
  }

  static formatPosition(value) {
    const unmapper = { RCB3: ['RCB'], LCB3: ['LCB'], RB5: ['RB'], LB5: ['LB'],
      RCMF3: ['RCMF'], LCMF3: ['LCMF'], SS: ['CF'] };
    return unmapper[value] || value;
  }

  static formatDate(value) {
    if (!value) return '-';
    const date = _.includes(value, '-') ? value.split('-') : value.split('/');
    if (_.includes(value, '-')) {
      return `${date[2]}.${date[1]}.${date[0]}`;
    }
    if (_.includes(value, '.')) return value;
    return `${date[0]}.${date[1]}.${date[2]}`;
  }

  static formatXg(value) {
    return value !== 0 ? _.padEnd(_.round(value, 2).toFixed(2), 4, '0') : 0;
  }

  static formatXgWithBelowCondition(value) {
    if (value === 0 || !value) return '-';
    return value < 0.01 ? '<0.01' : (value !== 0 ? _.padEnd(_.round(value, 2).toFixed(2), 4, '0') : 0);
  }

  static formatOneDigit(value) {
    return this.formatNDigit(value, 1);
  }

  static formatNDigit(value, n) {
    if (value === '-') return '-';
    return value !== 0 ? _.padEnd(_.round(value, n).toFixed(n), 2 + n, '0') : 0;
  }

  static formatPercent(value) {
    if (value === '-' || value === 0) return '-';
    return `${this.formatOneDigit(value)}%`;
  }

  // For the format of: period: 2, minute: 46
  static formatMinuteAndPeriod(minute, period, periodDuration) {
    const st_of_1_t = minute > periodDuration && minute < periodDuration + 15 && period === 1;
    const st_of_2_t = minute > periodDuration * 2 && minute < periodDuration * 2 + 15 && period === 2;
    const st_of_1_et = minute > periodDuration * 2 + 15 && period === 1;
    const st_of_2_et = minute > periodDuration * 2 + 30;
    return st_of_1_t ? (`${periodDuration}+${minute - periodDuration}`) :
      st_of_2_t ? (`${periodDuration * 2}+${minute - periodDuration * 2}`) :
        st_of_1_et ? (`${periodDuration * 2 + 15}+${minute - (periodDuration * 2 + 15)}`) :
          st_of_2_et ? (`${periodDuration * 2 + 30}+${minute - (periodDuration * 2 + 30)}`) : minute;
  }

  // For the format of: period: 2, minute: 1
  static formatMinuteRelativeToPeriod(minute, period, periodDuration) {
    const st_of_1_t = minute > periodDuration && minute < periodDuration + 15 && period === 1;
    const st_of_2_t = minute > periodDuration && minute < periodDuration + 15 && period === 2;
    const st_of_1_et = minute > 15 && period === 3;
    const st_of_2_et = minute > 15 && period === 4;
    return st_of_1_t ? (`${periodDuration}+${minute - periodDuration}`) :
      st_of_2_t ? (`${periodDuration * 2}+${minute - periodDuration}`) :
        st_of_1_et ? (`${periodDuration * 2 + 15}+${minute - (periodDuration)}`) :
          st_of_2_et ? (`${periodDuration * 2 + 30}+${minute - (periodDuration)}`) : minute + (periodDuration * period) - periodDuration;
  }

  static formatBigValue(value) {
    if (!value) return '0';
    if (value >= 1000000) return `${value / 1000000}M`;
    if (value >= 1000) return `${value / 1000}K`;
    return value;
  }

  static formatCurrency(value, symbol = '€') {
    return `${symbol} ${Formatter.formatBigValue(value)}`;
  }

  static formatMarketValue(value, currency = '€') {
    let formattedValue = Formatter.formatCurrency(value, currency);
    if (formattedValue === '€ 0') return '-';
    return formattedValue;
  }

  static lastNameOnly(value) {
    return _.includes(value, '. ') ? _.split(value, '. ', 2)[1] : value;
  }

  static formatBool(value) {
    if (_.isUndefined(value)) return '-';
    return value ? 'yes' : 'no';
  }

  static formatColor(color) {
    return `rgba(${color.r},${color.g},${color.b},${color.a})`;
  }
  static formatOpacityColor(color, opacity) {
    if (!color) return 'rgba(0, 0, 0, 0)';
    const colors = color.split(/[(,)]/);
    const r = colors[1];
    const g = colors[2];
    const b = colors[3];
    const a = colors[4];
    const newOpacity = opacity * a;
    return `rgba(${r},${g},${b},${newOpacity})`;
  }
  static removeOpacityColor(color) {
    if (!color) return 'rgba(0, 0, 0, 0)';
    const colors = color.split(/[(,)]/);
    const r = colors[1];
    const g = colors[2];
    const b = colors[3];
    return `rgba(${r},${g},${b},1)`;
  }

  static componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  static rgbToHex = (color) => {
    const { r, g, b } = color;
    return '#' + Formatter.componentToHex(r) + Formatter.componentToHex(g) + Formatter.componentToHex(b);
  };

  static hexToRgba = (hexValue, opacity, raw) => {
    if (!hexValue) {
      return 'rgba(255, 255, 255, 1)';
    }
    const hex = hexValue.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    if (raw) return [r, g, b];
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
