import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobeAmericas, faStar, faRss,faLink,faEye, faCheck, faPencil } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Home from './components/Home'
import RegistrationForm from './components/Login/RegistrationForm'
import PasswordRecovery from './components/Login/PasswordRecovery'
import PasswordReset from './components/Login/PasswordReset'
import LoginForm from './components/Login/LoginForm'
import ServerError from './components/ServerError'

import './styles/App.css'

library.add(faGlobeAmericas, faStar, faRss, faFacebookF, faXTwitter, faLink, faEye, faCheck, faInstagram,faPencil);

const App = () => (
  <Switch>
    <Redirect from="/injuries" to="/search" />
    <Route path="/register" component={ RegistrationForm } />
    <Route path="/recovery-password" component={ PasswordRecovery } />
    <Route path="/ticket/:ticket" component={ PasswordReset } />
    <Route path="/login" component={ LoginForm } />
    <Route path="/" component={ Home } />
    <Route component={ ServerError } />
  </Switch>
)

export default App
