import React from 'react'
import LogoImg from '../../images/logo.svg'
import Bg from '../../images/login-bg.jpg'
import { Form } from 'antd'
import LanguageSelector from './LanguageSelector'
import styled from 'styled-components/macro'
import { cssvar } from '../../styles/var'

class LoginPage extends React.Component {

  render() {
    return (
      <PageWrapper>
        <Logo src={ LogoImg } alt="noisefeed" />
        <Slogan>Know football, <strong>First</strong></Slogan>
        <LanguageSelector />
        { this.props.children }
      </PageWrapper>
    )
  }
}

export default LoginPage

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  background-image: url(${ Bg });
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
const Logo = styled.img`
  width: 350px;
  margin-top: 90px;
  @media (max-width: 540px) {
    width: 250px;
    margin-top: 40px;
  }
`
const Slogan = styled.h2`
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 23px;
  font-weight: normal;
  color: #fff;
  text-align: center;
  @media (max-width: 540px) {
    margin-top: 10px;
    font-size: 18px;
  }
`

// Form styles
export const FormStyled = styled(Form)`
  &&& {
    all: unset; // reset all antd styles
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 470px;
    margin: 20px 0 40px;
    padding: 30px 60px;
    background-color: rgba(0,0,0, .15);
    border-radius: 8px;
    backdrop-filter: blur(7px);
    box-sizing: border-box;
    @media (max-width: 540px) {
      padding: 20px 40px;
      margin: 0;
      background-color: transparent;
      backdrop-filter: none;
    }
  }
`
export const FormTitle = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-align: center;
`
export const FormBody = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 30px;
`
export const FormItem = styled(Form.Item)`
  // reset all antd styles
  &&& {
    all: unset;
    &:before, &:after {
      display: none;
    }
    .ant-form-item-control-wrapper,
    .ant-form-item-control {
      all: unset;
    }
  }

  // set new styles
  &&& {
    width: 100%;
    margin-top: 20px;
    input {
      width: 100%;
      height: 40px;
      padding: 0 11px;
      font-size: 14px;
      background-color: #fafafa;
      color: ${ cssvar('mainColor') };
      font-weight: 600;
      text-align: center;
      border: none;
      transition: .3s;
      opacity: .8;
      &:-webkit-autofill {
        -webkit-text-fill-color: ${ cssvar('mainColor') } !important;
      }
      &:-webkit-autofill::first-line {
        font-weight: 600;
      }
      &:focus {
        opacity: 1;
      }
    }
    .ant-form-explain {
      min-height: auto;
      margin-top: 0;
      font-size: 12px;
      padding: 3px 0;
      color: #fff;
      background-color: rgba(165, 41, 41, .5);
      border-radius: 4px;
      text-align: center;
    }
    &:first-child {
      margin-top: 0;
    }
  }
`
export const FormLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  span {
    margin-right: 7px;
  }
`
export const Submit = styled.button`
  align-self: center;
  margin-top: 30px;
  padding: 8px 60px;
  border: 2px solid #3cc0f2;
  background: transparent;
  color: #3cc0f2;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background-color: rgba(60, 192, 242, .1);
    border-color: rgba(60, 192, 242, .6);
  }
  &:disabled {
    opacity: .5;
    pointer-events: none;
  }
`
export const BackToLogin = styled.a`
  margin-top: 30px;
  color: #3cc0f2;
  font-size: 12px;
  &:hover {
    color: #3cc0f2;
    text-decoration: underline;
  }
`
export const Message = styled.div`
  width: 100%;
  padding: 15px;
  color: #fff;
  text-align: center;
  background-color: ${ props => props.success ? 'rgba(15, 148, 111, .5)' : 'rgba(165, 41, 41, .5)' };
  position: absolute;
  top: 0;
  transform: translateY(${ props => props.show ? '0' : '-100%' });
  transition: .5s transform;
`