import React, { useState, createRef, useCallback } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useLastSearches from './useLastSearches'
import { useSearchContext } from './SearchContext'
import ClockIcon from '../../../images/clock.svg'
import styled, { keyframes } from 'styled-components/macro'
import { cssvar } from '../../../styles/var'

const MainSearch = () => {
  const searchFieldRef = createRef()
  const { t } = useTranslation()
  const [lastSearches, setLastSearches] = useLastSearches()
  const [inputValue, setInputValue] = useState('')
  const {
    searchState: [, setSearch],
    setSearchResult,
  } = useSearchContext()

  const handleSearchSubmit = (event, value) => {
    event.preventDefault()
    setSearch(value || inputValue)
    setLastSearches(value || inputValue)

    if (inputValue !== value && value) {
      setInputValue(value)
    }
    searchFieldRef.current.blur()
  }

  const autocompleteOptions = () => {
    let options = _.filter(lastSearches, search => _.includes(search, inputValue))
    if (inputValue === '') options = lastSearches
    return _.take(options, 5)
  }

  const searchDebounce = useCallback(
    _.debounce((value) => {
      if (value.length > 2) {
        setSearch(value)
      } else {
        setSearch(null)
        setSearchResult([])
      }
    }, 500),
    [],
  )

  const handleInputChange = (value) => {
    setInputValue(value)
    searchDebounce(value)
  }

  return (
    <SearchForm onSubmit={ handleSearchSubmit }>
      <InputField>
        <Input
          ref={ searchFieldRef }
          type="search"
          value={ inputValue }
          onChange={e => { handleInputChange(e.target.value) }}
          placeholder={ t('injury-search-placeholder') } />
        { autocompleteOptions().length > 0 && inputValue.length < 3 &&
          <Autocomplete>
            { autocompleteOptions().map(search => (
              <AutocompeleteItem key={ search } onClick={ e => { handleSearchSubmit(e, search) } }>
                { search }
              </AutocompeleteItem>
            )) }
          </Autocomplete>
        }
      </InputField>
      <Submit type="submit">
        { t('injury-search-button') }
      </Submit>
    </SearchForm>
  )
}

export default MainSearch

const SearchForm = styled.form`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  max-width: 800px;
  margin: 30px 0;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 8px;
  user-select: none;
  @media (max-width: 640px) {
    margin: 40px 0 20px;
  }
`
const Input = styled.input`
  width: 100%;
  padding: 15px;
  font-size: 14px;
  line-height: 1;
  font-weight: 450;
  background-color: ${ cssvar('background') };
  border-radius: 4px;
  border: none;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: .3s;
  &:hover {
    background-color: #efefef;
  }
  &:focus {
    background-color: #e6e6e6;
  }
  @media (max-width: 640px) {
    padding: 12px;
  }
`
const hideAutocomplete = keyframes`
  from { transform: translateY(100%) }
  to { transform: translateY(100%) scale(0) }
`
const Autocomplete = styled.div`
  animation: ${ hideAutocomplete } 1s forwards;
  animation-delay: .5s;
  opacity: 0;
  width: 100%;
  padding: 7px 0;
  background-color: #fff;
  box-shadow: 0 0 5px 2px #e6e6e6;
  border-radius: 4px;
  position: absolute;
  left: 0;
  bottom: -10px;
  transform: translateY(100%);
  z-index: 3;
  transition: opacity .3s;
`
const AutocompeleteItem = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 15px;
  cursor: pointer;
  transition: .3s;
  &:before {
    content: '';
    flex: 0 0 auto;
    width: 13px;
    height: 13px;
    margin-right: 15px;
    margin-top: 2px;
    background-image: url(${ ClockIcon });
    background-size: cover;
  }
  &:hover {
    background-color: ${ cssvar('hoverColor') };
  }
`
const InputField = styled.div`
  flex: 1 1 auto;
  position: relative;
  ${ Input }:focus + ${ Autocomplete } {
    animation: none;
    opacity: 1;
  }
`
const Submit = styled.button`
  flex: 0 0 auto;
  padding: 0 30px;
  background-color: ${ cssvar('primaryColor') };
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background-color: ${ cssvar('primaryHoverColor') };
  }
  @media (max-width: 640px) {
    font-size: 14px;
  }
`