import React from 'react'
import ReactSVG from 'react-svg'
import i18next from '../../i18n/index'
import NewsFeed from './NewsFeed'
import Book from '../../images/Book.svg'
import T from '../Generic/T'
import styled from 'styled-components/macro'
import { cssvar } from '../../styles/var'


class Bookmarked extends React.Component {
  state = {
    currentLanguage: i18next.language,
  }

  render() {
    i18next.on('languageChanged', (lang) => {
      this.setState({ currentLanguage: lang })
    })
    const { currentLanguage } = this.state
    return (
      <Wrapper>
        <Header>
          <HeaderIcon src={ Book } />
          <T i18nKey='readlist' />
        </Header>
        <Body>
          <NewsFeed isReadListPage language={currentLanguage} />
        </Body>
      </Wrapper>
    )
  }
}


export default Bookmarked

const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 26px;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 15px;
    background-color: ${ cssvar('mainColor') };
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 650;
  }
`
const HeaderIcon = styled(ReactSVG)`
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  path {
    fill: ${ cssvar('mainColor') };
  }
  @media (max-width: 1024px) {
    width: 45px;
    height: 45px;
    margin: 0;
    margin-bottom: 5px;
    path {
      fill: white;
    }
  }
`
const Body = styled.div`
  flex: 1 1 auto;
  background: #f7f7f7;
  overflow: hidden;
`