import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { BASE_V2_URL } from '../../../api'
import { Container } from '../styledComponents'
import _ from 'lodash'
import MobileTimeframeSelector from '../../Generic/MobileTimeframeSelector'
import AvailabilityChart from '../../Widgets/Availability'
import BarChart from '../../Widgets/BarChart'
import PieChart from '../../Widgets/PieChart'
import OKIcon from '../../../images/ok.png'
import BenchIcon from '../../../images/bench_blue.svg'
import styled, { css } from 'styled-components'
import { cssvar } from '../../../styles/var'
import moment from 'moment'
import { ActiveTimeFilterOption } from '../../../redux/ducks/filters'

const Availability = ({ tag, filters }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [availability, setAvailability] = useState({ matches: [], injuries: [], availability: {} })

  const fetchData = useCallback(
    _.debounce((filters) => {
      async function fetch(filters) {
        setLoading(true)
        try {
          const timeframe = filters.activeTimeFilter === ActiveTimeFilterOption.timeframe
            ? filters.timeframe
            : `${moment(filters.from).format('YYYY-MM-DD')},${moment(filters.to).format('YYYY-MM-DD')}`

          const result = await axios(`${BASE_V2_URL}/players/${tag}/graph?timeframe=${timeframe}&include_other_events=true`)
          setAvailability(result.data)
          setLoading(false)
        } catch (err) {
          console.log('Unable to load data for', tag)
        }
      }
      fetch(filters)
    }, 300),
    [],
  )

  useEffect(() => {
    fetchData(filters)
  }, [filters])

  const involvement = availability.availability
  const matches = availability.matches.slice() // Clone as this will be reversed later on (by the graph widget)

  // if (_.isEqual(_.uniq(_.map(matches, 'skippedReason')), ['unknown'])) return null

  const injuriesTypology = _.chain(availability.injuries)
    .groupBy('injury_type')
    .map(inj => ({ name: t(`injury-type-${inj[0].injury_type}`), value: inj.length }))
    .sortBy('value')
    .reverse()
    .value()

  const whereTypology = _.chain(availability.injuries)
    .reject({ where: 'none' })
    .groupBy('where')
    .map(inj => ({ name: t(`injury-match-where-${inj[0].where}`), value: inj.length }))
    .value()

  const traumaTypology = _.chain(availability.injuries)
    .reject({ trauma: 'na' })
    .reject({ trauma: null })
    .groupBy('trauma')
    .map(inj => ({ name: t(`injury-contact-${inj[0].trauma}`), value: inj.length }))
    .value()

  const severityTypology = _.chain(availability.injuries)
    .reject({ recovery_days: null })
    .groupBy((inj) => {
      if (inj.recovery_days < 30) return '< 30 days'
      if (inj.recovery_days >= 30 && inj.recovery_days <= 60) return '30-60 days'
      if (inj.recovery_days > 60) return '> 60 days'
    })
    .sortBy(inj => inj[0].recovery_days)
    .map(inj => ({
      name: t(
        `${inj[0].recovery_days >= 30 && inj[0].recovery_days <= 60
          ? '30-60'
          : inj[0].recovery_days < 30
            ? '< 30'
            : '> 60'
        } days`,
      ),
      value: inj.length,
    }))
    .value()

  const InjuriesTypesWidget = injuriesTypology.length > 3 ? BarChart : PieChart

  return (
    <Container>
      <Widgets>
        <MobileTimeframeSelector />

        {matches && matches.length > 0 && (
          <>
            <Widget fullWidth>
              <GeneralStats>
                <div>
                  <WidgetTitle>{t('Minutes played')}</WidgetTitle>
                  <GeneralStat>
                    {involvement.minutesPercentage}
                    %
                  </GeneralStat>
                  <GeneralSubStat>
                    {involvement.minutesPlayed}
                    '/
                    {involvement.possibleMinutes}
                    '
                  </GeneralSubStat>
                </div>
                <div>
                  <WidgetTitle>{t('Matches played')}</WidgetTitle>
                  <GeneralStat>
                    {involvement.matchesPercentage}
                    %
                  </GeneralStat>
                  <GeneralSubStat>
                    {involvement.matchesPlayed}
                    /
                    {involvement.possibleMatches}
                  </GeneralSubStat>
                </div>
              </GeneralStats>
            </Widget>
            <Widget fullWidth style={{ paddingBottom: 0 }}>
              <WidgetTitle>{t('Availability')}</WidgetTitle>
              <AvailabilityChart matches={matches} />
            </Widget>
          </>
        )}

        {!loading && (!matches || matches.length === 0) && (
          <Widget fullWidth>
            <EmptyMessage>
              <img src={BenchIcon} alt='' />
              {t('no-availabilities-in-period')}
            </EmptyMessage>
          </Widget>
        )}

        {!loading && (!availability.injuries || !availability.injuries.length) && (
          <Widget fullWidth>
            <EmptyMessage>
              <img src={OKIcon} alt='' />
              {t('no-injuries-in-period')}
            </EmptyMessage>
          </Widget>
        )}

        {availability.injuries && availability.injuries.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Injury types')}</WidgetTitle>
            <InjuriesTypesWidget data={injuriesTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {traumaTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('injury-contact')}</WidgetTitle>
            <PieChart data={traumaTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {whereTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Match/Training')}</WidgetTitle>
            <PieChart data={whereTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {severityTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Severity')}</WidgetTitle>
            <PieChart data={severityTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}
      </Widgets>
    </Container>
  )
}

function mapStateToProps({ filters }) {
  return { filters }
}

export default connect(mapStateToProps)(Availability)

const Widgets = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`
const Widget = styled.div`
  ${props => props.fullWidth
    && css`
      @media (min-width: 1025px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `};
  min-width: 0;
  padding: 15px;
  background-color: #fff;
`
const WidgetTitle = styled.h4`
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-top: -2px;
    padding: 3px 8px;
    border: 2px solid ${cssvar('injuryColor')};
    border-radius: 100px;
    color: ${cssvar('injuryColor')};
    font-size: 13px;
    font-weight: 650;
    line-height: 1;
  }
  @media (max-width: 640px) {
    font-size: 18px;
    span {
      margin-left: 5px;
      font-size: 11px;
    }
  }
`
const GeneralStats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 15px 0;
  text-align: center;
  ${WidgetTitle} {
    margin-bottom: 5px;
  }
`
const GeneralStat = styled.div`
  font-size: 28px;
  font-weight: 550;
  line-height: 1;
  @media (max-width: 640px) {
    font-size: 24px;
  }
`
const GeneralSubStat = styled.div`
  margin-top: 5px;
  color: ${cssvar('secondaryTextColor')};
`
const EmptyMessage = styled.div`
  font-size: 18px;
  font-weight: 550;
  text-align: center;
  opacity: 0.75;
  img {
    display: block;
    height: 55px;
    margin: 0 auto;
    margin-bottom: 5px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    img {
      height: 45px;
    }
  }
`
