import { get } from './constants';

const initialState = {
  loading: false,
  error: false,
  data: {},
};

const statistics = (
  state = initialState,
  { type, data, error },
) => {
  switch (type) {
    case get.request:
      return {
        loading: true,
        error: false,
        data: state.data,
      };

    case get.success:
      return {
        loading: false,
        error: false,
        data,
      };

    case get.failure:
      return {
        loading: false,
        error,
        data: state.data,
      };

    default:
      return state;
  }
};

export default statistics;
