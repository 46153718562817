import _difference from 'lodash/difference'
import _includes from 'lodash/includes'
import moment from 'moment'
import { createReducer, createAction } from 'redux-starter-kit'

// constants
const DEFAULT_DATE = {
  from: moment().subtract(6, 'months').format(),
  to: moment().format(),
}

const ActiveTimeFilterOption = {
  range: 'range',
  timeframe: 'timeframe',
}

const INITIAL_STATE = {
  name: 'World',
  to: DEFAULT_DATE.to,
  from: DEFAULT_DATE.from,
  category: [],
  type: [],
  lang: [],
  applyUpdate: false,
  fromSocials: [],
  aboutSocials: [],
  analyticsSocials: [],
  timeframe: 'year',
  playersFilter: 'all',
  activeTimeFilter: ActiveTimeFilterOption.timeframe,
}

// action creators
const setFilter = createAction('FILTER_SET')
const toggleFilter = createAction('FILTER_TOGGLE')
const resetAllFilters = createAction('FILTER_RESET_ALL')
const applyFiltersUpdate = createAction('FILTER_UPDATE_APPLY')
const setTimeframe = createAction('SET_TIMEFRAME')
const setPlayersFilter = createAction('SET_PLAYERS_FILTER')
const setActiveTimeFilter = createAction('SET_ACTIVE_FILTER')

// reducer
const reducer = createReducer(INITIAL_STATE, {
  [setFilter]: (state, { payload: { id, data } }) => {
    state[id] = data
  },

  [toggleFilter]: (state, action) => {
    const { id, data } = action.payload
    state[id] = _includes(state[id], ...data.split(','))
      ? _difference(state[id], [...data.split(',')])
      : [...state[id], ...data.split(',')]
  },

  [resetAllFilters]: (state) => {
    state.to = DEFAULT_DATE.to
    state.from = DEFAULT_DATE.from
    state.category = []
    state.type = []
    state.lang = []
  },

  [applyFiltersUpdate]: (state, action) => {
    state.applyUpdate = action.payload
  },
  [setTimeframe]: (state, action) => {
    state.timeframe = action.payload
    if (action.payload) {
      state.activeTimeFilter = ActiveTimeFilterOption.timeframe
    }
  },
  [setPlayersFilter]: (state, action) => {
    state.playersFilter = action.payload
  },
  [setActiveTimeFilter]: (state, action) => {
    state.activeTimeFilter = action.payload

    if (action.payload === ActiveTimeFilterOption.range) {
      setTimeframe(undefined)
    }
  },
})

export {
  setFilter,
  toggleFilter,
  resetAllFilters,
  applyFiltersUpdate,
  setTimeframe,
  setPlayersFilter,
  setActiveTimeFilter,
  DEFAULT_DATE,
  ActiveTimeFilterOption,
}
export default reducer
