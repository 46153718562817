import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import _times from 'lodash/times';

const StreamSkeleton = ({ drilldown, drilldownHeader }) => {
  const height = window.innerHeight;
  const count = drilldownHeader ? 1 : (Math.floor(height / 110) - 2);
  const containerStyle = drilldown || drilldownHeader
    ? {
      marginBottom: 0,
      height: 'auto',
    }
    : {};

  return (
    <div className="stream-skeleton">
      {_times(count, i => (
        <div
          key={i}
          className="stream-row"
          style={containerStyle}
        >
          <div className="stream">
            <Skeleton
              active
              avatar
              title
              paragraph={{ rows: drilldown ? 0 : 2 }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

StreamSkeleton.propTypes = {
  drilldown: PropTypes.bool,
  drilldownHeader: PropTypes.bool,
};

StreamSkeleton.defaultProps = {
  drilldown: false,
  drilldownHeader: false,
};

export default StreamSkeleton;
