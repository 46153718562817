import React from 'react';
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { groupPartialsBySocial } from '../../../helpers';
import DataLoading from './DataLoading';

const StackedPillarChart = ({
  data,
  type,
  name,
  trigger,
  hashtag,
  fulltext,
  t,
  isFavourite,
  loading,
}) => {
  const custom = fulltext || hashtag;

  const socials = [
    'ig',
    'tw',
    'rss',
  ];


  const handleClick = (ev, it) => {
    if (it && it.length > 0) {
      const { _model = {} } = it[0];
      const { label } = _model;
      trigger(label, result[label]);
    }
  };

  const dati = data || [];
  const labels = dati.map(row => row.key);
  const values = dati.map(row => row.partials);

  const sortedPartials = groupPartialsBySocial(values, socials);

  const {
    ig = [],
    tw = [],
    rss = [],
  } = sortedPartials;

  const result = dati.reduce(
    (res, curr) => ({
      ...res,
      [curr.key]: curr.tag,
    }),
    {},
  );

  const set = {
    labels,
    datasets: [{
      label: 'Instagram',
      data: ig || [],
      backgroundColor: '#ebe0ff',
      borderColor: '#9965ff',
      borderWidth: 1,
      hoverBackgroundColor: '#9965ff',
      hoverBorderWidth: 1,
      hoverBorderColor: '#7b52cd',
      stack: 1,
    },
    {
      label: 'X',
      data: tw || [],
      borderWidth: 1,
      borderColor: '#36a2eb',
      backgroundColor: '#d6ecfb',
      hoverBorderColor: '#2b87c5',
      hoverBackgroundColor: '#36a2eb',
      hoverBorderWidth: 1,
      stack: 1,
    },
    {
      label: t('web_article'),
      data: rss || [],
      borderWidth: 1,
      backgroundColor: '#feecd9',
      borderColor: '#fdb976',
      hoverBorderWidth: 1,
      hoverBackgroundColor: '#fc6500c2',
      hoverBorderColor: '#fc6500',
      stack: 2,
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: true }],
    },
    onClick: (ev, el) => (
      !custom
          && type === 'mentions'
            && handleClick(ev, el)
    ),
  };

  const suffix = hashtag
    ? ` #${hashtag}`
    : fulltext
      ? ` ${fulltext}`
      : name
        ? ` ${name}`
        : ` ${t('World')}`;

  const renderChart = () => {
    if (loading) {
      return <DataLoading />;
    }

    if (data && data.length > 0) {
      return <Bar data={set} options={options} />;
    }

    return (
      <div className="no-stats">
        {t('stats_not_available')}
      </div>
    );
  };

  return (
    <div className="pillar-chart">
      {
          type === 'mentions'
            ? (
              <h1 className="pillar-title">
                {
                isFavourite
                  ? `${t('favourite-analytics-most-prolific-leaf')}`
                  : `${t('analytics-most-prolific-leaf')} ${suffix}`
               }
              </h1>
            )
            : (
              <h1 className="pillar-title">
                {
                isFavourite
                  ? (type === 'active')
                    ? t('favourite-analytics-most-prolific')
                    : t('favourite-analytics-most-followed-account')
                  : (type === 'active')
                    ? `${t('analytics-most-prolific')} ${suffix}`
                    : `${t('analytics-most-followed-account')} ${suffix}`
              }
              </h1>
            )
        }
      {renderChart()}
    </div>
  );
};
export default withTranslation('translation')(StackedPillarChart);
