import React from 'react'

const style = {
  position: 'absolute',
  left: '20%',
  top: '40%'
}

const ServerError = () => (
  <div style={ style }>
    <h1>SERVER ERROR</h1>
  </div>
)

export default ServerError
