import { get, remove, post } from './constants';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const favourites = (state = initialState, { type, data, error }) => {
  switch (type) {
    case get.request:
      return {
        loading: true,
        error: false,
        data: state.data,
      };

    case get.success:
      return {
        loading: false,
        error: false,
        data,
      };

    case post.success:
      return {
        loading: false,
        error: false,
        data: state.data.map(({ tag, ...other }) => {
          if (tag === data.tag) {
            return ({ ...other, tag, favourite_settings: data.favourite_settings });
          }

          return ({ ...other, tag });
        }),
      };

    case remove.success:
      return {
        loading: false,
        error: false,
        data: state.data.filter(({ tag }) => tag !== data),
      };

    case get.failure:
      return {
        loading: false,
        error,
        data: null,
      };

    default:
      return state;
  }
};

export default favourites;
