import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { cssvar } from '../../../styles/var'
import Formatter from '../../../utils/Formatter'

const colors = [cssvar('primaryColor'), cssvar('injuryColor')]

const Line = ({
  maxValue, dataKey, dataKey2, bar,
}) => {
  const fac = Math.pow(10, (`${maxValue}`).length - 1)
  const lineWidth = Math.ceil(maxValue / fac) * fac
  return (
    <BarLineWrapper>
      <BarLine width={`${bar[dataKey] / lineWidth * 100}%`} color={colors[0]} />
      {dataKey2 && bar[dataKey2] > 0 && <BarLine width={`${bar[dataKey2] / lineWidth * 100}%`} color={colors[1]} />}
    </BarLineWrapper>
  )
}

const BarChart = ({
  data, dataKey, dataKey2, nameKey, linkKey, percents, absoluteValueKey,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const formatLegends = (legend) => {
    if (legend === 'Injuries') return t('Total injuries')
    if (legend === 'SignificantInjuries') return `${t('Significant injuries')} (${t('> 60 days')})`
    return t(_.upperFirst(_.lowerCase(legend)))
  }
  const goToExplore = (tag) => {
    history.push(`/explore/${tag}`)
  }
  return (
    <ChartWrapper absoluteValueKey={!!absoluteValueKey}>
      {data.map(bar => (
        <Bar key={bar[nameKey]}>
          <BarName
            onClick={() => (linkKey ? goToExplore(bar[linkKey]) : undefined)}
            asLink={!!linkKey}>
            {bar[nameKey]}
          </BarName>
          <BarValue>
            {!absoluteValueKey ? (
              <>
                {bar[dataKey]}
                {percents && '%'}
                <span>{dataKey2 && ` / ${bar[dataKey2]}${percents ? '%' : ''}`}</span>
              </>
            )
              : bar[absoluteValueKey]
            }
          </BarValue>
          <Line
            bar={bar}
            dataKey={dataKey}
            dataKey2={dataKey2}
            maxValue={percents ? 100 : _.maxBy(data, dataKey)[dataKey]}
          />
          {!!absoluteValueKey && (
            <BarPercentageAppendix>
              {`${bar[dataKey]}${percents ? '%' : ''}`}
            </BarPercentageAppendix>
          )}
        </Bar>
      ))}
      {dataKey2 && (
        <Legend>
          <LegendItem color={colors[1]}>{formatLegends(dataKey2)}</LegendItem>
          <LegendItem color={colors[0]}>{formatLegends(dataKey)}</LegendItem>
        </Legend>
      )}
    </ChartWrapper>
  )
}

export default BarChart

const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(130px, max-content) min-content minmax(80px, 1fr) ${props => (props.absoluteValueKey ? 'min-content' : '')} ;
  grid-gap: 15px 10px;
  align-items: center;
`
const Bar = styled.div`
  display: contents;
`
const BarName = styled.div`
  font-weight: 500;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.asLink && css`
    cursor: pointer;
    transition: .3s;
    &:hover {
      color: ${cssvar('primaryColor')};
      text-decoration: underline;
    }
  `}
`
const BarLineWrapper = styled.div`
  height: 10px;
  margin-top: 3px;
  background-color: ${Formatter.hexToRgba(colors[0], 0.12)};
  border-radius: 20px;
  position: relative;
  overflow: hidden;
`
const BarLine = styled.div`
  width: ${({ width }) => width};
  min-width: 10px;
  height: 100%;
  background-color: ${({ color }) => color};
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
`
const BarValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${colors[0]};
  text-align: right;
  line-height: 1;
  white-space: nowrap;
  span {
    color: ${colors[1]};
  }
`
const BarPercentageAppendix = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${colors[0]};
  text-align: right;
  line-height: 1;
  white-space: nowrap;
  span {
    color: ${colors[1]};
  }
`
const Legend = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const LegendItem = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 520;
  color: ${({ color }) => color};
  &:last-child {
    margin: 0;
  }
  &:before {
    content: '';
    flex: 0 0 auto;
    width: 11px;
    height: 11px;
    margin-right: 5px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
  }
`