import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import styled from 'styled-components'
import { availableLanguages, flags, languages } from '../Header/ChangeLanguage'

const LanguageSelector = () => {
  const { i18n } = useTranslation()

  const handleClick = (lang) => {
    i18n.changeLanguage(lang)
    const locale = lang.slice(0, 2)
    DateTime.local().setLocale(locale)
  }

  let language = i18n.language.toLowerCase()
  if (!languages[language]) language = availableLanguages[0]

  return (
    <Languages>
      {availableLanguages.map(lang => (
        <Language
          key={lang}
          src={flags[lang]}
          alt={flags[lang]}
          onClick={() => handleClick(lang)}
          active={language === lang}
        />
      ))}
    </Languages>
  )
}

export default LanguageSelector

const Languages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`
const Language = styled.img`
  height: 24px;
  border-radius: 2px;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s;
  margin: 0 8px;
  &:hover {
    opacity: 1;
  }
  ${props => (props.active ? 'opacity: 1' : '')};
  @media (max-width: 540px) {
    height: 20px;
  }
`
