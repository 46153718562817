import Africa from '../images/Africa.svg'
import Asia from '../images/Asia.svg'
import AmericaSud from '../images/AmericaSud.svg'
import AmericaNord from '../images/AmericaNord.svg'
import Europa from '../images/Europa.svg'
import Oceania from '../images/Oceania.svg'
import WorldIcon from '../images/World.svg'

export const continentImages = {
  Europe: Europa,
  Africa,
  'South America': AmericaSud,
  'N/C America': AmericaNord,
  Asia,
  Oceania,
  World: WorldIcon
}