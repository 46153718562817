import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { cssvar } from '../../styles/var'

const HeaderDropdown = ({ trigger, children, rightAligned = false, withScroller = false, disabled = false }) => {
  const [open, setOpen] = useState(false)
  return (
    <DropdownWrapper disabled={ disabled }>
      <Trigger onClick={ () => setOpen(true) }>{ trigger }</Trigger>
      { open && (
        <DropdownList
          rightAligned={ rightAligned }
          withScroller={ withScroller }
          onClick={ () => setOpen(false) }>
          { children }
        </DropdownList>
      ) }
      { open && <CloseBg onClick={ (e) => { e.preventDefault(); setOpen(false) } } /> }
    </DropdownWrapper>
  )
}

export default HeaderDropdown

const DropdownWrapper = styled.div`
  height: 100%;
  position: relative;
  opacity: ${ props => props.disabled ? 0.5 : 1 };
  pointer-events:${ props => props.disabled ? 'none' : 'auto' };
`
const Trigger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  cursor: pointer;
`
const DropdownList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  padding: 4px 0;
  background-color: ${ cssvar('mainColor') };
  border: 1px solid rgba(255,255,255, .15);
  border-top-color: ${ cssvar('mainColor') };
  color: white;
  text-align: center;
  border-radius: 0 0 8px 8px;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 12;
  transform: translateX(-50%) translateY(100%);
  overflow: hidden;
  ${
    props => props.withScroller && css`
      max-height: 300px;
      overflow-y: scroll;
    `
  }
  ${ props => props.rightAligned && css`
    text-align: right;
    left: auto;
    right: -10px;
    transform: translateY(100%);
  ` }
`
export const DropdownItem = styled.li`
  display: block;
  padding: 5px 10px;
  color: rgba(255,255,255, .6);
  cursor: pointer;
  white-space: nowrap;
  transition: .3s;
  a {
    color: inherit;
    transition: 0;
  }
  &:hover {
    color: white;
    background-color: rgba(255,255,255, .1);
  }
`
const CloseBg = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  cursor: default;
`
