import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SocialFilterBar from './SocialFilterBar'
import { getFilterNameFromOriginal } from '../../../helpers'
import styled, { css } from 'styled-components/macro'
import { cssvar } from '../../../styles/var'

class ColTitle extends React.Component {

  handleSingleColumnOriginal = () => {
    if (!this.props.setSingleColumnOriginal) return
    return this.props.setSingleColumnOriginal(this.props.original === 1 ? 2 : 1)
  }

  renderTitle = () => {
    const { t, hashtag, fulltext, isFavourite, isReadListPage, original, name, setSingleColumnOriginal } = this.props
    
    let label
    if (isFavourite) {
      label = (
        <Fragment>
          { t('news') } <OriginalToggler original={ original } onClick={ this.handleSingleColumnOriginal }><span>{ t('from_favourites') } </span>/ <span>{ t('about_favourites') }</span></OriginalToggler> { t('your_favourites') }
        </Fragment>
      )
    }
    else if (hashtag) label = `${ t('livestream_feed_hashtag_label') } #${ hashtag }`
    else if (fulltext) label = `${ t('livestream_feed_hashtag_label') } ${ fulltext }`
    else if (isReadListPage) label = t('livestream_feed')
    
    else if (name) {
      label = (
        <Fragment>
          { t('news') } <OriginalToggler original={ original } onClick={ this.handleSingleColumnOriginal }><span>{ t('from') } </span>/ <span>{ t('about') }</span></OriginalToggler> { t(name) }
        </Fragment>
      )
    }
    else label = t('livestream_feed')

    return (
      <Title static={ !setSingleColumnOriginal }>
        { label }
      </Title>
    )
  }

  render() {
    return (
      <TitleWrapper>
        { this.renderTitle() }
        <SocialFilterBar filter={ getFilterNameFromOriginal(this.props.original) } />
      </TitleWrapper>
    )
  }
}

ColTitle.propTypes = {
  isCrossFeed: PropTypes.bool,
  isSingleColumn: PropTypes.bool,
  hashtag: PropTypes.string,
  fulltext: PropTypes.string,
  title: PropTypes.string.isRequired,
  original: PropTypes.number.isRequired,
  isFavourite: PropTypes.bool,
  name: PropTypes.string
}

ColTitle.defaultProps = {
  isCrossFeed: false,
  isSingleColumn: false,
  isFavourite: false,
  name: '',
  hashtag: '',
  fulltext: ''
}

const mapStateToProps = ({ navDrilldownHeader: { data } }) => ({ name: data ? data.name : '' })

export default withTranslation('translation')(connect(mapStateToProps)(ColTitle))

const TitleWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  border-bottom: 1px solid ${ cssvar('borderColor') };
`
const Title = styled.div`
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: 650;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  @media (min-width: 641px) and (max-width: 1200px) {
    font-size: 18px;
  }
  ${ props => !props.static && css`
    @media (max-width: 1200px) {
      pointer-events: auto;
    }
  ` }
`
const OriginalToggler = styled.span`
  border-bottom: 2px dashed #bbb;
  cursor: pointer;
  white-space: normal;
  transition: border-color .3s;
  span {
    color: #bbb;
    transition: color .3s;
  }
  span:nth-child(${ props => props.original }) {
    color: ${ cssvar('primaryColor') };
  }
  &:hover {
    border-color: ${ cssvar('primaryHoverColor') };
  }
  @media (min-width: 1201px) {
    font-size: 0;
    border: none;
    pointer-events: none;
    span {
      display: none;
    }
    span:nth-child(${ props => props.original }) {
      display: inline;
      font-size: 16px;
      color: ${ cssvar('mainColor') };
    }
  }
`