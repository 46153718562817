import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'
import missingPreview from '../../../images/missing-preview-news.jpg'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'

const Article = ({ article }) => {
  const {
    url, title, date, excerpt, site_name, image_url,
  } = article

  const handlePreviewError = (e) => {
    e.target.onerror = null
    e.target.src = missingPreview
  }

  const renderPreview = source => (
    <PreviewImageContainer>
      <PreviewImage src={source || missingPreview} onError={handlePreviewError} />
    </PreviewImageContainer>
  )

  return (
    <ArticleItem
      href={url}
      target='_blank'
    >
      <MetaContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {title && <ArticleTitle>{title}</ArticleTitle>}
          {date && <ArticleDate>{moment(date).format('DD.MM.YYYY')}</ArticleDate>}
        </div>
        {site_name ? <ArticleSource>{site_name}</ArticleSource> : <></>}
        {excerpt && <Excerpt>{excerpt}</Excerpt>}
      </MetaContainer>
      <>
        {renderPreview(image_url)}
      </>
    </ArticleItem>
  )
}

const News = ({ news }) => {
  const { t } = useTranslation()

  const [showAll, setShowAll] = useState(false)


  return (
    <NewsContainer>
      <NewsTitle>{t('other-events-news')}</NewsTitle>
      {news.slice(0, showAll ? news.length : 1).map((article, i) => <Article key={i} article={article} />)}
      {news.length > 1 && <Button style={{ margin: '10px auto', marginLeft: '10px' }} onClick={() => setShowAll(!showAll)}>{showAll ? t('other-events-show-less') : t('other-events-show-more')}</Button>}
    </NewsContainer>
  )
}

export default News


const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 15px;
  @media (max-width: 800px) {
  }
`

const NewsTitle = styled.div`
  font-size: 20px;
  font-weight: 550;
  margin-top: 20px;
  padding-left: 15px;
  @media (max-width: 800px) {
    margin-top: 5px;
    padding-left: 0px;
    font-size: 16px;
  }
`


const ArticleItem = styled.a`
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-top: 1px solid ${cssvar('borderColor')};
  cursor: pointer;
  color: ${cssvar('mainColor')};
  position: relative;
  transition: .3s;
  column-gap: 10px;
  &:first-of-type {
    border-top: none;
  }
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    background-color: ${cssvar('mainColor')};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: width .3s;
  }
  &:hover {
    color: ${cssvar('mainColor')};
    background-color: ${cssvar('hoverColor')};
    &:before {
      width: .33em;
    }
  }
  @media (max-width: 800px) {
    padding: 15px 0;
    flex-direction: column;
    margin-left: 12px;
    row-gap: 10px;
    &:before {
      display: none;
    }
  }
`

const MetaContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
   padding-left: 0px;
  }
`

const ArticleTitle = styled.div`
  font-size: 16px;
  font-weight: 550;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`
const ArticleSource = styled.div`
  font-size: 12px;
  color: ${cssvar('secondaryTextColor')};
`
const Excerpt = styled.p`
  margin: 0;
  margin-top: 1em;
  font-size: 13px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`

const ArticleDate = styled.span`
  font-size: 12px;
  color: ${cssvar('secondaryTextColor')};
  font-weight: bold;
`
const PreviewImageContainer = styled.div`
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  border: 1px solid ${cssvar('borderColor')};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  @media (max-width: 800px) {
    width: 100%;
    height: 200px;
  }
`
const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`