import axios from 'axios';
import { applyFilters, geoStatsAdapter } from '../../helpers';

import { get } from './constants';

/**
 * Get statistics
 */
const getStatisticsRequest = () => ({ type: get.request });
const getStatisticsFailure = error => ({
  type: get.failure,
  error,
});
const getStatisticsSuccess = data => ({
  type: get.success,
  data,
});

const getStatistics = ({ options, statsToFetch, isFavourite }) => async (dispatch) => {
  dispatch(getStatisticsRequest());

  try {
    const responses = await Promise.all(statsToFetch.map(stat => (
      axios({
        method: 'get',
        url: applyFilters({ url: isFavourite ? '/favourites/livestats' : '/livestats' }),
        params: { ...options, ...stat },
      })
    )));

    dispatch(getStatisticsSuccess(responses.reduce(
      (accumulator, { data }, index) => {
        const { stateName, type } = statsToFetch[index];
        accumulator[stateName] = type === 'geo-country' ? geoStatsAdapter(data) : data;

        return accumulator;
      },
      {},
    )));
  } catch (error) {
    dispatch(getStatisticsFailure(error));
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getStatistics };
