import axios from 'axios';
import { BASE_URL } from '../../api';

import { get, post, remove } from './constants';

/**
 * Get favourites
 */
const getFavouritesRequest = () => ({ type: get.request });
const getFavouritesFailure = error => ({
  type: get.failure,
  error,
});
const getFavouritesSuccess = data => ({
  type: get.success,
  data,
});

const getFavourites = ({ url = '/nav-drilldown' } = {}) => async (dispatch) => {
  dispatch(getFavouritesRequest());

  try {
    const { data } = await axios({
      method: 'get',
      url: `${BASE_URL}/favourites${url}`,
    });

    dispatch(getFavouritesSuccess(data));
  } catch (error) {
    dispatch(getFavouritesFailure(error));
  }
};

/**
 * Post favourite
 */
const postFavouritesRequest = () => ({ type: post.request });
const postFavouritesFailure = error => ({
  type: post.failure,
  error,
});
const postFavouritesSuccess = data => ({ type: post.success, data });

const postFavourites = ({
  tag,
  type = 'tag',
  push,
  digest,
} = {}) => async (dispatch) => {
  dispatch(postFavouritesRequest());

  try {
    const { data: { favourite_settings } } = await axios({
      method: 'post',
      url: `${BASE_URL}/favourite/${type}/${tag}`,
      data: { push, digest },
    });

    dispatch(postFavouritesSuccess({ tag, favourite_settings }));
  } catch ({ response }) {
    dispatch(postFavouritesFailure(response));
  }
};


/**
 * Delete favourite
 */
const removeFavouritesRequest = () => ({ type: remove.request });
const removeFavouritesFailure = error => ({
  type: remove.failure,
  error,
});
const removeFavouritesSuccess = data => ({
  type: remove.success,
  data,
});

const removeFavourites = (tag, { hashtag } = {}) => async (dispatch) => {
  dispatch(removeFavouritesRequest());
  const item = hashtag ? 'hashtag' : 'tag';

  try {
    await axios({
      method: 'delete',
      url: `${BASE_URL}/favourite/${item}/${tag}`,
    });

    dispatch(removeFavouritesSuccess(tag));
  } catch ({ response }) {
    dispatch(removeFavouritesFailure(response));
  }
};

export { getFavourites, postFavourites, removeFavourites };
