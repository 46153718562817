import React from 'react'
import { SearchProvider } from './SearchContext'
import Logo from './Logo'
import MainSearch from './MainSearch'
import SearchResults from './SearchResults'

const Injuries = () => (
  <SearchProvider>
    <Logo />
    <MainSearch />
    <SearchResults />
  </SearchProvider>
)

export default Injuries
