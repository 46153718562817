import React from 'react';
import PropTypes from 'prop-types';

import { TagCloud } from 'react-tagcloud';
import T from '../../Generic/T';

const CloudChart = ({ hashtag, trigger, fulltext }) => {
  const handleClick = ({ value }) => {
    if (!fulltext) {
      trigger(value, value, { isCrossTag: true });
    }
  };

  const data = hashtag.map((row) => {
    const { key, doc_count: count } = row;

    return { value: `#${key}`, count };
  });

  return (
    <div className={!fulltext ? 'hashtag-cloud' : 'disabled hashtag-cloud'}>
      <h1 className="cloud-title">
        <T i18nKey="analytics-hashtag-cloud" />
      </h1>
      {
        data.length > 0
          ? (
            <TagCloud
              minSize={10}
              maxSize={39}
              tags={data}
              onClick={handleClick}
              shuffle={false}
            />
          )
          : (
            <div className="no-stats">
              <T i18nKey="stats_not_available" />
            </div>
          )
      }
    </div>
  );
};

CloudChart.propTypes = {
  hashtag: PropTypes.arrayOf(),
  fulltext: PropTypes.arrayOf(),
  trigger: PropTypes.func.isRequired,
};

CloudChart.defaultProps = {
  hashtag: [],
  fulltext: null,
};

export default CloudChart;
