import { createReducer, createAction } from 'redux-starter-kit'
import _set from 'lodash/set'

export const favouritesSnapshot = createAction('FAVOURITES_SNAPSHOT')
export const favouritesToggle = createAction('FAVOURITES_TOGGLE')

export const readlistSnapshot = createAction('READLIST_SNAPSHOT')
export const readlistRemove = createAction('READLIST_REMOVE')
export const readlistAdd = createAction('READLIST_ADD')

export default createReducer({}, {
  [favouritesSnapshot]: (state, action) => {
    state.tag = action.payload
  },
  [favouritesToggle]: (state, action) => {
    const id = action.payload
    state.tag[id] = !state[id]
  },
  [readlistSnapshot]: (state, action) => {
    const { payload } = action
    const { data } = payload
    state.feeds = data.reduce(
      (res, row) => ({
        ...res,
        [row._id]: row,
      }),
      {},
    )
  },
  [readlistRemove]: (state, action) => {
    const { id } = action.payload
    delete state.feeds[id]
  },
  [readlistAdd]: (state, action) => {
    const { id } = action.payload
    _set(state, ['feeds', id], true)
  },
})
