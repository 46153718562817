const GET_NAV_DRILLDOWN_REQUEST = 'getNavDrilldown/REQUEST'
const GET_NAV_DRILLDOWN_SUCCESS = 'getNavDrilldown/SUCCESS'
const GET_NAV_DRILLDOWN_FAILURE = 'getNavDrilldown/FAILURE'

const get = {
  request: GET_NAV_DRILLDOWN_REQUEST,
  success: GET_NAV_DRILLDOWN_SUCCESS,
  failure: GET_NAV_DRILLDOWN_FAILURE
}

// eslint-disable-next-line import/prefer-default-export
export { get }