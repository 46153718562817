import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BodyImage from './BodyImage'
import Dialog from '../../Dialog'
import { Select } from 'antd'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'
import InjuriesOverall from './OverallMobile'

const EmptyState = ({ data, coverageStatus }) => {
  const { t } = useTranslation()
  const [dialog, setDialog] = useState(false)
  return (
    <>
      <InjuriesOverall data={data} coverageStatus={coverageStatus} />
      <BodyOnlyWrapper>
        <EmptyMessage>{t('No injuries recorded')}</EmptyMessage>
        <Side>
          <BodyImage side='front' dataFilters={null} heatMap={{}} />
        </Side>
        {/* <Side>
          <BodyImage side='rear' dataFilters={null} heatMap={{}} />
        </Side> */}
        <Dialog
          open={dialog}
          onClose={() => setDialog(false)}
          title='Information request'
          subtitle='Please fill the form'
          footer={
            <ButtonWrapper>
              <Button onClick={() => setDialog(false)}>Submit request</Button>
            </ButtonWrapper>
          }
        >
          <Form>
            <Label>Request category</Label>
            <Select defaultValue='1' style={{ width: '100%' }}>
              <Select.Option value='1'>Missing data</Select.Option>
              <Select.Option value='2'>Update required</Select.Option>
            </Select>
            <Label>Describe the info you'd like to request</Label>
            <TextArea rows='5'></TextArea>
          </Form>
        </Dialog>
      </BodyOnlyWrapper>
    </>
  )
}

export default EmptyState

const BodyOnlyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: center; */
  gap: 0 30px;
  max-width: 500px;
  /* margin: 20px auto; */
  padding: 0 30px;
  position: relative;
`
const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  opacity: 0.5;
  pointer-events: none;
`
export const EmptyMessage = styled.div`
  font-weight: 550;
  font-size: 22px;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 5;
  opacity: 0.75;
`
export const RequestLink = styled.div`
  margin-top: 15px;
  font-size: 16px;
  span {
    color: ${cssvar('primaryColor')};
    border-bottom: 1px dashed ${cssvar('primaryColor')};
    text-shadow: 1px 1px 0 white;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      border: none;
    }
  }
`
export const Form = styled.div`
  width: 100%;
  max-width: 400px;
  @media (max-width: 600px) {
    max-width: auto;
  }
`
export const Label = styled.div`
  margin-top: 30px;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  &:first-child {
    margin-top: 0;
  }
`
export const TextArea = styled.textarea`
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Button = styled.div`
  flex: 0 0 auto;
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  background-color: ${cssvar('primaryColor')};
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${cssvar('primaryHoverColor')};
  }
`
