import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelectedRow } from './contexts'
import BodyImage from './BodyImage'
import Table from './Table'
import Legend from './Legend'
import RotateIcon from '../../../images/rotate.svg'
import ClearIcon from '../../../images/clear.svg'
import styled, { keyframes, css } from 'styled-components'
import InjuriesOverallMobile from './OverallMobile'

/**
 * Utilities
 */
const getTotal = (partial, value) => (partial > 0 ? partial + +value : +value)

const getHeatMap = data => data.reduce((accumulator, {
  injuried_part: injuriedPart,
  side: injuriedSide,
  recovery_days: recoveryDays,
  date: injuryDate,
  ongoing
}) => {
  const result = { ...accumulator }

      // If "both" is set as side, map right and left both
      const currentParts =
        injuriedSide === 'both'
          ? [`${injuriedPart}-right`, `${injuriedPart}-left`]
          : [`${injuriedPart}-${injuriedSide}`]

  // if the injury is ongoing calculate days from date
  const actualDays = ongoing ? moment().diff(moment(injuryDate), 'days') : recoveryDays

      currentParts.forEach((currentPart) => {
        result[currentPart] = getTotal(result[currentPart], actualDays)
      })

      return result
    },
    {}
  )

const getDataSource = (data, dataFilters) =>
  data.filter(({ injuried_part: injuriedPart, side: injuriedSide }) => {
    if (dataFilters) {
      const { part, side } = dataFilters

      // If a filter is set avoid to read "void" side in table
      return injuriedPart === part && (injuriedSide === side || injuriedSide === 'both')
    }
    return true
  })

/**
 * Component
 */
const Heatmap = ({ data, bodyOnly, coverageStatus, coverageProgress, playerId, playerName }) => {
  const { t } = useTranslation()
  const [bodySide, setBodySide] = useState('front')
  const [animateButton, setAnimateButton] = useState(false)
  const {
    bodyPartSelectedState: [dataFilters, setDataFilters],
  } = useSelectedRow()

  const getTitle = () => {
    if (dataFilters) {
      if (dataFilters.side) return t(`injury-part-${dataFilters.part}-${dataFilters.side}`)
      return t(`injury-part-${dataFilters.part}`)
    }
    return null
  }

  const clearSelected = () => {
    setDataFilters()
  }

  const toggleBodySide = () => {
    setBodySide((currentBodySide) => (currentBodySide === 'front' ? 'rear' : 'front'))
    setAnimateButton(true)
    setTimeout(() => setAnimateButton(false), 500)
  }

  if (bodyOnly) {
    return (
      <BodyOnlyWrapper>
        <Side>
          <SideName>{t(`injury-button-turn-front`)}</SideName>
          <BodyImage side='front' dataFilters={dataFilters} heatMap={getHeatMap(data)} />
        </Side>
        <Side>
          <SideName>{t(`injury-button-turn-rear`)}</SideName>
          <BodyImage side='rear' dataFilters={dataFilters} heatMap={getHeatMap(data)} />
        </Side>
        <LegendWrapper>
          <Legend />
        </LegendWrapper>
      </BodyOnlyWrapper>
    )
  }

  return (
    <Wrapper>
      <HeatmapWrapper>
        <TurnButton onClick={ toggleBodySide } animate={ animateButton }>
          { t(`injury-button-turn-${bodySide}`) }
        </TurnButton>
        { dataFilters && <ClearButton onClick={ clearSelected } /> }
        <BodyImage
          side={ bodySide }
          dataFilters={ dataFilters }
          onClick={ setDataFilters }
          heatMap={ getHeatMap(data) }  />
        <Legend />
      </HeatmapWrapper>
      { data.length === 0 ? (
        <EmptyMessageContainer>
          <EmptyMessage>{ t('No injuries recorded') }</EmptyMessage>
        </EmptyMessageContainer>
      ) : <Table data={ getDataSource(data, dataFilters) } title={ getTitle() } /> }
      <InjuriesOverallMobile
        data={ data }
        coverageProgress={ coverageProgress }
        coverageStatus={ coverageStatus }
        playerId={ playerId }
        playerName={ playerName } />
    </Wrapper>
  )
}

Heatmap.propTypes = {
  playerName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  coverageStatus: PropTypes.string
}

Heatmap.defaultProps = {
  playerName: null,
  data: []
}

export default Heatmap

const rotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 5fr;
  grid-gap: 30px;
  align-items: flex-start;
  padding-top: 30px;
  position: relative;
  @media (max-width: 1440px) {
    grid-gap: 15px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`
const HeatmapWrapper = styled.div`
  position: sticky;
  top: 10px;
  @media (max-width: 1024px) {
    display: none;
  }
`
const TurnButton = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  top: 8px;
  left: 0;
  opacity: 0.7;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
  @media (max-width: 1500px) {
    font-size: 12px;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 7px;
    background-image: url(${RotateIcon});
    background-size: cover;
    transform-origin: center;
    ${ props => props.animate && css`
      animation: ${rotate} 0.5s ease-in-out forwards;
    ` }
    @media (max-width: 1500px) {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
  &:hover {
    opacity: 1;
  }
`
const ClearButton = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${ClearIcon});
  background-size: cover;
  opacity: 0.7;
  position: absolute;
  top: 8px;
  right: 0;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 1500px) {
    width: 14px;
    height: 14px;
  }
`

const BodyOnlyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 0 30px;
  max-width: 500px;
  margin: 20px auto;
  padding: 0 30px;
  position: relative;
`
const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  pointer-events: none;
`
const SideName = styled.div`
  font-size: 16px;
  font-weight: 600;
`
const LegendWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`
const EmptyMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
`
const EmptyMessage = styled.div`
  font-weight: 550;
  font-size: 22px;
  border-radius: 8px;
  text-align: center;
  z-index: 5;
  opacity: 0.75;
  @media (max-width: 640px) {
    font-size: 18px;
    padding: 10vh 0;
  }
`
