import axios from 'axios';
import { urlBuilder } from '../../helpers';

import {
  GET_NAV_DRILLDOWN_HEADER_REQUEST,
  GET_NAV_DRILLDOWN_HEADER_SUCCESS,
  GET_NAV_DRILLDOWN_HEADER_FAILURE,
} from './constants';

const getNavDrilldownHeaderRequest = () => ({ type: GET_NAV_DRILLDOWN_HEADER_REQUEST });
const getNavDrilldownHeaderFailure = error => ({
  type: GET_NAV_DRILLDOWN_HEADER_FAILURE,
  error,
});
const getNavDrilldownHeaderSuccess = navDrilldownHeader => ({
  type: GET_NAV_DRILLDOWN_HEADER_SUCCESS,
  navDrilldownHeader,
});

const getNavDrilldownHeader = ({ tag, hashtag, fulltext }) => async (dispatch) => {
  dispatch(getNavDrilldownHeaderRequest());

  const url = hashtag
    ? urlBuilder({ url: '/hashtag/details', hashtag })
    : urlBuilder({ url: '/profile', tag });

  try {
    const { data } = await axios({ method: 'get', url });

    dispatch(getNavDrilldownHeaderSuccess({ ...data, tag, hashtag, fulltext }));
  } catch (error) {
    dispatch(getNavDrilldownHeaderFailure(error));
  }
};

export default getNavDrilldownHeader;
