import axios from 'axios';
// import history from '../history'
import { applyFilters, streamAdapter } from '../helpers';

// LIVESTREAM FETCH - if any parameter is passed it will be used as filter
const fetchStream = (opts = {}, isFavourite, isCrossFeed) => {
  const path = (isFavourite)
    ? '/favourites/livestream'
    : isCrossFeed
      ? '/livestream/custom'
      : '/livestream';

  const url = applyFilters(
    { url: path },
      {
        ...opts,
        preview: true,
      },
  );

  return axios.get(url)
    .then(res => streamAdapter(res))
    .catch((err) => {
      console.log('fetchStream', err);
      // if (err.toString().indexOf('401') > -1) {
      //   history.push('/login')
      // }
    });
};

export { fetchStream };
