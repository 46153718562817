import React from 'react';
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import DataLoading from './DataLoading';

const PillarChart = ({
  data,
  type,
  name,
  hashtag,
  fulltext,
  t,
  isFavourite,
  loading,
}) => {
  const dati = data || [];
  const labels = dati.map(row => row.key);
  const values = dati.map(row => row.doc_count);

  const dataset = type === 'followed'
    ? {
      label: 'Followers',
      data: values,
      hoverBackgroundColor: 'rgb(189, 189, 189)',
      borderColor: 'rgb(189, 189, 189)',
      borderWidth: 1,
      hoverBorderWidth: 2,
    }
    : {
      label: 'News',
      data: values,
      hoverBackgroundColor: 'rgb(189, 189, 189)',
      borderColor: 'rgb(189, 189, 189)',
      borderWidth: 1,
      hoverBorderWidth: 2,
    };

  const set = {
    labels,
    datasets: [dataset],
  };

  const suffix = hashtag
    ? ` #${hashtag}`
    : fulltext
      ? ` ${fulltext}`
      : name
        ? ` ${name}`
        : ` ${t('World')}`;

  const renderChart = () => {
    if (loading) {
      return <DataLoading />;
    }

    if (data && data.length > 0) {
      return (
        <Bar
          data={set}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
          }}
        />
      );
    }

    return (
      <div className="no-stats">
        {t('stats_not_available')}
      </div>
    );
  };

  return (
    <div className="pillar-chart">
      <h1 className="pillar-title">
        {
          isFavourite
            ? (type === 'active')
              ? t('favourite-analytics-most-prolific')
              : t('favourite-analytics-most-followed-account')
            : (type === 'active')
              ? `${t('analytics-most-prolific')} ${suffix}`
              : `${t('analytics-most-followed-account')} ${suffix}`
          }
      </h1>
      {renderChart()}
    </div>
  );
};
export default withTranslation('translation')(PillarChart);
