const paths = {
  front: [
    {
      bodyPart: 'abdomen',
      d: 'M312.6,364.5c-0.6,0-1.1-0.1-1.7-0.2c-34.5-3.9-40.2-42.6-77-42.6c-38.8,0-60.8,52.5-81.8,52.5c0.2,3.8,0.2,7.6,0,11.5c-0.2,4.7-0.6,8.1-1.1,10.8c35.9,0,33.7,63,82.9,63c49.2,0,41.6-60.5,77.6-63.4C310.9,386.7,310.6,376.4,312.6,364.5z',
    }, {
      bodyPart: 'ankle',
      side: 'left',
      d: 'M282.2,972c-2.8,31.8,1.1,30.2-1.8,37.7c-7.6-5.9-23.7-16.2-40-6.2c0.1-3.8,0.1-7.4-0.2-10.5c2-12,4-12.6,6-28.8c8.1,5.7,25.1,4.8,36,7.6V972z',
    }, {
      bodyPart: 'ankle',
      side: 'right',
      d: 'M227,1000.7c-4-8.3-14.1-14.9-39.2,7c-4.2-8.4-3.1-14.7-3.1-14.7c0-5.7-0.4-11.7-1-17.8c10.4,1.2,23.8-0.2,36.3-11c0.7,2.9,1.5,5.5,2.5,7.8C222.4,972,228.9,985.1,227,1000.7z',
    }, {
      bodyPart: 'arm',
      side: 'left',
      d: 'M379.6,359.7L379.6,359.7c-3.2,7.8-7.9,15.2-14.6,21.6c-23.1-30.4-37.8-27.3-45.1-42c11.5-24.1,9.9-21,10.8-78.9c0.1-6.7,5.2-13.9,4.9-21.5c6.7,13.3,19.7,12.7,34.6,37.5c2,20.3,4.1,39.7,5.6,54.2c1.7,15.5,2.8,25.5,2.8,25.5C379,357.4,379.3,358.6,379.6,359.7z',
    }, {
      bodyPart: 'arm',
      side: 'right',
      d: 'M142.9,333.6c-0.1,0.2-0.2,0.4-0.2,0.5c-7.3,14.7-27.3,10.5-49.3,47.2c-6.1-9.4-9.5-20.4-11.1-31.8c0.5-1.3,1.1-2.6,1.7-3.8c1.3-8,2.6-15.7,3.8-23c4.7-27.6,8.6-48.6,8.8-49.4l0,0c11.5-28.3,22-17.8,35.7-44.1c-1.1,2.6,4.6,12.2,4.9,26.2C137.6,280.6,126.6,294.5,142.9,333.6z',
    }, {
      bodyPart: 'chest',
      side: 'left',
      d: 'M335.7,238.9c0,0.1,0,0.3,0,0.4c0,7.5-4.8,14.6-4.9,21.1c-0.9,57.9,0.7,54.8-10.8,78.9c-2,5-3.5,9.7-4.7,14.2c-0.7,2.5-1.2,4.9-1.7,7.3c-0.3,1.6-0.6,3.1-0.8,4.6c-35.4-2.9-41.1-41.5-76.8-42.8c0-0.3-2.5-0.2-2.5-0.5c-1.6-18.8,0-35.4,0-49.2v-2.2c0-1,0-2,0-3s0-2,0-2.9c0,0,0,0,0,0c0-1,0-1.9,0.1-2.9s0-1.9,0.1-2.8c0-0.6,0-1.2,0.1-1.8c0-1,0.1-1.9,0.1-2.8c0,0,0-0.1,0-0.1c0.7-14.5,2.3-25.8,4.4-34.6c0.1-0.3,0.2-0.6,0.2-1c0.1-0.5,0.3-1,0.4-1.5c0-0.1,0-0.1,0.1-0.2c5.3-19.8,13.2-25.5,18-27.1c0,0,0,0,0,0c0.6-0.2,1.3-0.3,1.9-0.5h0c1.4-0.3,2.9-0.5,4.5-0.6c0,0,0,0,0,0c1.3-0.1,2.6-0.2,4.1-0.2c5.2-0.1,11-0.1,17-0.2c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0.1c1,0.2,2.7,0.7,4.9,1.4c0.4,0.1,0.8,0.3,1.2,0.4c1.3,0.4,2.7,0.9,4.2,1.6c0.3,0.1,0.6,0.2,0.9,0.4c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.3,1,0.4c0.3,0.1,0.6,0.3,0.9,0.4c3.4,1.5,7.1,3.5,10.7,6.1l0.9,0.6c0.7,0.5,1.4,1,2.2,1.6c0.8,0.6,1.6,1.3,2.4,2c0.3,0.2,0.5,0.4,0.8,0.6c0.4,0.3,0.7,0.6,1,0.9c0.7,0.6,1.4,1.2,2,1.9c0.6,0.6,1.2,1.2,1.8,1.9c0.3,0.3,0.5,0.6,0.8,0.9c0.2,0.2,0.3,0.4,0.5,0.5c0.3,0.3,0.6,0.7,0.9,1s0.5,0.6,0.8,0.9c0.2,0.2,0.3,0.4,0.5,0.6c0.5,0.6,0.9,1.2,1.4,1.9c0.3,0.4,0.6,0.8,0.9,1.3c0.3,0.4,0.5,0.7,0.7,1.1c3.7,5.9,6.7,12.9,8.1,21.3c-0.3,0-0.3,0-0.4,0C335.6,238.7,335.6,238.8,335.7,238.9z',
    }, {
      bodyPart: 'chest',
      side: 'right',
      d: 'M233.5,270.7c0,0.7,0,1.5,0,2.2c0,13.8,1.6,30.4,0,49.2c0,0.3-2.5,0.2-2.5,0.6c-36.9,2.7-58.3,52.3-78.7,52.4v-0.5c-0.7-17.7-4.6-34-8.6-39.5c0-0.1,0-0.1,0-0.2c-0.2-0.4-0.4-0.9-0.5-1.3c-16.3-39.1-5.3-53-5.8-78.2c-0.3-14-6-23.6-4.9-26.2c2.5-18.6,15.3-25.6,36.6-35.9c4.9-2.4,10.2-4.9,15.9-7.7c8.1,0.7,14.8,1.4,18.3,2.1c2.6,0.6,5,1.4,7.1,2.3c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.5,0.2,0.7,0.3c8,4.1,21,19.6,21.5,77.3C233.5,268.8,233.5,269.8,233.5,270.7z',
    }, {
      bodyPart: 'foot',
      side: 'left',
      d: 'M293.7,1057c-25.2,4.2-46.2,4.2-51.4-4.2c-5.2-8.4-2.1-11.5-2.1-15.7c-1.6-7.2-0.1-21.2,0.2-33.5c16.3-10.1,32.4,0.3,40,6.2c-0.3,0.9-0.8,2-1.4,3.2c2.1,13.6,9.4,21,9.4,21S304.2,1045.5,293.7,1057z',
    }, {
      bodyPart: 'foot',
      side: 'right',
      d: 'M226.6,1059.1c-5.2,5.2-48.3-1-48.3-1s-9.4-9.4-3.1-19.9c6.3-10.5,13.6-21,12.6-30.4c25.1-21.9,35.2-15.3,39.2-7c-0.1,0.9-0.3,1.9-0.4,2.8c3.1,10.5,1,26.2,1,26.2s3.1,6.3,3.1,9.4C230.8,1042.3,231.8,1053.9,226.6,1059.1z',
    }, {
      bodyPart: 'forearm',
      side: 'left',
      d: 'M380.8,468.4c0.8,23.7,2.6,32.9,3.2,40.7c-12.4-2.9-32.7-3.8-36.5-1c-4.2-37.2-21.4-88.5-22.3-102.6c-1-16.8,3.1-43-5.2-66.1c7.3,14.7,22,11.5,45.1,42c6.7-6.4,11.4-13.8,14.6-21.6C389.9,400.4,383.9,403.2,380.8,468.4z',
    }, {
      bodyPart: 'forearm',
      side: 'right',
      d: 'M135.3,369.8c3.1,45.1-30.4,110.2-30.4,132.2c-3-3-22.9-3.1-35.7-0.5c0.2-4.3,0.6-10.8,2.9-22.6c4.4-22.3-5.3-90.8,10.2-129.4c1.6,11.4,5,22.5,11.1,31.8c22-36.7,42-32.5,49.3-47.2C142.6,334.2,139.5,350.9,135.3,369.8z',
    }, {
      bodyPart: 'head',
      d: 'M292.1,160.5c-11.1-8.2-18.9-15.7-20.4-19.3c1.1-7.3,1.1-25.2,1.1-25.2s0-12.6,3.2-19.9c4.2,12.6,13.2-14.4,13.2-14.4s4.6-27.6-7.9-14c3.2-21-6.3-71.3-50.3-66.1c-47.2,7.3-43,40.9-43,66.1c-8.4-11.5-9.4,4.2-5,15c4.5,10.9,3.9,20.6,10.2,16.4c0,23.1,8.4,28.3,8.4,28.3c1,9.4-2.1,16.8-2.1,16.8s-7.2,6-20.8,13.9c-11,6.5-26.2,14.2-45.1,21.2c-0.3,0.1-0.6,0.2-0.9,0.3c9.7,2.8,34.2,4.4,52.1,5.9c8.1,0.7,14.8,1.4,18.3,2.1c2.9,0.6,5.3,1.5,7.6,2.5c0,0,0,0,0,0c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.5,0.2,0.7,0.3c8,4.1,21,19.6,21.5,77.3c0,0.9,0,1.8,0,2.8c0-1,0-2,0-3c0-1,0-2,0-2.9c0,0,0,0,0,0c0-1,0-1.9,0.1-2.9s0-1.9,0.1-2.8c0-0.6,0-1.2,0.1-1.8c0-1,0.1-1.9,0.1-2.8c0,0,0-0.1,0-0.1c0.7-14.2,2.2-25.6,4.4-34.6c0.1-0.3,0.2-0.6,0.2-1c0.1-0.5,0.3-1,0.4-1.5c0-0.1,0-0.1,0.1-0.2c5.3-19.8,13.2-25.5,18-27.1c0,0,0,0,0,0c0.6-0.2,1.3-0.3,1.9-0.5h0c1.4-0.3,2.9-0.5,4.5-0.6c0,0,0,0,0,0c1.3-0.2,2.6-0.3,3.9-0.3c5.2-0.1,11-0.1,17-0.2h5.4c1.2,0,2.4-0.1,3.6-0.1c4.3-0.1,8.2-0.4,11.8-0.6c0.6,0,1.2-0.1,1.7-0.1c2.5-0.2,4.9-0.5,7.1-0.7c0.8-0.1,1.6-0.2,2.3-0.3c0.1,0,0.1,0,0.2,0c1.3-0.2,2.5-0.3,3.6-0.5c5.4-0.7,10-1.4,14.4-1.8C319,178.2,303.7,169,292.1,160.5z',
    }, {
      bodyPart: 'hand',
      side: 'left',
      d: 'M425.9,572.3c-4.2,10.5-22-1-24.1-6.3c-2.1,17.8,11.5,57.7,0,61.9c-10.5,1-7.3-44.1-14.7-48.3c-2.1,5.2,8.4,58.8-1,58.8c-11.5,4.2-8.4-57.7-13.6-56.7c-4.2,13.6,6.3,50.4-4.2,50.4c-9.4,4.2-5.2-51.4-10.5-52.5c-4.2,3.1,6.3,39.9-5.2,36.7c-6.3,0-3.1-19.9-6.3-39.9c-5.3-22,2.1-45.1,2.1-49.3c0.3-5.6,0-12.1-0.8-19.1l0,0c3.8-2.8,24.1-1.8,36.5,1c0.2,2.9,0.2,5.5-0.1,8.6c2.1,6.3,13.6,5.2,22,30.4C412.3,568.1,425.9,565,425.9,572.3z',
    }, {
      bodyPart: 'hand',
      side: 'right',
      d: 'M103.8,568.1c-4.2,23.1-2.1,44.1-9.4,42c-8.4,0,1-38.8-3.1-37.8c-5.2,0-1,52.5-12.6,52.5c-9.4,3.1,1-49.3-2.1-50.4c-6.3-1-3.1,55.6-14.7,55.6s2.1-52.5,0-58.8c-6.3-2.1-5.2,51.4-16.8,47.2c-11.5-4.2,7.3-52.5,2.1-57.7c0,0-17.8,14.7-23.1,2.1c6.3-5.2,16.8-15.7,16.8-15.7s6.3-24.1,26.2-36.7c1.7-2.6,1.8-4.7,2-8.9c12.8-2.7,32.8-2.5,35.7,0.5c0,5.2,0,7.3-2.1,13.6C100.7,522,109.1,527.2,103.8,568.1z',
    }, {
      bodyPart: 'knee',
      side: 'left',
      d: 'M310.3,704.3c-1.8,5.6-3,8.6-3,8.6s-3.7,29.9-5,53.6c-7.5,10.6-7.8,37.7-28.3,37.7c-12.7,0-22.5-12.7-30.1-22.9c-3-12.8-6.7-28.1-8.2-45.8c-0.5-5.3-0.6-10.7-0.6-16.4c16,35.4,43.7,38.1,51.6,30.2C294.5,741.4,298.2,719.9,310.3,704.3z',
    }, {
      bodyPart: 'knee',
      side: 'right',
      d: 'M221.2,720.3c-0.9,40.8-6.9,47.9-8,59.7c-8.6,7.2-12.4,24.2-30.6,24.2c-22,0-20.6-23.4-26.4-31.4c0.1-0.7,0.1-1.1,0.1-1.1s-3.1-45.1-7.3-70.3c-0.3-1.3-0.9-3.2-1.7-5.5c12.3,15.7,13.9,44.8,22.6,53.4C178.5,757.9,208,758.1,221.2,720.3z',
    }, {
      bodyPart: 'leg',
      side: 'left',
      d: 'M282.2,971.8c-10.9-2.8-27.9-1.8-36-7.6c0.1-0.9,0.2-1.8,0.3-2.7c5.2-51.4-16.8-92.3,0-168.9c-0.7-3.5-1.6-7.3-2.6-11.4c7.7,10.2,17.5,22.9,30.1,22.9c20.4,0,20.8-27.1,28.3-37.7c-0.1,1.4-0.1,2.8-0.2,4.1C319.9,880.7,291.7,895.4,282.2,971.8z',
    }, {
      bodyPart: 'leg',
      side: 'right',
      d: 'M219.9,964.2c-12.5,10.7-25.9,12.2-36.3,11c-5.5-49.7-30.5-109.2-31.5-135.3c-0.9-26.5,3.2-60.7,4-67.1c5.7,8.1,4.4,31.4,26.4,31.4c18.2,0,22.1-17,30.6-24.2c-0.4,3.9-0.2,8.4,0.8,14.7C231.6,839.7,211,925.5,219.9,964.2z',
    }, {
      bodyPart: 'pelvis',
      side: 'left',
      d: 'M313.7,443.3c-28.3,16.8-64.4,106.2-82.9,114.4c-0.4,0.2-0.7,0.3-1.1,0.5v-0.5c-0.5-0.9-1-1.6-1.5-2.2c4.6-23.7-7-50.9-0.6-96.1c0.1-0.1,0.2-0.2,0.2-0.3c1.9,0.2,3.9,0.3,6,0.3c49.2,0,41.6-60.5,77.6-63.4C316.8,417,313.7,443.3,313.7,443.3z',
    }, {
      bodyPart: 'pelvis',
      side: 'right',
      d: 'M228.2,555.5c-3.3-3.3-7.9-0.9-7.9-0.9c-24.4-19.4-29.1-77.3-74.5-122.9v-0.1c0.1-24.6,3.4-24.7,5.2-35.1c23.5,0,30.6,26.9,46.5,45.5c7.4,8.6,16.6,15.5,30.3,17.1c-0.1,0.1-0.1,0.2-0.2,0.3C221.2,504.6,232.8,531.9,228.2,555.5z',
    }, {
      bodyPart: 'shoulder',
      side: 'left',
      d: 'M374.3,238.2c-2.9-26.2-18.2-45.7-39.1-53.9c-0.2-0.1-0.4-0.1-0.6-0.2c-4.4,0.4-9,1.1-14.4,1.8c-1.2,0.2-2.4,0.3-3.6,0.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.5,0.2-2.3,0.3c-2.2,0.3-4.6,0.5-7.1,0.7c-0.6,0.1-1.2,0.1-1.7,0.1c-3.5,0.3-7.4,0.5-11.8,0.6c-1.2,0-2.4,0.1-3.6,0.1c-1.8,0-3.6,0.1-5.4,0.1c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0.1c1,0.2,2.7,0.7,4.9,1.4c0.4,0.1,0.8,0.3,1.2,0.4c1.3,0.4,2.7,0.9,4.2,1.6c0.3,0.1,0.6,0.2,0.9,0.4c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.3,1,0.4c3.6,1.6,7.6,3.7,11.6,6.5c0.3,0.2,0.6,0.4,0.9,0.6c0.7,0.5,1.4,1,2.2,1.6c0.8,0.6,1.6,1.3,2.4,2c0.3,0.2,0.5,0.4,0.8,0.6c0.4,0.3,0.7,0.6,1,0.9c0.7,0.6,1.4,1.2,2,1.9c0.6,0.6,1.2,1.2,1.8,1.9c0.3,0.3,0.5,0.6,0.8,0.9c0.2,0.2,0.3,0.4,0.5,0.5c0.3,0.3,0.6,0.7,0.9,1s0.5,0.6,0.8,0.9c0.2,0.2,0.3,0.4,0.5,0.6c0.5,0.6,0.9,1.2,1.4,1.9c0.3,0.4,0.6,0.8,0.9,1.3c0.3,0.4,0.5,0.7,0.7,1.1c3.7,5.9,6.7,12.9,8.1,21.3c0,0.1,0,0.1,0,0.2l0,0c6.7,13.3,19.7,12.7,34.6,37.5C374.5,262.7,375.6,249.8,374.3,238.2z',
    }, {
      bodyPart: 'shoulder',
      side: 'right',
      d: 'M184.7,185.7c-30.9,15.4-49.4,20.7-52.5,43.6c-13.6,26.2-24.1,15.7-35.7,44.1c0,0-5.7-19.9-2.5-42.1c2.8-19.2,12.4-40.1,38.1-51.3c0.1-0.1,0.3-0.1,0.4-0.2C142.3,182.6,166.7,184.2,184.7,185.7z',
    }, {
      bodyPart: 'thigh',
      side: 'left',
      d: 'M310.3,704.3c-12.1,15.7-15.8,37.2-23.6,45c-7.9,7.9-35.6,5.2-51.6-30.2c0-12.3,0.7-25.6,0.1-41.1c-2.3-54.1-11.7-31.2-5.5-119.8c0.3-0.2,0.7-0.3,1.1-0.5c18.4-8.2,54.6-97.6,82.9-114.4c0,0,6.3,34.6,4.2,47.2C343.1,587.2,319.2,676.7,310.3,704.3z',
    }, {
      bodyPart: 'thigh',
      side: 'right',
      d: 'M220.3,670.9c1,20.5,1.2,36.6,0.9,49.4c-13.2,37.8-42.6,37.6-51.3,28.9c-8.7-8.7-10.3-37.8-22.6-53.4c-8.3-26.4-37.9-116.5-12-213.7c1-24.1,10.5-49.3,10.5-49.3v-1.2c45.4,45.6,50,103.5,74.5,122.9C229.7,599.6,225.5,625.8,220.3,670.9z',
    },
  ],
  rear: [
    {
      bodyPart: 'arm',
      side: 'left',
      d: 'M132.1,341.4c0,0.7,0,1.4,0,2.1c-4.4,12.1-6,25.5-6.7,37.8c-2.1-1.8-4.9-3.5-10.8-1.6c-23.7,7.6-33.9-30.2-37.9-54.2l0,0c0.1-1.4,0.2-2.8,0.4-4.3c1-11.2,3.4-24.2,2.6-37.9c7.5-7.5,14.5-19.5,22.1-31.4c0,0,17.7-6.9,23,0.4C114.3,275.3,141.6,296.3,132.1,341.4z',
    }, {
      bodyPart: 'arm',
      side: 'right',
      d: 'M363.6,328.3L363.6,328.3c-4.6,23.8-15,55.3-36.8,48.3c-5.5-1.8-8.3-0.4-10.3,1.2c0-2.7,0-5.4,0.2-8c-4.1-18.3-9.1-32.6-9.4-33.5l0,0c-14.7-32.5,6.3-70.3,5.2-80.8c0,0-2.8-20.5,21.7-13.4c7.8,9.8,15.1,20.6,22.3,29.1c-0.5,2.7-0.9,4.3-0.9,4.3S358.8,297.3,363.6,328.3z',
    }, {
      bodyPart: 'head',
      d: 'M312.1,181.2c-0.7,0.6-1.3,1.1-2,1.7c-0.2,0.1-0.3,0.3-0.5,0.4c-0.6,0.5-1.2,1-1.8,1.4c-0.1,0.1-0.1,0.1-0.2,0.2c-0.7,0.5-1.4,1.1-2.1,1.6s-1.5,1-2.2,1.5l-0.1,0.1c-0.7,0.4-1.3,0.9-2,1.3c-0.9,0.6-1.9,1.2-2.9,1.8c-0.7,0.4-1.5,0.8-2.2,1.3c-1,0.5-1.9,1-2.9,1.6c-0.5,0.3-1,0.5-1.6,0.8c-0.7,0.3-1.4,0.7-2.2,1c-0.5,0.2-1,0.4-1.5,0.7c-0.5,0.2-1,0.4-1.5,0.6c-0.4,0.2-0.9,0.4-1.3,0.5c-0.8,0.3-1.5,0.6-2.3,0.9c-0.8,0.3-1.5,0.6-2.3,0.8c-1,0.4-2.1,0.7-3.1,1.1c-0.8,0.3-1.6,0.5-2.4,0.7c-1,0.3-2.1,0.6-3.1,0.9c-1.8,0.5-3.6,1-5.4,1.4h-0.1c-1.7,0.4-3.3,0.7-5,1.1c-0.3,0.1-0.5,0.1-0.8,0.2c-0.9,0.2-1.8,0.3-2.6,0.5c-0.1,0-0.1,0-0.2,0c-0.9,0.2-1.8,0.3-2.8,0.5h-0.1c-0.7,0.1-1.5,0.2-2.3,0.3c-2.2,0.3-4.4,0.6-6.6,0.8c-1,0.1-1.9,0.2-2.9,0.3c-1.2,0.1-2.5,0.2-3.7,0.3c-0.6,0-1.2,0.1-1.7,0.1c-1.7,0.1-3.3,0.2-5,0.2c-0.8,0-1.5,0.1-2.3,0.1s-1.5,0-2.3,0c-1,0-2,0-3,0c-0.8,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.8,0l0,0c-1.8,0-3.6-0.1-5.4-0.2c-1.6-0.1-3.2-0.1-4.7-0.2c-1-0.1-2-0.1-3-0.2l0,0c-1.2-0.1-2.3-0.2-3.5-0.3c-0.7-0.1-1.5-0.1-2.2-0.2c-0.3,0-0.5,0-0.8-0.1c-0.4,0-0.8-0.1-1.2-0.1c-0.9-0.1-1.8-0.2-2.7-0.3c-4.6-0.5-9-1-13.5-1.6c-0.9-0.1-1.7-0.3-2.6-0.4c-3.5-0.5-7-1.1-10.3-1.7c-1-0.2-2-0.4-3-0.5c-1.1-0.2-2.2-0.4-3.3-0.6c-2.6-0.5-5.2-1-7.7-1.6c-1.2-0.3-2.4-0.5-3.6-0.8c-0.6-0.1-1.2-0.3-1.8-0.4c-0.6-0.1-1.2-0.3-1.7-0.4c-5.2-1.2-10-2.4-14.4-3.6c-1-0.3-1.9-0.5-2.9-0.8c-3.7-1-7.1-2-10.1-2.9c-0.7-0.2-1.5-0.4-2.2-0.7c-2.1-0.7-3.9-1.2-5.5-1.8c0.2-0.1,0.4-0.2,0.6-0.2c2.7-1,5.3-2.1,7.9-3.2c27.4-11.8,52.2-29.2,55-38.8c-1-7.3-1-25.2-1-25.2s0-15.7-3.1-23.1c-4.2,12.6-13.2-14.4-13.2-14.4s-4.6-27.6,8-14c-3.1-21-3.1-69.2,50.4-66.1c47.2,7.3,42,40.9,42,66.1c8.4-11.5,10.5,4.2,6,15c-4.5,10.9-5,20.6-11.3,16.4c0,19.9-7.3,26.2-7.3,26.2c0,8.4,2.1,16.8,2.1,16.8s7.2,10.4,26.5,21.7C286.1,171.1,297.6,176.5,312.1,181.2z',
    }, {
      bodyPart: 'chest',
      d: 'M334.3,250.8c-24.5-7.1-21.7,13.4-21.7,13.4c1,10.5-19.9,48.3-5.2,80.8c-4.2,4.2-10.9,20.5-11.4,38.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.4,0.1,0.6c-0.1,2.7,0,5.5,0.3,8.2c-0.7,12.1-3.5,30-15.1,36.3c-28.3,13.6-34.6,64-59.8,64s-28.3-47.2-58.8-58.8c-15.7-8.4-21.2-21.9-23.4-33.7c0.2-3.7,0.3-7.5,0.2-11.5c-0.2-10.8-1.8-22.8-7.2-36.6c0,0,0,0,0,0c0-0.7,0-1.4,0-2.1c9.4-45.1-17.8-66.1-7.3-89.2c-5.2-7.3-23-0.4-23-0.4c11-17.4,23.2-34.6,39.7-37.4c19.9-3.3,26.9-8.5,29.3-11.9c3.4,0.6,6.8,1.2,10.3,1.7c0.8,0.1,1.7,0.3,2.6,0.4c4.4,0.6,8.9,1.2,13.5,1.6c0.9,0.1,1.8,0.2,2.7,0.3c0.4,0,0.8,0.1,1.2,0.1c0.3,0,0.5,0.1,0.8,0.1c0.7,0.1,1.5,0.1,2.2,0.2c1.1,0.1,2.3,0.2,3.5,0.3c0,0,0,0,0,0c1,0.1,2,0.1,3,0.2c1.6,0.1,3.1,0.2,4.7,0.2c1.8,0.1,3.6,0.1,5.4,0.2h0c0.6,0,1.2,0,1.8,0c0.8,0,1.7,0,2.5,0c1,0,2,0,3,0c0.7,0,1.5,0,2.3,0c0.8,0,1.5,0,2.3-0.1c1.7-0.1,3.3-0.1,5-0.2c0.6,0,1.2-0.1,1.7-0.1c1.2-0.1,2.5-0.2,3.7-0.3c1-0.1,1.9-0.2,2.9-0.3c2.2-0.2,4.4-0.5,6.6-0.8c0.8-0.1,1.5-0.2,2.3-0.3c0.1,0,0.1,0,0.1,0c0.9-0.1,1.8-0.3,2.8-0.5c0.1,0,0.1,0,0.2,0c0.9-0.1,1.8-0.3,2.6-0.5c0.3-0.1,0.5-0.1,0.8-0.2c1.7-0.3,3.3-0.7,5-1.1c0.7,2.4,5.1,9.7,31.5,12C311.8,225.6,323.6,237.3,334.3,250.8z',
    }, {
      bodyPart: 'forearm',
      side: 'left',
      d: 'M124.3,416.3c-3,24-18,65.7-21.1,98.1c-13.6,7.9-28.3,3.5-36.6-0.3c1-6.8,2.9-16.5,3.6-40.5c-2.9-61.3-7.5-78.9-0.3-104.6c6.3,13.2,19.3,37.9,32.8,46.9C113.3,422.9,120.1,420.2,124.3,416.3z',
    }, {
      bodyPart: 'forearm',
      side: 'right',
      d: 'M383.3,505.1c-15.3,8.4-30.8,5.5-36.7,3.9l-0.4-2.9c-1.4-20-16.1-55.2-24.2-89.9c4.1,1.6,9.6,1.3,16.8-3.5c14.3-9.5,28-36.6,33.8-49.1c8.7,40.4,2.6,98.8,6.5,118.4C381.4,494.2,382.6,500.8,383.3,505.1z',
    }, {
      bodyPart: 'shoulder',
      side: 'left',
      d: 'M170.9,202.6c-2.4,3.4-9.4,8.5-29.3,11.9c-16.5,2.7-28.7,20-39.7,37.4c-7.6,11.9-14.6,23.9-22.1,31.4c0-0.5-0.1-1.1-0.1-1.6c-10.8-35.8-1.2-66.4,18.5-83.2c4.9-4.2,10.5-7.6,16.6-10c1.6,0.5,3.5,1.1,5.5,1.8c0.7,0.2,1.4,0.4,2.2,0.7c3,0.9,6.4,1.9,10.1,2.9c0.9,0.3,1.9,0.5,2.9,0.8c4.4,1.2,9.2,2.4,14.4,3.6c0.6,0.1,1.2,0.3,1.7,0.4c0.6,0.1,1.2,0.3,1.8,0.4c1.2,0.3,2.4,0.5,3.6,0.8c2.5,0.5,5,1.1,7.7,1.6c1.1,0.2,2.2,0.4,3.3,0.6C168.9,202.3,169.9,202.4,170.9,202.6z',
    }, {
      bodyPart: 'shoulder',
      side: 'right',
      d: 'M356.4,271.1c-7.2-8.4-14.4-19.3-22.3-29.1c-10.7-13.5-22.5-25.2-36.5-26.4c-26.4-2.4-30.8-9.6-31.5-12h0.1c1.8-0.4,3.6-0.9,5.4-1.4c1-0.3,2.1-0.6,3.1-0.9c0.8-0.2,1.6-0.5,2.4-0.7c1-0.3,2.1-0.7,3.1-1.1c0.8-0.3,1.5-0.5,2.3-0.8s1.6-0.6,2.3-0.9c0.4-0.2,0.9-0.4,1.3-0.5c0.5-0.2,1-0.4,1.5-0.6c0.5-0.2,1-0.4,1.5-0.7c0.7-0.3,1.5-0.7,2.2-1c0.5-0.3,1.1-0.5,1.6-0.8c1-0.5,2-1,2.9-1.6c0.8-0.4,1.5-0.8,2.2-1.3c1-0.6,1.9-1.2,2.9-1.8c0.7-0.4,1.3-0.8,2-1.3l0.1-0.1c0.7-0.5,1.5-1,2.2-1.5s1.4-1,2.1-1.6c0.1-0.1,0.2-0.1,0.2-0.2c0.6-0.5,1.2-1,1.8-1.4c0.2-0.1,0.3-0.3,0.5-0.4c0.7-0.6,1.3-1.1,2-1.7c1.9,0.6,3.8,1.2,5.8,1.8c4.1,1.8,7.8,3.8,11.2,6C364.1,212.3,359.1,257.1,356.4,271.1z',
    }, {
      bodyPart: 'back',
      d: 'M334.3,242c-24.5-7.1-21.7,13.4-21.7,13.4c1,10.5-19.9,48.3-5.2,80.8c-4.2,4.2-10.9,20.5-11.4,38.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.4,0.1,0.6c-0.1,2.7,0,5.5,0.3,8.2c-0.7,12.1-3.5,30-15.1,36.3c-28.3,13.6-34.6,64-59.8,64s-28.3-47.2-58.8-58.8c-15.7-8.4-21.2-21.9-23.4-33.7c0.2-3.7,0.3-7.5,0.2-11.5c-0.2-10.8-1.8-22.8-7.2-36.6l0,0c0-0.7,0-1.4,0-2.1c9.4-45.1-17.8-66.1-7.3-89.2c-5.2-7.3-23-0.4-23-0.4c11-17.4,23.2-34.6,39.7-37.4c19.9-3.3,26.9-8.5,29.3-11.9c3.4,0.6,6.8,1.2,10.3,1.7c0.8,0.1,1.7,0.3,2.6,0.4c4.4,0.6,8.9,1.2,13.5,1.6c0.9,0.1,1.8,0.2,2.7,0.3c0.4,0,0.8,0.1,1.2,0.1c0.3,0,0.5,0.1,0.8,0.1c0.7,0.1,1.5,0.1,2.2,0.2c1.1,0.1,2.3,0.2,3.5,0.3l0,0c1,0.1,2,0.1,3,0.2c1.6,0.1,3.1,0.2,4.7,0.2c1.8,0.1,3.6,0.1,5.4,0.2l0,0c0.6,0,1.2,0,1.8,0c0.8,0,1.7,0,2.5,0c1,0,2,0,3,0c0.7,0,1.5,0,2.3,0s1.5,0,2.3-0.1c1.7-0.1,3.3-0.1,5-0.2c0.6,0,1.2-0.1,1.7-0.1c1.2-0.1,2.5-0.2,3.7-0.3c1-0.1,1.9-0.2,2.9-0.3c2.2-0.2,4.4-0.5,6.6-0.8c0.8-0.1,1.5-0.2,2.3-0.3c0.1,0,0.1,0,0.1,0c0.9-0.1,1.8-0.3,2.8-0.5c0.1,0,0.1,0,0.2,0c0.9-0.1,1.8-0.3,2.6-0.5c0.3-0.1,0.5-0.1,0.8-0.2c1.7-0.3,3.3-0.7,5-1.1c0.7,2.4,5.1,9.7,31.5,12C311.8,216.8,323.6,228.5,334.3,242z',
    }, {
      bodyPart: 'elbow',
      side: 'left',
      d: 'M125.4,381.3c-0.6,11.1-0.4,21.4-0.7,29.4c-0.1,1.8-0.2,3.7-0.5,5.7c-4.1,3.8-11,6.6-21.5-0.5c-13.5-9-26.5-33.7-32.8-46.9c0.7-2.5,1.5-5,2.4-7.6c3.9-8.9,2.8-15.3,4.4-35.8c4,24,14.1,61.8,37.9,54.2C120.6,377.8,123.4,379.5,125.4,381.3z',
    }, {
      bodyPart: 'elbow',
      side: 'right',
      d: 'M372.6,363.7c-5.8,12.5-19.5,39.6-33.8,49.1c-7.2,4.8-12.7,5-16.8,3.5c-3.1-13.2-5.3-26.3-5.4-38.5c2-1.6,4.8-3,10.3-1.2c21.8,7,32.2-24.5,36.8-48.2c0.8,4.9,1.6,9.9,2.4,15.2C368.9,349.4,370.9,356.2,372.6,363.7z',
    }, {
      bodyPart: 'hand',
      side: 'left',
      d: 'M104.9,574.3c-3.2,19.9,0,38.8-6.3,38.8c-8.4,3.1-1-32.5-5.2-35.7c-5.2,1,0,55.6-10.5,51.4c-10.5,0,0-34.6-4.2-48.3c-5.2-1-2.1,58.8-12.6,55.6c-9.4,0,1-53.5-1-58.8c-7.3,4.2-4.2,48.3-14.7,47.2c-11.5-4.2,1-44.1-1-61.9c-2.1,5.2-21,16.8-23.1,4.2c0-5.2,10.5-3.1,16.8-16.8c8.4-25.2,21-29.4,23.1-29.4c0-2.2,0.2-4.3,0.6-6.7c8.3,3.9,22.9,8.2,36.6,0.3c-0.5,5.6-0.7,10.9-0.4,15.8C105.9,535.5,105.9,544.9,104.9,574.3z',
    }, {
      bodyPart: 'hand',
      side: 'right',
      d: 'M423.8,557.5c-2.1,12.6-21,1-21,1c-5.2,5.2,12.6,53.5,1,57.7c-11.5,4.2-9.4-48.3-15.7-46.2c-2.1,6.3,11.5,58.8,0,58.8s-8.4-56.7-14.7-55.6c-3.1,1,8.4,50.4-2.1,49.3c-11.5,3.1-7.3-51.4-12.6-51.4c-4.2-1,5.2,36.7-3.1,36.7c-7.3,2.1-5.2-17.8-9.4-40.9c-5.2-40.9,4.2-40.9,2.1-47.2l-1.7-10.7c5.9,1.6,21.4,4.5,36.7-3.9c0.6,3.9,0.6,5.8,0.6,8.3c19.9,12.6,19.9,22,23.1,29.4C410.1,550.2,417.4,552.3,423.8,557.5z',
    }, {
      bodyPart: 'thigh',
      side: 'left',
      d: 'M215.4,741.6c-3.5,28.9-9.8,40.5-9.8,54.1c-29.2-71.7-48.2-43.4-56-24.9c-2.2-30.1-5.9-61.2-5.9-61.2s-27.8-77.1-19.1-167.3c16.6,22.9,62,50.6,93.6,20.4c1.1-1,2.2-1.9,3.2-2.6c-0.4,0.8-0.8,1.7-1.1,2.6c6.3,89.2-1,70.3-5.4,121.4C212.9,706.6,218.1,720.1,215.4,741.6z',
    }, {
      bodyPart: 'thigh',
      side: 'right',
      d: 'M303.1,697.1c-2.9,17.6-5.9,49.6-7.4,67.3c-7.8-18.5-26.8-46.4-55.9,25.1c-0.3,0.7-0.6,1.4-0.8,2.1c-1-15.1-12.3-21.2-8.3-118.6c-5.2-44.5-9.3-89.1,0.7-118.5l0,0c0.3-0.1,0.4-0.1,0.4-0.1c43.2,28.9,72.6,15.8,90.6-32C334.1,608.3,304.9,669.5,303.1,697.1z',
    }, {
      bodyPart: 'ankle',
      side: 'left',
      d: 'M211.9,1016c0,0,0.1,0.7,0.3,2l0,0c-4.1,7.7-8.8,17.9-16.1,17.9c-11.5,0-11.5-13.6-23.1-13.6c-4.2-8.4-1-21-2.1-34.6c-0.6-5.6-1.3-10.9-2.1-16c29.3,22.9,37.9,3.7,37.9,3.7c2.5,12.8,6.2,22.4,6.2,25.9C212.9,1006.6,211.9,1016,211.9,1016z',
    }, {
      bodyPart: 'ankle',
      side: 'right',
      d: 'M265.4,1016c0,0.2,0,0.4,0.1,0.7c-7.6,4.8-14.7,21.4-25.2,21.4c-9.4,0-12.8-11.6-16.3-20c0.6-4.7,1.6-10.4,1.6-10.4s-1-18.9,5.2-28.3c2-6.1,3.2-13.4,3.8-21.5c2.4,7.3,4.2,11.3,4.2,11.3s7.2,16.2,31.2,1c-1.6,9.7-2.5,19.1-2.5,28C267.4,998.2,269.6,1007.6,265.4,1016z',
    }, {
      bodyPart: 'leg',
      side: 'left',
      d: 'M204.4,954.1c0,7.7,0.9,14.9,2.2,21.3l0,0c0,0-8.6,19.1-37.9-3.7c-6.4-40-18.9-67.4-24-118.3c-3.6-35.2,6-65.7,5.2-77.7c-0.1-1.6-0.2-3.3-0.3-4.9c7.7-18.5,26.8-46.8,56,24.9c0,18.9,8.2,34.3,8,67.4C211.9,893.3,204.4,931,204.4,954.1z',
    }, {
      bodyPart: 'leg',
      side: 'right',
      d: 'M301.1,846c-0.9,24.4-23.4,76.9-31.1,124.1c-24,15.2-31.2-1-31.2-1s-1.8-4-4.2-11.3c2.1-29.1-3.4-69-3.6-106.3c-0.1-26.3,8.2-41.2,8.2-58c0-0.7,0-1.4-0.1-2.1c0.3-0.7,0.6-1.4,0.8-2.1c29-71.4,48.1-43.6,55.9-25.1c-0.6,7.6-1,12.5-1,12.5S302.1,816.7,301.1,846z',
    }, {
      bodyPart: 'pelvis',
      side: 'left',
      d: 'M164.2,426.1c-13.1,10.2-23.7,23.5-30.4,39c1.6-12.7,3.5-24,3.5-24s-4.2-17.8,1-38.8c0-0.4,0.1-0.8,0.1-1.1c0.2-3,0.4-6.2,0.6-9.5c2.2,11.8,7.7,25.3,23.4,33.7C163.1,425.6,163.6,425.9,164.2,426.1z',
    }, {
      bodyPart: 'pelvis',
      side: 'right',
      d: 'M309.4,451.3c-7.8-12.1-18.1-22.4-30.1-30.2c0.6-0.3,1.1-0.6,1.7-0.9c11.5-6.3,14.4-24.2,15.1-36.3c0.1,1.6,0.4,3.2,0.7,4.8c0.3,1.6,0.6,3,0.9,4.2c3,13,6.5,11.2,6.5,40.9C304.3,433.8,306.8,441,309.4,451.3z',
    }, {
      bodyPart: 'buttock',
      d: 'M322.4,522.4c-18,47.8-47.4,60.9-90.6,32c0,0-0.1,0-0.4,0.1c0.1-0.4,0.2-0.8,0.4-1.1c0,0-6.7,0-10.4,6.8c-1,0.8-2.1,1.6-3.2,2.6c-23.8,22.7-55.3,12.8-76.6-3.3c-7-5.3-12.9-11.3-17-17.1c0.6-6.6,1.4-13.2,2.5-19.8c1.3-8.6,3-17.2,5.1-25.9c-1-6.2,0.2-19.5,1.7-31.6c6.7-15.4,17.3-28.8,30.4-39c28.8,12.6,32.3,58.1,57,58.1c24.7,0,31.2-48.3,58.1-63.1c12.1,7.8,22.4,18.1,30.1,30.2c2.4,9.4,4.8,21.4,5.3,32.9C318.1,497.4,320.8,510.1,322.4,522.4z',
    }, {
      bodyPart: 'foot',
      side: 'left',
      d: 'M208.6,1058c-25.2,4.2-35.7-6.3-35.7-6.3s-26.2,1-16.8-17.8c11.5-3.1,16.8-11.5,16.8-11.5c11.5,0,11.5,13.6,23.1,13.6c7.2,0,12-10.3,16.1-17.9C213.4,1025.6,216.8,1052.6,208.6,1058z',
    }, {
      bodyPart: 'foot',
      side: 'right',
      d: 'M271.6,1048.5c0,0-5.7-1.1-11-0.2c-11,4.4-27.4,6.2-33.1-0.9c-15.7-9.4-4.2-21-4.2-24.1c0-1,0.2-3.1,0.5-5.3c3.4,8.4,6.9,20,16.3,20c10.6,0,17.6-16.6,25.2-21.4c1.2,13,8.2,12.5,11.5,18.2C281.1,1042.2,271.6,1048.5,271.6,1048.5z',
    },
  ],
};

export default paths;
