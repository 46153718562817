import { Skeleton } from '@material-ui/lab'
import React, { useState } from 'react'
import styled from 'styled-components'

const ImageSkeleton = ({ src, height, onError }) => {
  const [loading, setLoading] = useState(true)

  return (
    <>
      <Wrapper style={{ minHeight: height }}>
        {loading && (
          <Skeleton
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            component='div'
            variant='rect'
          />
        )}
        <Image src={src} alt={src} onLoad={() => setLoading(false)} onError={onError} />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`

export default ImageSkeleton
