import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Radar } from 'react-chartjs-2';

const RadarChart = ({ data, t }) => {
  const socials = [
    {
      id: 'ig',
      label: 'Instagram',
      colors: {
        borderColor: { main: '#9965ff', hover: '#7b52cd' },
        backgroundColor: { main: '#ebe0ff80', hover: '#9965ff' },
      },
    }, {
      id: 'tw',
      label: 'X',
      colors: {
        borderColor: { main: '#36a2eb', hover: '#2b87c5' },
        backgroundColor: { main: '#d6ecfb80', hover: '#36a2eb' },
      },
    }, {
      id: 'rss',
      label: t('web_article'),
      colors: {
        borderColor: { main: '#fdb976', hover: '#fc6500' },
        backgroundColor: { main: '#feecd980', hover: '#fc6500' },
      },
    },
  ];
  const findFor = keyToFind => ({ key }) => key === keyToFind;

  const radarOptions = {
    legend: { display: true },
    scale: {
      ticks: {
        display: true,
        stepSize: 20,
        callback: label => `${label}%`,
      },
    },
  };

  const dataSet = data && {
    labels: [
      t('stats_neutral'),
      t('stats_barely_positive'),
      t('stats_positive'),
      t('stats_very_positive'),
      t('stats_very_negative'),
      t('stats_negative'),
      t('stats_barely_negative'),
    ],
    datasets: socials.map(({ id, label, colors: { borderColor, backgroundColor } }) => ({
      data: [
        data.find(findFor('neutral')).partials[id],
        data.find(findFor('barelygood')).partials[id],
        data.find(findFor('good')).partials[id],
        data.find(findFor('verygood')).partials[id],
        data.find(findFor('verybad')).partials[id],
        data.find(findFor('bad')).partials[id],
        data.find(findFor('barelybad')).partials[id],
      ],
      label,
      borderWidth: 1,
      borderColor: borderColor.main,
      backgroundColor: backgroundColor.main,
      hoverBorderColor: borderColor.hover,
      hoverBackgroundColor: backgroundColor.hover,
      hoverBorderWidth: 1,
      stack: 1,
    })),
  };

  return (
    <div className="doughnut-wrapper">
      <h2 className="donut-title">{t('social-media-risk')}</h2>
      {dataSet
        ? <Radar data={dataSet} options={radarOptions} />
        : <div className="no-stats">{t('stats_not_available')}</div>
      }
    </div>
  );
};

RadarChart.propTypes = {
  data: PropTypes.oneOfType(
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.bool,
  ),
  t: PropTypes.func.isRequired,
};

RadarChart.defaultProps = {
  data: false,
};

export default withTranslation('translation')(RadarChart);
