import React from 'react'
import Column from './Column'


class Panel extends React.Component {
  render() {
    const {
      type,
      color,
      children,
      style,
    } = this.props
    const baseStyle = {
      marginLeft: '50px',
      background: color,
    }
    return (
        <Column style={{ ...style, ...baseStyle }} className={type}>
          {children}
        </Column>
    )
  }
}
export default Panel
