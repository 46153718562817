import React from 'react'

const baseStyle = {
  display: 'flex',
  flexDirection: 'row',
}

const Row = (props = {}) => {
  const {
    children,
    style,
    className,
    // key,
  } = props

  return (
    <div style={{ ...style, ...baseStyle }} className={className}>
      {children}
    </div>
  )
}


Row.defaultProps = {
  children: null,
  style: {},
}

export default Row
