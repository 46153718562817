import axios from 'axios';
import { BASE_URL } from '../../api';

import { get, post, remove } from './constants';

/**
 * Get Read List
 */
const getReadListRequest = () => ({ type: get.request });
const getReadListFailure = error => ({ type: get.failure, error });
const getReadListSuccess = data => ({ type: get.success, data });

const getReadList = () => async (dispatch) => {
  dispatch(getReadListRequest());

  try {
    const { data } = await axios({
      method: 'get',
      url: `${BASE_URL}/readlist/livestream`,
    });

    dispatch(getReadListSuccess(data));
  } catch (error) {
    dispatch(getReadListFailure(error));
  }
};

/**
 * Post in read list
 */
const postReadListRequest = () => ({ type: post.request });
const postReadListFailure = error => ({
  type: post.failure,
  error,
});
const postReadListSuccess = data => ({ type: post.success, data });

const postReadList = id => async (dispatch) => {
  dispatch(postReadListRequest());

  try {
    await axios({
      method: 'post',
      url: `${BASE_URL}/readlist/${id}`,
    });

    dispatch(postReadListSuccess(id));
    dispatch(getReadList());
  } catch ({ response }) {
    dispatch(postReadListFailure(response));
  }
};


/**
 * Delete from read list
 */
const removeReadListRequest = () => ({ type: remove.request });
const removeReadListFailure = error => ({ type: remove.failure, error });
const removeReadListSuccess = data => ({ type: remove.success, data });

const removeReadList = id => async (dispatch) => {
  dispatch(removeReadListRequest());

  try {
    await axios({
      method: 'delete',
      url: `${BASE_URL}/readlist/${id}`,
    });

    dispatch(removeReadListSuccess(id));
    dispatch(getReadList());
  } catch (error) {
    dispatch(removeReadListFailure(error));
  }
};

export { getReadList, postReadList, removeReadList };
