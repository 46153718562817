import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { saveExpiryDate } from '../../helpers'
import { setAuthToken } from '../../redux/ducks/auth'
import { handleLogout } from '../../api/auth'
import { getUserInfo } from '../../api/profile'
import Analytics from '../../Analytics'
import Dropdown, { DropdownItem } from './Dropdown'
import Dialog from '../Dialog'
import styled from 'styled-components'
import { cssvar } from '../../styles/var'

import QRCode from 'qrcode.react'
import googlePlayBadge from '../../images/google-play-badge.png'
import appleStoreBadge from '../../images/apple-store-badge.png'
import tutorialApp from '../../images/tutorial-qrcode.png'

const Profile = ({ auth, saveAuthToken }) => {
  const { t } = useTranslation()
  const [QRDialog, setQRDialog] = useState(false)

  useEffect(() => {
    getUserInfo()
      .then((userInfo) => {
        saveAuthToken(userInfo, 'userInfo')

        if (userInfo) {
          const {
            access_token_expires_on: accessTokenExpiresOn,
            user_id: userId,
            plan_name: planName,
            email,
            name
          } = userInfo
          saveExpiryDate(accessTokenExpiresOn)
          Analytics.start({ email, name, userId, planName })
        }
      })
      .catch(error => Promise.reject(error))
  }, [])

  const toggleQRDialog = () => {
    setQRDialog(!QRDialog)
  }
  const logout = () => {
    handleLogout().then(res => { console.log('logout res', res) })
  }

  const { userInfo = {} } = auth
  const { first_name, last_name, mobile_auth } = userInfo
  
  return (
    <Fragment>
      <Dropdown rightAligned trigger={ <Avatar>{ _.first(first_name) }{ _.first(last_name) }</Avatar> }>
        <DropdownItem onClick={ () => toggleQRDialog() }>
          {t('Download app')}
        </DropdownItem>
        <DropdownItem>
          <a href="mailto:customercare@noisefeed.com" target="_blank" rel="noreferrer">
          {t('Customer care')}
          </a>
        </DropdownItem>
        <DropdownItem onClick={ () => logout() }>{ t('logout') }</DropdownItem>
      </Dropdown>
      
      <Dialog
        open={ QRDialog }
        title={t('Download app')}
        onClose={ () => setQRDialog(false) }>
        <QRDialogContent>
          <p>{ t('qrcode-tutorial-1') }</p>
          <AppStoreLinks>
            <a href="https://apps.apple.com/it/app/noisefeed/id1196780096" target="_blank" rel="noopener noreferrer">
              <img alt="App Store" src={ appleStoreBadge } height="50" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.appfactory.noisefeed&hl=it" target="_blank" rel="noopener noreferrer">
              <img alt="Google Play" src={ googlePlayBadge } height="50" />
            </a>
          </AppStoreLinks>
          <p>{ t('qrcode-tutorial-2') }</p>
          <img alt="Mobile app QRCode login tutorial" src={ tutorialApp } width="250" />
          <p>{ t('qrcode-tutorial-3') }</p>
          <QRCode value={ mobile_auth } />
        </QRDialogContent>
      </Dialog>
    </Fragment>
  )
}

const mapStateToProps = ({ auth }) => ({ auth })

const mapDispatchToProps = dispatch => ({
  saveAuthToken: (auth, id) => {
    const action = setAuthToken({ data: auth, id })
    dispatch(action)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)

const Avatar = styled.div`
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: rgba(255,255,255, .2);
  border-radius: 4px;
  color: white;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 640px) {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
`
const QRDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  color: ${ cssvar('mainColor') };
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  p {
    margin-top: 30px;
    margin-bottom: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
`
const AppStoreLinks = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
`