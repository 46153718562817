import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from '../Dialog'
import styled, { css } from 'styled-components/macro'
import { cssvar } from '../../styles/var'


const FavouritesModal = ({ details = {}, currentTag, cancel, update, remove, favourited, tag }) => {
  const { t } = useTranslation()
  const [digest, setDigest] = useState('no')
  const [push, setPush] = useState('no')

  const digestOptions = ['all', 'no']
  const pushOptions = ['all', 'posts', 'mentions', 'no']

  useEffect(() => {
    setDigest(details.digest || 'no')
    setPush(details.push || 'no')
  }, [details])

  return (
    <Dialog
      open={ !!currentTag }
      title={ t('follow-dialog-title') }
      subtitle={ t('follow-dialog-desc') }
      onClose={ cancel }
      footer={
        <Buttons>
          { favourited ? <Button red onClick={ () => remove(tag, { hashtagDelete: false }) }>{ t('follow-action-unfollow') }</Button> : null }
          <Button onClick={ () => update(tag, { digest, push }) }>{ t('follow-action-save') }</Button>
        </Buttons>
      }>
      <SubTypeTitle>{ t('follow-digest-label') }</SubTypeTitle>
      { digestOptions.map(digestItem => (
        <SubOption
          key={ digestItem }
          selected={ digest === digestItem }
          onClick={ () => setDigest(digestItem) }>
          { t(`follow-digest-${digestItem}`) }
        </SubOption>
      )) }
      <SubTypeTitle>{ t('follow-push-label') }</SubTypeTitle>
      { pushOptions.map(pushItem => (
        <SubOption
          key={ pushItem }
          selected={ push === pushItem }
          onClick={ () => setPush(pushItem) }>
          { t(`follow-push-${pushItem}`) }
        </SubOption>
      )) }
    </Dialog>
  )
}

export default FavouritesModal

export const SubTypeTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 15px;
  &:first-child {
    margin-top: 0;
  }
`
export const SubOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 480;
  transition: .4s;
  cursor: pointer;
  &:before {
    content: '';
    flex: 0 0 auto;
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 10px;
    background-color: #fff;
    border: 2px solid #fff;
    box-shadow: 0 0 0 2px #c4d9e6;
    border-radius: 50%;
    box-sizing: content-box;
    transition: .4s;
    ${ props => props.selected && css`
      background-color: ${ cssvar('primaryColor') };
      box-shadow: 0 0 0 2px ${ cssvar('primaryColor') };
  ` }
  }
`
export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
export const Button = styled.div`
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  background-color: ${ props => cssvar(props.red ? 'injuryColor' : 'primaryColor') };
  border-radius: 4px;
  transition: .3s;
  cursor: pointer;
  &:hover {
    background-color: ${ props => props.red ? '#a92518' : cssvar('primaryHoverColor') };
  }
`