import React from 'react'
import ReactSVG from 'react-svg'
import WorldIcon from '../../images/World.svg'
import missingImage from '../../images/Blue_World_icon.png'
import Africa from '../../images/Africa.svg'
import Asia from '../../images/Asia.svg'
import AmericaSud from '../../images/AmericaSud.svg'
import AmericaNord from '../../images/AmericaNord.svg'
import Europa from '../../images/Europa.svg'
import Oceania from '../../images/Oceania.svg'


const ProfilePicture = (props = {}) => {
  const { image, style, label, isHashtag, isFulltext } = props
  
  const customs = {
    Europe: Europa,
    Africa,
    'South America': AmericaSud,
    'N/C America': AmericaNord,
    Asia,
    Oceania,
    World: WorldIcon
  }
  const img = customs[label] ? customs[label] : image

  return (
    img
      ? img === customs[label]
        ? (
          <ReactSVG
            className='custom-icon'
            src={ customs[label] } />
        )
        : isHashtag || isFulltext ? (
          <ReactSVG
            className="profile-icon"
            src={WorldIcon} />
          )
          : (
        <img
          className='profile-image'
          alt=""
          style={ style }
          src={ img }
          onError={
            e => {
              e.target.onerror = null
              e.target.src = missingImage
            }
          } />
          )
      : (
      <ReactSVG
        className="profile-icon"
        src={ WorldIcon } />
      )
  )
}

export default ProfilePicture
