import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { cssvar } from '../../../styles/var'

const VideoItemList = ({ title, description, meta, selected, onClick }) => {
  return (
    <Item selected={ selected } onClick={ onClick }>
      <Title>{ title }</Title>
      <Description>{ description }</Description>
      <Meta>{ meta }</Meta>
    </Item>
  )
}

VideoItemList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func
}

VideoItemList.defaultProps = {
  title: '',
  description: '',
  meta: '',
  selected: false,
  onClick: () => {}
}

export default VideoItemList

const Item = styled.div`
  padding: 15px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: .3s;
  position: relative;
  &:last-child {
    border-bottom: none;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    background-color: ${ cssvar('mainColor') };
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: width .3s;
  }
  &:hover {
    background-color: ${ cssvar('hoverColor') };
    &:before {
      width: .33em;
    }
  }
  ${ props => props.selected && css`
    color: #fff;
    pointer-events: none;
    &:before {
      width: 100%;
    }
  ` }
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 550;
`
const Description = styled.div`
  margin-top: 3px;
`
const Meta = styled.div`
  margin-top: 1em;
  font-size: 12px;
  color: rgba(255,255,255 .6);
`
