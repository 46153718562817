const GET_FAVOURITES_REQUEST = 'getFavourites/REQUEST';
const GET_FAVOURITES_SUCCESS = 'getFavourites/SUCCESS';
const GET_FAVOURITES_FAILURE = 'getFavourites/FAILURE';
const POST_FAVOURITES_REQUEST = 'postFavourites/REQUEST';
const POST_FAVOURITES_SUCCESS = 'postFavourites/SUCCESS';
const POST_FAVOURITES_FAILURE = 'postFavourites/FAILURE';
const DELETE_FAVOURITES_REQUEST = 'deleteFavourites/REQUEST';
const DELETE_FAVOURITES_SUCCESS = 'deleteFavourites/SUCCESS';
const DELETE_FAVOURITES_FAILURE = 'deleteFavourites/FAILURE';

const get = {
  request: GET_FAVOURITES_REQUEST,
  success: GET_FAVOURITES_SUCCESS,
  failure: GET_FAVOURITES_FAILURE,
};

const post = {
  request: POST_FAVOURITES_REQUEST,
  success: POST_FAVOURITES_SUCCESS,
  failure: POST_FAVOURITES_FAILURE,
};

const remove = {
  request: DELETE_FAVOURITES_REQUEST,
  success: DELETE_FAVOURITES_SUCCESS,
  failure: DELETE_FAVOURITES_FAILURE,
};

export { get, post, remove };
