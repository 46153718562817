import React from 'react'
import { Translation } from 'react-i18next'

const T = (props = {}) => {
  const { i18nKey, uppercase } = props
  return (
      <Translation>
        { t => uppercase ? t(i18nKey).toUpperCase() : t(i18nKey) }
      </Translation>
  )
}

export default T