import {
  GET_NAV_DRILLDOWN_HEADER_REQUEST,
  GET_NAV_DRILLDOWN_HEADER_SUCCESS,
  GET_NAV_DRILLDOWN_HEADER_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  error: false,
  data: { World: { name: 'World' } },
};

const navDrilldown = (
  state = initialState,
  { type, navDrilldownHeader, error },
) => {
  switch (type) {
    case GET_NAV_DRILLDOWN_HEADER_REQUEST:
      return {
        loading: true,
        error: false,
        data: state.data,
      };

    case GET_NAV_DRILLDOWN_HEADER_SUCCESS:
      return {
        loading: false,
        error: false,
        data: navDrilldownHeader,
      };

    case GET_NAV_DRILLDOWN_HEADER_FAILURE:
      return {
        loading: false,
        error,
        data: state.data,
      };

    default:
      return state;
  }
};

export default navDrilldown;
