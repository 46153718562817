import React from 'react'
import TimeframeSelector from '../Toolbar/TimeframeSelector'
import styled from 'styled-components'

const MobileTimeframeSelector = () => {
  return (
    <Wrapper>
      <TimeframeSelector />
    </Wrapper>
  )
}

export default MobileTimeframeSelector

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  @media (min-width: 1025px) {
    display: none;
  }
`