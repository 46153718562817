import React from 'react'
import { Slider, Tooltip } from 'antd'
import ReactSVG from 'react-svg'
import Up from '../../../images/ThumbUp.svg'
import Down from '../../../images/ThumbDown.svg'
import styled from 'styled-components'

class SentimentIndicator extends React.Component {
  render() {
    const { data } = this.props
    const value = (data * 20).toFixed(1)
    const s = value > 0 ? '+' : ''
    return (
        <Wrapper>
          <Tooltip title='Very Bad'>
            <ReactSVG className="sentiment-thumb-down" src={ Down } />
          </Tooltip>
          <Slider
            tipFormatter={ () => `Sentiment Analysis: ${s}${value}%` }
            min={ -100 }
            max={ 100 }
            defaultValue={ value }
            disabled />
          <Tooltip title='Very Good'>
            <ReactSVG className="sentiment-thumb-up" src={ Up } />
          </Tooltip>
        </Wrapper>
    )
  }
}
export default SentimentIndicator

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .sentiment-thumb-up, .sentiment-thumb-down {
    width: 16px;
    height: 16px;
  }
  .sentiment-thumb-up svg path {
    fill: #00d32d
  }
  .sentiment-thumb-down svg path {
    fill: #e8004d
  }
  .ant-slider-disabled {
    width: 110px;
    margin: 0;
    cursor: default;
  }
  .ant-slider-rail {
    height: 5px;
    background: linear-gradient(to right, #ff0351, #ffe300, #00d32d);
  }
  .ant-slider-handle {
    border-color: #052533 !important;
    cursor: default;
  }
  .ant-slider-track {
    background-color: transparent !important;
  }
`
