import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router-dom'
import i18n from './i18n/index'
import history from './history'
import * as serviceWorker from './serviceWorker'
import store from './redux/index'
import App from './App'

import './styles/index.css'
import Analytics from './Analytics'

Analytics.init()
Analytics.start()

window.store = store

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Router>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
