import React, { useEffect, useState } from 'react'
import Feed from './Feed'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { cssvar } from '../../../styles/var'

const NewsFeed = ({
  isReadListPage,
  isFavourite,
  isCrossFeed,
  fulltext,
  tag,
  name,
  hashtag,
  language,
}) => {
  const feedProps = {
    isReadListPage,
    isFavourite,
    isCrossFeed,
    fulltext,
    tag,
    name,
    hashtag,
    language,
  }
  const [singleColumnOriginal, setSingleColumnOriginal] = useState(1)

  const { t } = useTranslation()

  const singleColumn = !(isFavourite || tag)

  const [showTwitterObsoleteBanner, setShowTwitterObsoleteBanner] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setShowTwitterObsoleteBanner(false)
    }, 5000)
  }, [])

  return (
    <>
      {showTwitterObsoleteBanner && (
        <Alert singleColumn={singleColumn}>{t('twitter-obsolete-banner')}</Alert>
      )}
      <FeedWrapper singleColumn={singleColumn}>
        <Feed
          {...feedProps}
          original={singleColumnOriginal}
          setSingleColumnOriginal={setSingleColumnOriginal}
        />
        {!singleColumn && (
          <Feed {...feedProps} original={singleColumnOriginal === 1 ? 2 : 1} onlyLargeScreens />
        )}
      </FeedWrapper>
    </>
  )
}

export default NewsFeed

const FeedWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.singleColumn ? 'minmax(min-content, 800px)' : '1fr 1fr')};
  justify-content: center;
  gap: 15px;
  height: 100%;
  padding: 15px;
  @media (max-width: 1200px) {
    grid-template-columns: minmax(min-content, 800px);
  }
  @media (max-width: 800px) {
    padding: 0;
  }
`

const Alert = styled.div`
  min-height: 35px;
  max-width: ${props => (props.singleColumn ? '800px;' : 'none;')}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: ${cssvar('mainColor')};
  border: 1px solid ${cssvar('borderColor')};
  border-radius: 8px;
  margin: ${props => (props.singleColumn ? '0 auto;' : '0 15px;')};
  margin-top: 15px;
  padding: 8px;
  font-size: 0.7rem;
  text-align: center;
  @media (max-width: 640px) {
    justify-content: center;
    margin: 0px;
    font-size: 0.6rem;
    padding: 8px 16px;
    border-radius: 0px;
  }
`
