import React from 'react'
import star from '../../images/Favourite_Full.svg'
import T from '../Generic/T'
import { DetailContainer, MainInfo, Image, NameAndDesc, Name } from './Detail'

class FavouriteDetail extends React.Component {
  render() {
    return (
      <DetailContainer>
        <MainInfo>
          <Image src={ star } />
          <NameAndDesc>
            <Name><T i18nKey='favourites' /></Name>
          </NameAndDesc>
        </MainInfo>
      </DetailContainer>
    )
  }
}
export default FavouriteDetail
