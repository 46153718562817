import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import severityColors from './severityColors'
import sidesPaths from './sidesPaths'
import styled, { css } from 'styled-components'

/**
 * Utils
 */
const getDecimalColor = hexColor => (
  /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
    .exec(hexColor)
    .reduce((accumulator, value, index) => (
      index > 0 && index < 4
        ? [...accumulator, Math.floor(parseInt(value, 16))]
        : accumulator
    ), [])
)

const getColor = (count, gradient) => {
  const lastSeverityColor = severityColors[severityColors.length - 1]

  if (gradient) {
    const percentage = Math.min(count, 101) / 101
    const min = getDecimalColor(severityColors[0])
    const max = getDecimalColor(lastSeverityColor)

    return `rgb(${min.map((minPrimaryColor, index) => parseInt((max[index] - minPrimaryColor) * percentage + minPrimaryColor, 10)).join(',')})`
  }

  return (severityColors.find(({ max }) => count <= max) || lastSeverityColor).color
}

/**
 * Component
 */
const BodyImage = ({ dataFilters, side, onClick, heatMap, gradientColor }) => {

  const handleClickAway = () => { onClick(null) }
  const handlePathClick = (part, partSide) => (event) => {
    event.stopPropagation()
    onClick(isEqual(dataFilters, { part, side: partSide }) ? null : { part, side: partSide })
  }

  const renderPath = ({ bodyPart, side: partSide = '', d }) => {
    const selector = `${bodyPart}-${partSide}`
    const partHeatMap = heatMap[selector.toLowerCase()]
    const available = partHeatMap && partHeatMap > 0 && getColor(partHeatMap, gradientColor)

    return (
      <Path
        key={ selector }
        d={ d }
        onClick={ available ? handlePathClick(bodyPart, partSide) : () => {} }
        available={ available }
        inactive={ dataFilters && selector !== `${dataFilters.part}-${dataFilters.side}` } />
    )
  }

  return (
    <svg
      version="1.1"
      viewBox="0 0 430 1100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      onClick={ handleClickAway }>
      { sidesPaths[side].map(renderPath) }
    </svg>
  )
}

BodyImage.propTypes = {
  dataFilters: PropTypes.shape(),
  side: PropTypes.oneOf(['front', 'rear']),
  onClick: PropTypes.func,
  heatMap: PropTypes.shape(),
  gradientColor: PropTypes.bool
}

BodyImage.defaultProps = {
  dataFilters: null,
  side: 'front',
  onClick: () => {},
  heatMap: {},
  gradientColor: false
}

export default BodyImage

const Path = styled.path`
  ${ ({ available, inactive }) => css`
    fill: ${ available || '#EAEAEA' };
    opacity: ${ inactive ? .4 : 1 };
    cursor: ${ available ? 'pointer' : 'default' };
    transition: .3s;
    ${ available && inactive && css`
      &:hover {
        opacity: 1;
      }
    ` }
  ` }
`