import React from 'react'
import { withTranslation } from 'react-i18next'
import { Form, Input } from 'antd'
import { handleLogin } from '../../api/auth'
import { browserDetect } from '../../helpers'
import history from '../../history'
import Analytics from '../../Analytics'
import LoginPage, { Message, FormStyled, FormTitle, FormBody, FormItem, FormLabel, Submit } from './LoginPage'
import styled from 'styled-components'

const client_id = '123'
const client_secret = '456'

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class LoginForm extends React.Component {
  state = {
    authenticated: false,
    submitted: false,
    authError: ''
  }

  componentDidMount() {
    // const { form } = this.props
    const isChrome = browserDetect();
    this.setState({
      infoVisible: !isChrome,
    });
    Analytics.pageView('/login');

    // To disabled submit button at the beginning.
    // form.validateFields()
  }

  componentDidUpdate(prevProps) {
    const { t, form } = this.props
    // dirty hack for errors' translation update
    if (prevProps.t !== t) {
      if (form.getFieldValue('username') === '' || !form.getFieldValue('username')) {
        form.setFieldsValue({ username: ' ' })
        form.setFieldsValue({ username: '' })
        form.validateFields(['username'])
      }
      if (form.getFieldValue('password') === '' || !form.getFieldValue('password')) {
        form.setFieldsValue({ password: ' ' })
        form.setFieldsValue({ password: '' })
        form.validateFields(['password'])
      }
    }
  }


  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const params = { ...values, client_id, client_secret }
        handleLogin(params)
          .then((res) => {
            if (res.status === 200) {
              this.setState({
                authenticated: true,
                submitted: true
              })
              // setAuthToken(res)
              history.push('/')
            }
          })
          .catch((error) => {
            console.log('LoginForm HandleLogin() catched error: ', error)
            let authError = 'login_error'

            if (error.error === 'invalid_grant') {
              authError = 'Invalid_grant'
            }

            if (error.message === 'PAYMENT REQUIRED!') {
              authError = 'subscription_expired'
            }

            this.setState({
              submitted: true,
              authenticated: false,
              authError
            })
          })
      }
    })
  }

  render() {
    const { form, t } = this.props
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = form
    const { authenticated, submitted, infoVisible, authError } = this.state
    const { search } = window.location
    const params = new URLSearchParams(search)
    const successMessage = params.get('success_msg')

    // Only show error after a field is touched.
    const userNameError = isFieldTouched('username') && getFieldError('username')
    const passwordError = isFieldTouched('password') && getFieldError('password')
    return (
      <LoginPage>

        <Message success show={ successMessage }>{ t(successMessage) }</Message>
        <Message show={ !authenticated && submitted }>{ t(authError) }</Message>

        <FormStyled layout="inline" onSubmit={ this.handleSubmit }>
          <FormTitle>{ t('login_sign_in') }</FormTitle>
          <FormBody>

            <FormItem validateStatus={ userNameError && 'error' }>
              <FormLabel>{ t('username') }</FormLabel>
              { getFieldDecorator('username', { rules: [{ required: true, message: t('username_required') }] })(
                <Input />
              ) }
            </FormItem>

            <FormItem validateStatus={ passwordError && 'error' }>
              <FormLabel>{ t('password') }</FormLabel>
              { getFieldDecorator('password', { rules: [{ required: true, message: t('password_required') }] })(
                <Input.Password />
              ) }
            </FormItem>

            <Submit type="submit" disabled={ hasErrors(getFieldsError()) }>
              { t('login_login_btn') }
            </Submit>

          </FormBody>
          <Links>
            <a href="/register">{ t('new_user_register_now') }</a>
            <a href="/recovery-password">{ t('login_lost_pwd') }</a>
          </Links>
        </FormStyled>
        { infoVisible && (
          <LoginInfo>
            { t('login_suggestion') }<strong>{ t('login_suggestion_browser') }</strong>
            <br />
            { t('login_customer_care') }<a href="mailto:customercare@noisefeed.com">{ t('customer_care') }</a>
          </LoginInfo>
        ) }
      </LoginPage>
    )
  }
}

const WrappedLogin = Form.create()(LoginForm)

export default withTranslation('translation')(WrappedLogin)

const Links = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  a {
    color: #3cc0f2;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
`
const LoginInfo = styled.div`
  margin-top: 30px;
  color: #fff;
  text-align: center;
  line-height: 2;
  a {
    color: #fff;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-device-width: 1024px) {
    display: none;
  }
`