import React, { useState, useEffect, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { BASE_URL } from '../../../api'
import styled from 'styled-components/macro'

const SearchContext = createContext()

const SearchProvider = ({ children }) => {
  const searchState = useState()
  const [search] = searchState
  const [searchResult, setSearchResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [homeSearch, setHomeSearch] = useState(true)

  useEffect(() => {
    if (!search) {
      setHomeSearch(true)
    } else {
      setHomeSearch(false)
    }
  }, [search])

  useEffect(() => {
    const fetchSearch = async () => {
      const { data } = await axios({
        url: `${BASE_URL}/autocomplete`,
        method: 'get',
        params: { q: search }
      })
      setLoading(false)
       setSearchResult(
         data.filter(
           ({ _type }) =>
             _.includes(['player', 'team', 'competition', 'national_team'], _type) ||
             _.includes(_type, 'x_') // For including extras too
         )
       )
    }

    if (search) {
      setLoading(true)
      fetchSearch()
    } 
  }, [search])

  return (
    <SearchContext.Provider
      value={{
        searchState,
        homeSearch,
        searchResult,
        setSearchResult,
        loading
      }}>
      <SearchWrapper>
        { children }
      </SearchWrapper>
    </SearchContext.Provider>
  )
}

const useSearchContext = () => useContext(SearchContext)

SearchProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export { SearchContext, SearchProvider, useSearchContext }

const SearchWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 30px;
  background-color: #fff;
  overflow: scroll;
  @media (max-width: 640px) {
    min-height: 75vh;
    padding-top: 40px;
    padding-bottom: 20px;
  }
`