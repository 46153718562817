import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'

const colors = [cssvar('primaryColor'), cssvar('mainColor'), '#DB5461', '#F4A261', '#2A9D8F', '#E76F51', '#9F86C0', '#CA054D']

const pies = (data, dataKey) => {
  const totalDataItems = _.sumBy(data, dataKey)
  let sector = 0
  return data.map((pie, index) => {
    const percent = Math.ceil(pie[dataKey] / totalDataItems * 100)
    sector = sector + percent
    return `${ colors[index] } 0 ${sector}%`
  }).join(', ')
}

const PieChart = ({ data, dataKey, nameKey }) => {
  return (
    <Wrapper>
      <ChartWrapper>
        <Chart value={ pies(data, dataKey) }>
          <Hole />
        </Chart>
      </ChartWrapper>
      <LegendWrapper>
        { data.map( (legend, index) => (
          <LegendItem key={ legend[nameKey] } color={ colors[index] }>
            <LegendName>{ legend[nameKey] }</LegendName>
            <LegendValue>{ legend[dataKey] }</LegendValue>
          </LegendItem>
         ) ) }
      </LegendWrapper>
    </Wrapper>
  )
}

export default PieChart

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
`
const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const Chart = styled.div`
  width: 100%;
  max-width: 200px;
  border-radius: 50%;
  background: conic-gradient(${ props => props.value });
  position: relative;
  &:before {
    content: '';
    float: left;
    padding-bottom: 100%;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  @media (max-width: 640px) {
    max-width: 175px;
  }
`
const Hole = styled.div`
  width: 95%;
  height: 95%;
  border: 5px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  @media (max-width: 640px) {
    border-width: 3px;
  }
`
const LegendWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 15px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`
const LegendItem = styled.div`
  display: contents;
  color: ${ props => props.color };
`
const LegendName = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  @media (max-width: 640px) {
    font-size: 16px;
  }
`
const LegendValue = styled.div`
  margin-top: 2px;
  font-weight: 600;
  font-size: 24px;
  text-align: right;
  line-height: 1;
  white-space: nowrap;
  @media (max-width: 640px) {
    font-size: 20px;
  }
`