const variables = {
  'mainColor': '#052533',
  'primaryColor': '#3F8CCA',
  'primaryHoverColor': '#2E72A8',
  'oldPrimaryColor': '#13BEE8',
  'secondaryTextColor': '#576670',
  'background': '#F7F7F7',
  'borderColor': '#E6E6E6',
  'hoverColor': '#F0F8FB',
  'injuryColor': '#C72E1F'
}

export const cssvar = (prop) => {
  return variables[prop]
}