import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createEnquiry } from '../../../api/enquiries'
import Dialog from '../../Dialog'
import { Item, ItemTitle, ItemValue } from './Overall'
import styled, { css } from 'styled-components'
import { cssvar } from '../../../styles/var'

export const CoverageStatus = ({ status, progress, playerId, playerName }) => {
  const { t } = useTranslation()
  const [dialog, setDialog] = useState(false)
  const [error, setError] = useState(true)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [localCoverageProgess, setLocalCoverageProgess] = useState(progress)

  const handleReqClick = (playerId) => {
    setConfirmDialog(false)
    createEnquiry(playerId)
      .then(res => {
        if (res.status === 429) {
          setDialog(true)
          setError(true)
          return
        }
        setError(false)
        setDialog(true)
        setLocalCoverageProgess(true)
      })
  }

  return (
    <Item>
      <ItemTitle>{ t(status) }</ItemTitle>
      <ItemValue>
        <CoverageStatusContainer>
          <FeedbackBadge status={ status } />
          { status !== 'fully_covered' && (
            <RequestLink
              status={ status }
              disabled={ localCoverageProgess }
              onClick={ () => !localCoverageProgess ? setConfirmDialog(true) : null }>
              { localCoverageProgess ? t('analysis-in-progress') : t('request-analysis') }
            </RequestLink>
          ) }
        </CoverageStatusContainer>
      </ItemValue>
      <Dialog
        open={ dialog }
        title={ error ? t('limit-reached') : t('analysis-in-progress') }
        onClose={ () => setDialog(false) }>
        <DialogText>
          { error ? t('enquiry-request-error') : t('enquiry-request-feedback') }
        </DialogText>
      </Dialog>
      <Dialog
        open={ confirmDialog }
        title={ t('request-analysis') }
        onClose={ () => setConfirmDialog(false) }
        footer={
          <Buttons>
            <Button onClick={ () => handleReqClick(playerId) }>{ t('confirm-request-yes') }</Button>
            <Button cancel onClick={ () => setConfirmDialog(false) }>{ t('confirm-request-cancel') }</Button>
          </Buttons>
        }>
        <DialogText>
          { t('confirm-request') } <b>{ playerName }</b>?
        </DialogText>
      </Dialog>
    </Item>
  )
}

export default CoverageStatus

const colors = {
  fully_covered: '#3ed771',
  partially_covered: '#cd8300',
  uncovered: cssvar('injuryColor'),
}

const CoverageStatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  @media (max-width: 640px) {
    justify-content: flex-end;
    margin: 0;
  }
`

const FeedbackBadge = styled.div`
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${ props => colors[props.status] };
`

const RequestLink = styled.div`
  flex: 1 1 auto;
  font-size: 14px;
  text-transform: none;
  border-bottom: ${({ disabled, status }) => disabled ? `none` : `1px dashed ${colors[status]}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${ props => (props.disabled ? 'auto' : 'pointer')};
  &&& {
    color: ${ props => colors[props.status] };
  }
`

const DialogText = styled.div`
  color: ${ cssvar('mainColor') };
  text-align: center;
  white-space: normal;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
const Button = styled.div`
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  background-color: ${ cssvar('primaryColor') };
  border-radius: 4px;
  transition: .3s;
  cursor: pointer;
  &:hover {
    background-color: ${ cssvar('primaryHoverColor') };
  }
  ${ props => props.cancel && css`
    color: ${ cssvar('mainColor') };
    background-color: #ddd;
    &:hover {
      background-color: #ccc;
    }
  ` }
`