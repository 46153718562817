import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { LineChart, CartesianGrid, XAxis, YAxis, Line, ResponsiveContainer } from 'recharts'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'

const CareerInjuries = ({ injuries }) => {

  const getYear = (injury) => {
    return _.toNumber(_.split(injury.date, '-')[0])
  }
  const thisYear = _.toNumber(moment().format('YYYY'))
  const firstInjuryYear = getYear(_.last(injuries))
  const years = _.range(firstInjuryYear > thisYear - 7 ? thisYear - 7 : firstInjuryYear, thisYear + 1)
  const injuriesByYear = years.map(year => {
    const injuriesInYear = injuries.filter(injury => getYear(injury) === year)
    return {
      year,
      injuries: injuriesInYear.length
    }
  })

  const CustomLabel = ({ x, y, value }) => {
    return (
      <text x={ x } y={ y } dy={ 4 } fill={ cssvar('injuryColor') } fontSize={ 12 } textAnchor="middle">
        { value }
      </text>
    )
  }

  return (
    <LineChartContainer>
      <LineChartSubContainer>
        <ResponsiveContainer width="99%" height={ 220 }>
          <LineChart width={ 730 } height={ 250 } data={ injuriesByYear } margin={{ top: 10, right: 20, left: -40, bottom: 0 }}>
            <CartesianGrid strokeDasharray="1 1" vertical={ false } />
            <XAxis dataKey="year" tickLine={ false } axisLine={ false } tick={ { fill: cssvar('secondaryTextColor'), fontSize: 12, dy: 6 } } />
            <YAxis type="number" tickLine={ false } axisLine={ false } />
            <Line
              dataKey="injuries"
              type="monotone"
              stroke={ cssvar('injuryColor') }
              strokeWidth={ 3 }
              dot={ { stroke: cssvar('injuryColor'), strokeWidth: 2, r: 9 } }
              isAnimationActive={ false }
              label={ <CustomLabel /> } />
          </LineChart>
        </ResponsiveContainer>
      </LineChartSubContainer>
    </LineChartContainer>
  )
}

export default CareerInjuries

const LineChartContainer = styled.div`
  width: 100%;
  height: 220px;
  position: relative;
  .yAxis .recharts-cartesian-axis-tick {
    display: none;
  }
  @media (max-width: 1024px) {
    .recharts-line-curve {
      stroke-width: 2;
    }
    .recharts-line-dots circle {
      r: 8;
      stroke-width: 1;
    }
    .recharts-label-list text {
      font-size: 10px;
    }
  }
`
const LineChartSubContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`