import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'

import Detail from './Detail'
import List from './List'
import AvailabilityChart from './AvailabilityChart'
import FavouritesModal from './FavouritesModal'
import FavouriteDetail from './FavouriteDetail'
import FulltextDetail from './FulltextDetail'
import HashtagDetail from './HashtagDetail'
import StreamSkeleton from '../Sections/NewsFeed/StreamSkeleton'

import { adapterMenuList } from '../../helpers'

import getNavDrilldown from '../../redux/navDrilldown/actions'
import getNavDrilldownHeader from '../../redux/navDrilldownHeader/actions'
import { getFavourites, postFavourites, removeFavourites } from '../../redux/favourites/actions'

const Sidebar = ({
  isFavourite, tag, language, hashtag, fulltext, showAsSection,
}) => {
  const router = useHistory()
  const dispatch = useDispatch()

  const isPage = page => _.includes(router.location.pathname, page)

  if (!showAsSection && isPage('drilldown')) return null

  /**
   * State
   */
  const [currentTag, setCurrentTag] = useState(null)
  const {
    data: navDrilldownHeaderData,
    loading: navDrilldownHeaderLoading,
  } = useSelector(({ navDrilldownHeader }) => navDrilldownHeader)
  const {
    data: navDrilldownData,
    loading: navDrilldownLoading,
  } = useSelector(({ navDrilldown }) => navDrilldown)
  const {
    data: favouritesData,
    loading: favouritesLoading,
  } = useSelector(({ favourites }) => favourites)
  const { groups, sections } = adapterMenuList(isFavourite ? favouritesData : navDrilldownData)

  const { meta } = navDrilldownHeaderData

  /**
   * useEffect
   */
  useEffect(() => {
    dispatch(getNavDrilldownHeader({ tag, hashtag, fulltext }))

    if (isFavourite) {
      dispatch(getFavourites())
    } else {
      dispatch(getNavDrilldown({ tag }))
    }
  }, [tag, hashtag, fulltext, isFavourite])

  /**
   * Methods
   */
  const onEdit = (tagToEdit) => {
    setCurrentTag(tagToEdit)
  }

  const onCancel = () => {
    setCurrentTag(null)
  }

  const onDelete = (tagToDelete, { hashtagDelete }) => {
    dispatch(removeFavourites(tagToDelete, { hashtag: hashtagDelete }))
    onCancel()
  }

  const onUpdate = (tagToUpdate, opts = {}, type) => {
    const { digest, push } = opts
    dispatch(postFavourites({
      type, tag: tagToUpdate, digest, push,
    }))
    onCancel()
  }

  /**
   * Renders
   */
  const renderHeader = () => {
    if (navDrilldownHeaderLoading) {
      return (
        <div className="streams-row-wrapper no-scroll">
          <StreamSkeleton drilldownHeader />
        </div>
      )
    }

    if (isFavourite) return <FavouriteDetail data={favouritesData} />
    if (fulltext) return <FulltextDetail fulltext={fulltext} data={navDrilldownHeaderData} />
    if (hashtag) return <HashtagDetail hashtag={hashtag} data={navDrilldownHeaderData} />
    return <Detail data={navDrilldownHeaderData} tag={tag} />
  }

  const renderBody = () => {
    if (navDrilldownLoading || favouritesLoading) {
      return (
        <div className="streams-row-wrapper no-scroll">
          <StreamSkeleton drilldown />
        </div>
      )
    }

    if ((!hashtag && !fulltext) && (sections.length || (navDrilldownHeaderData && navDrilldownHeaderData.tags && navDrilldownHeaderData.tags.length))) {
      return sections.map((section, i) => (
        <List
          key={i}
          section={section}
          links={groups[section]}
          foldable={section !== 'player' && _.indexOf(sections, section) !== 0 && !isPage('favourites')}
          language={language}
          currentPageTag={tag}
          isHashtag={hashtag || section.indexOf('hashtag') > -1}
          isFullText={fulltext || section.indexOf('fulltext') > -1}
          onEdit={isFavourite && onEdit}
          onDelete={isFavourite && onDelete} />
      ))
    }
    return null
  }

  return (
    <SidebarStyled asSection={showAsSection} hideForMobile={false}>
      {!showAsSection && renderHeader()}
      <ScrollWrapper hideForMobile={!showAsSection}>
        {!navDrilldownLoading && tag && _.startsWith(tag, 'p_') && (meta ? meta.type !== 'coach' : false) && !isPage('availability') && (meta ? !!meta.team_name : false) && <AvailabilityChart tag={tag} />}
        {renderBody()}
      </ScrollWrapper>

      <FavouritesModal
        favourited
        details={_.find(favouritesData, { tag: currentTag }) ? _.find(favouritesData, { tag: currentTag }).favourite_settings : {}}
        currentTag={currentTag}
        tag={currentTag}
        cancel={onCancel}
        update={onUpdate}
        remove={onDelete} />
    </SidebarStyled>
  )
}

Sidebar.propTypes = {
  tag: PropTypes.string,
  hashtag: PropTypes.string,
  fulltext: PropTypes.string,
  isFavourite: PropTypes.bool,
  language: PropTypes.string.isRequired,
}

Sidebar.defaultProps = {
  tag: '',
  hashtag: '',
  fulltext: '',
  isFavourite: false,
}

export default Sidebar

const SidebarStyled = styled.aside`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 300px;
  background-color: #fff;
  @media (max-width: 1440px) {
    width: 275px;
  }
  @media(max-width: 1280px) {
    width: 250px;
  }
  @media (max-width: 1024px) {
    ${props => (props.hideForMobile ? 'display: none' : '')};
    width: 100%;
    min-height: ${props => (props.asSection ? '100%' : 'none')};
  }
`
const ScrollWrapper = styled.div`
  flex: 1 1 auto;
  padding-bottom: 10px;
  overflow: scroll;
  position: relative;
  @media (max-width: 1024px) {
    ${props => (props.hideForMobile ? 'display: none' : '')};
    padding-bottom: 0;
  }
`