import axios from 'axios';
import { BASE_URL } from '../api';
import { deleteAllCookies, isTokenElapsed } from '../helpers';
import history from '../history';
import Analytics from '../Analytics';
import { getUserInfo } from './profile';

function requestInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      Object.assign(config.headers, { language: localStorage.getItem('i18nextLng') });

      return config;
    },
    (error) => {
      const { response = {} } = error;
      const { status } = response;
      switch (status) {
        case 401:
          return history.push('/login');
        case 404:
          return console.log('404 res', response);
        case 500:
          return history.push('/serverError');
        case 200:
          return response;
        default:
          return response;
      }
    },
  );
}

function responseInterceptor() {
  return axios.interceptors.response.use(
    response => response,
    (error) => {
      const { response = {} } = error;
      const { status } = response;
      switch (status) {
        case 401: {
          Promise.reject(error);
          history.push('/login');
          throw new axios.Cancel('WRONG CREDENTIALS!');
        }
        case 402: {
          history.push('/login');
          Promise.reject(error);
          throw new axios.Cancel('PAYMENT REQUIRED!');
        }
        case 404: {
          Promise.reject(error);
          throw new axios.Cancel('NOT FOUND!');
        }
        case 500: {
          history.push('/serverError');
          Promise.reject(error);
          throw new axios.Cancel('SERVER ERROR!');
        }
        case 200:
          return response;
        default:
          return response;
      }
      // console.log('intercepted response error.response: ', statusText)
      // // Do something with response error
      // return response
    },
    (error) => {
      const { response = {} } = error;
      const { status } = response;
      switch (status) {
        case 401: {
          history.push('/login');
          Promise.reject(error);
          throw new axios.Cancel('NO AUTH!!!!!!!!!!');
        }
        // case 404: return Promise.reject(response)
        case 500: {
          history.push('/serverError');
          Promise.reject(response);
          throw new axios.Cancel('SERVER ERROR');
        }
        default: {
          Promise.reject(response);
          throw new axios.Cancel('ERROR ', response);
        }
        // case 200: return response
      }
      // console.log('intercepted response error.response: ', statusText)
      // // Do something with response error
      // return response
    },
  );
}

export function createCookie(name, value, days) {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  } else {
    expires = null;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
}

export function getCookie(c_name) {
  const cookies = document.cookie.match(`(^|[^;]+)\\s*${c_name}\\s*=\\s*([^;]+)`);
  return cookies ? cookies.pop() : '';
}

function saveToken(token, expires_in) {
  // save into cookie
  createCookie('token', token, 22);
  // save into localstorage
  window.localStorage.setItem('token_expiration', expires_in);
}

export function readToken() {
  // read from cookie
  return getCookie('token');
}

// function readAuth() {

// }

export function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common.authorization = token;
  } else {
    delete axios.defaults.headers.common.authorization;
  }
}

export function hasAuthorizationToken() {
  // let token = null
  // read from cookie if exist and verify it is valid
  const token = readToken();

  // TODO get value, expiration
  // TODO return !!value && expiration > ...

  return !!token && isTokenElapsed();
}
// const store = configureStore()

function initAxios() {
  requestInterceptor();
  responseInterceptor();
  if (hasAuthorizationToken()) {
    const token = getCookie('token');
    setAuthorizationToken(token);
  } else {
    const inPublicPage = window.location.pathname === '/login'
      || window.location.pathname === '/register'
      || window.location.pathname === '/recovery-password'
      || window.location.pathname === '/serverError'
      || window.location.pathname.split('/')[1] === 'ticket';
    if (!inPublicPage) {
      history.push('/login');
    }
  }
}

export function handleLogin(values) {
  const url = `${BASE_URL}/oauth/token`;

  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        username: values.username,
        password: values.password,
        client_id: '123',
        client_secret: '456',
      })
      .then((res) => {
        const { status } = res;
        // console.log('res --->', res)
        if (status === 200) {
          // set default header
          // console.log(res)
          const { data = {} } = res;
          const { access_token, expires_in } = data;
          // console.log('access_token_expires_in --->', data)
          const token = access_token;
          setAuthorizationToken(token);
          saveToken(token, expires_in);

          // Check subscription
          getUserInfo()
            .then(() => {
              // change route
              resolve({ token, expires_in, status });
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(res.data)
        }
      })
      .catch((error) => reject(error));
  });
}

export function handleLogout() {
  const url = `${BASE_URL}/oauth/logout`;

  return axios
    .get(url)
    .then((res) => {
      console.log('logged out');
      deleteAllCookies();
      delete axios.defaults.headers.common.authorization;
      Analytics.stop();
      history.push('/login');

      return res;
    })
    .catch(err => err);
}

export function recoveryPassword(values) {
  const url = `${BASE_URL}/oauth/recover-password`;
  return axios
    .post(url, { email: values.email })
    .then(res => res)
    .catch(error => console.log(error));
}

export const checkTicket = ticket => axios({
  method: 'get',
  url: `${BASE_URL}/oauth/check-ticket/${ticket}`,
});

export const resetPassword = data => axios({
  method: 'post',
  url: `${BASE_URL}/oauth/change-password`,
  data,
});

export function refreshToken(token) {
  console.log('refresh auth Token');
  const { access_token, token_type } = token;
  const url = `${BASE_URL}/oauth/refresh-token`;
  axios.post(url, { access_token, token_type });
}

initAxios();
