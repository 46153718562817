import React from 'react'
import styled from 'styled-components'

const PlayerCell = ({
  image, name, extra, extraBreakpoint, smallImage, onError,
}) => (
  <PlayerCellStyled extraBreakpoint={extraBreakpoint}>
    <PlayerRow smallImage={smallImage}>
      <img src={image} alt={name} onError={onError} />
      <span>{name}</span>
    </PlayerRow>
    {extra && <div className='extra'>{extra}</div>}
  </PlayerCellStyled>
)

export default PlayerCell

export const PlayerCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  min-width: 170px;
  @media (max-width: 1440px) {
    min-width: 110px;
  }
  .extra {
    display: none
  }
  @media(${props => props.extraBreakpoint}){
    .extra{
      margin-top: 7px;
      display: block
    }
  }
`

const PlayerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    flex: 0 0 auto;
    height: ${props => (props.smallImage ? '20px' : '24px')};
    margin-right: 10px;
  }
  @media(max-width: 1440px){
    img {
      height: ${props => (props.smallImage ? '17px' : '20px')};
      margin-right: ${props => (props.smallImage ? '10px' : '10px')};
    }
  }
  span {
    font-weight: 500;
  }
`