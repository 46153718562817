import axios from 'axios'
import { BASE_URL } from '../api'
import { readlistAdapter } from '../helpers'


export function fetchReadList() {
  const url = `${BASE_URL}/readlist/livestream`
  return axios
    .get(url, {})
    .then(result => readlistAdapter(result))
    .catch(err => console.log(err))
}


export function removeFromReadlist(id) {
  const url = `${BASE_URL}/readlist/${id}`
  return axios
    .delete(url, {})
    .then(res => res)
    .catch((err) => {
      console.log('RFRL err --->', err)
    })
}
export function addToReadList(id) {
  const url = `${BASE_URL}/readlist/${id}`
  return axios
    .post(url, {})
    .then(res => res)
    .catch((err) => {
      console.log('add to list --->', err)
    })
}
