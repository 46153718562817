import React from 'react'
import { Doughnut, Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { nFormatter } from '../../../helpers'
import DataLoading from './DataLoading'

Chart.pluginService.register({
  beforeDraw: (chart) => {
    if (chart.config.options.elements.center) {
      const ctx = chart.chart.ctx
      const centerConfig = chart.config.options.elements.center
      const fontStyle = centerConfig.fontStyle || 'Arial'
      const txt = centerConfig.text
      const color = centerConfig.color || '#000'
      const sidePadding = centerConfig.sidePadding || 20
      const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      ctx.font = `30px ${fontStyle}`
      const stringWidth = ctx.measureText(txt).width
      const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated

      // Find out how much the font can grow in width.
      const widthRatio = elementWidth / stringWidth
      const newFontSize = Math.floor(30 * widthRatio)
      const elementHeight = chart.innerRadius * 2

      // Pick a new font size so it will not be larger than the height of label.
      const fontSizeToUse = Math.min(newFontSize, elementHeight)

      // Set font settings to draw it correctly
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
      ctx.font = `${fontSizeToUse} px  ${fontStyle}`
      ctx.fillStyle = color
      // Draw text in center
      ctx.fillText(txt, centerX, centerY)
    }
  },
})

const DonutChart = ({ doughnut, loading }) => {
  const { t } = useTranslation()

  const extended = {
    tw: 'X',
    rss: t('web_article'),
    ig: 'Instagram',
  }
  const backgroundColors = {
    tw: '#d6ecfb',
    rss: '#feecd9',
    ig: '#ebe0ff',
  }
  const backgroundHoverColors = {
    tw: '#36a2eb',
    rss: '#fc6500c2',
    ig: '#9965ff',
  }
  const borderColors = {
    tw: '#36a2eb',
    rss: '#fdb976',
    ig: '#9965ff',
  }
  const hoverBorderColors = {
    tw: '#2b87c5',
    rss: '#fc6500',
    ig: '#7b52cd',
  }

  const dataSet = doughnut && {
    labels: doughnut.map(d => extended[d.key]),
    datasets: [
      {
        data: doughnut.map(d => d.doc_count),
        backgroundColor: doughnut.map(d => backgroundColors[d.key]),
        hoverBackgroundColor: doughnut.map(d => backgroundHoverColors[d.key]),
        borderColor: doughnut.map(d => borderColors[d.key]),
        hoverBorderColor: doughnut.map(d => hoverBorderColors[d.key]),
      },
    ],
  }

  const getTotal = () => {
    let sum = 0
    for (let i = 0; i < dataSet.datasets[0].data.length; i += 1) {
      sum += dataSet.datasets[0].data[i]
    }
    const text = nFormatter(sum)
    return text
  }

  const renderChart = () => {
    if (loading) return <DataLoading />

    if (dataSet) {
      return (
        <Doughnut
          data={dataSet}
          options={{
            tooltips: {
              callbacks: {
                label(tooltipItem, data) {
                  const value = data.datasets[0].data[tooltipItem.index]
                  return nFormatter(value)
                },
                title(tooltipItem, data) {
                  return data.labels[tooltipItem[0].index]
                },
              },
            },
            elements: {
              center: {
                text: getTotal(),
                color: 'black',
              },
            },
          }}
        />
      )
    }

    return <div className="no-stats">{ t('stats_not_available') }</div>
  }

  return (
    <div className="doughnut-wrapper">
      <h2 className="donut-title">{ t('analytics-social-market-share') }</h2>
      { renderChart() }
    </div>
  )
}

export default DonutChart
