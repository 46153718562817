import React from 'react'
import { withTranslation } from 'react-i18next'
import { Form, Input } from 'antd'
import { recoveryPassword } from '../../api/auth'
import Analytics from '../../Analytics'
import LoginPage, { Message, FormStyled, FormTitle, FormBody, FormItem, FormLabel, Submit, BackToLogin } from './LoginPage'

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class PasswordRecovery extends React.Component {
  state = {
    success: false,
    failure: false
  }

  handleSubmit = (e) => {
    const { form } = this.props
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        recoveryPassword(values)
          .then((res) => {
            const { data = {} } = res
            const { accepted } = data
            accepted ?
              this.setState({ success: true, failure: false })
              :
              this.setState({ failure: true })
          })
          .catch((error) => {
            console.log('Recovery password Error: ', error)
          })
      }
    })
  }

  componentDidMount() {
    const { form } = this.props
    form.validateFields()
    Analytics.pageView('/recovery-password')
  }

  componentDidUpdate(prevProps) {
    const { t, form } = this.props
    // dirty hack for errors' translation update
    if (prevProps.t !== t) {
      if (form.getFieldValue('email') === '' || !form.getFieldValue('email')) {
        form.setFieldsValue({ email: ' ' })
        form.setFieldsValue({ email: '' })
        form.validateFields(['email'])
      }
    }
  }

  render() {
    const { form, t } = this.props
    const { success, failure } = this.state
    const { getFieldError, getFieldDecorator, isFieldTouched, getFieldsError } = form
    const emailError = isFieldTouched('email') && getFieldError('email')
    return (
      <LoginPage>

        <Message show={ failure }>{ t('recover_password_mail_not_found') }</Message>
        <Message success show={ success }>{ t('recover_password_check_your_mailbox') }</Message>

        <FormStyled layout="inline" onSubmit={ this.handleSubmit }>
          <FormTitle>{ t('recovery_request_recover_password') }</FormTitle>
          <FormBody>
            <FormItem validateStatus={ emailError && 'error' }>
              <FormLabel>{ t('login_email') }</FormLabel>
              { getFieldDecorator('email', { rules: [{ required: true, message: t('email_required') }] })(
                <Input />
              )}
            </FormItem>
            <Submit type="submit" disabled={ hasErrors(getFieldsError()) }>
              { t('recovery_request_recover_password') }
            </Submit>
          </FormBody>
          <BackToLogin href="/login">{ t('back_to_login') }</BackToLogin>
        </FormStyled>
      </LoginPage>
    )
  }
}

const WrappedPasswordRecovery = Form.create()(PasswordRecovery)
export default withTranslation('translation')(WrappedPasswordRecovery)
