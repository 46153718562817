import React, {
  useState, useEffect, useRef,
} from 'react'
import { Router, Route, useHistory } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import _ from 'lodash'
import i18next from '../../../i18n/index'
import T from '../../Generic/T'
import ScrollToTop from '../../../utils/ScrollToTop'
import { hasInjuriesPermissionSelector } from '../../../redux/selectors/user'

import Sidebar from '../../Sidebar'
import Toolbar from '../../Toolbar'

import Injury from '../Injury'
import AvailabilityPanel from '../Availability'
import OtherEvents from '../OtherEvents'
import Video from '../Video'
import Biography from '../Biography'
import Roster from '../Roster'
import NationalRoster from '../Roster/national'
import TeamInjuries from '../Roster/injuries'
import CompetitionInjuries from '../Competition/injuries'
import NewsFeed from '../NewsFeed'
import LiveStats from '../LiveStats/LiveStats'

import CircularProgress from '@material-ui/core/CircularProgress'
import styled, { css } from 'styled-components/macro'
import { cssvar } from '../../../styles/var'
import OngoingCompetitionInjuries from '../Competition/ongoingInjuries'

const ID_FROM_TAG_REGEX = /p_(.+)/

const Explore = ({
  match, location, navDrilldownHeader, history,
}) => {
  const router = useHistory()
  const contentRef = useRef(null)
  const hasInjuriesPermission = useSelector(hasInjuriesPermissionSelector)
  const {
    params = {}, data, path, url,
  } = match
  const {
    tag, starred, hashtag, fulltext,
  } = params
  const navDrilldownHeaderData = navDrilldownHeader.data || {}
  const navDrilldownHeaderLoading = navDrilldownHeader.loading
  const {
    bio, injuries, type, name, options,
  } = navDrilldownHeaderData

  const playerId = tag
    && tag.match(ID_FROM_TAG_REGEX)
    && tag.match(ID_FROM_TAG_REGEX).length
    && Number(tag.match(ID_FROM_TAG_REGEX)[1])

  const [language, setLanguage] = useState(i18next.language)
  i18next.on('languageChanged', (lang) => {
    setLanguage(lang)
  })


  useEffect(() => {
    if (path === '/explore') return router.replace(`${url}/world`)
    if (location.pathname !== url && !_.includes(location.pathname, 'news')) return
    return router.replace(`${url}/${_.filter(explorePages, p => p.link !== 'bio')[0].link}`)
  }, [navDrilldownHeaderData])

  const hasInjuriesStats = options ? options.has_injuries_stats === true : false

  const explorePages = [
    {
      link: 'injuries',
      name: <T i18nKey="tab_injuries" />,
      condition: type === 'profile' && injuries && hasInjuriesPermission,
      component: () => (
        <Injury
          coverageStatus={options.coverage_status}
          coverageProgress={options.coverage_progress}
          seasons={navDrilldownHeaderData.seasons}
          data={injuries}
          playerName={name}
          playerId={playerId} />
      ),
    },
    {
      link: 'avatar',
      name: <T i18nKey="tab_avatar" />,
      condition: type === 'profile' && injuries && injuries.length && hasInjuriesPermission,
      onlyDevices: true,
      component: () => <Injury data={injuries} playerName={name} seasons={navDrilldownHeaderData.seasons} bodyOnly />,
    },
    {
      link: 'video',
      name: <T i18nKey="tab_media" />,
      condition:
        type === 'profile'
        && injuries
        && injuries.length
        && injuries.filter(({ media }) => media && !_.isEmpty(media)).length
        && hasInjuriesPermission,
      component: () => <Video data={injuries} />,
    },
    {
      link: 'other_events',
      name: <T i18nKey="Other events" />,
      condition: type === 'profile' && hasInjuriesPermission && injuries.filter(({ other_event }) => other_event).length, // Add check on other events count
      component: () => (
        <OtherEvents
          tag={tag}
          coverageStatus={options.coverage_status}
          coverageProgress={options.coverage_progress}
          seasons={navDrilldownHeaderData.seasons}
          data={injuries.filter(({ other_event }) => other_event)}
          playerName={name}
          playerId={playerId} />
      ),
    },
    {
      link: 'availability',
      name: <T i18nKey="Availability" />,
      condition: type === 'profile' && hasInjuriesPermission,
      component: () => <AvailabilityPanel tag={tag} injuries={injuries} />,
    },
    {
      link: 'roster',
      name: <T i18nKey="tab_roster" />,
      condition: type === 'team' && hasInjuriesPermission,
      component: () => <Roster data={navDrilldownHeaderData} />,
    },
    {
      link: 'callup',
      name: <T i18nKey="tab_roster" />,
      condition: type === 'national_team' && options && options.has_callups,
      component: () => <NationalRoster data={navDrilldownHeaderData} />,
    },
    {
      link: 'team_injuries',
      name: <T i18nKey="tab_injuries" />,
      condition: type === 'team' && hasInjuriesPermission,
      component: () => <TeamInjuries data={navDrilldownHeaderData} />,
    },
    {
      link: 'competition_injuries',
      name: <T i18nKey="tab_injuries" />,
      condition: type === 'competition' && hasInjuriesStats && hasInjuriesPermission,
      component: () => <CompetitionInjuries data={navDrilldownHeaderData} />,
    },
    {
      link: 'competition_ongoing_injuries',
      name: <T i18nKey="tab_ongoing_injuries" />,
      condition: type === 'competition' && hasInjuriesStats && hasInjuriesPermission,
      component: () => <OngoingCompetitionInjuries data={navDrilldownHeaderData} />,
    },
    {
      link: 'bio',
      name: <T i18nKey="tab_biography" />,
      condition: type === 'profile' && bio,
      notResponsive: false,
      component: () => <Biography data={navDrilldownHeaderData} />,
    },
    {
      link: 'news',
      name: <T i18nKey="tab_details" />,
      condition: true,
      component: () => (
        <NewsFeed
          tag={tag === 'world' ? null : tag}
          starred={starred}
          hashtag={hashtag}
          isFavourite={false}
          name={name}
          fulltext={fulltext}
          language={language} />
      ),
    },
    {
      link: 'media_stats',
      name: <T i18nKey="tab_analytics" />,
      condition: true,
      notResponsive: true,
      component: () => (
        <LiveStats
          tag={tag === 'world' ? null : tag}
          hashtag={hashtag}
          name={name}
          fulltext={fulltext}
          isFavourite={false} />
      ),
    },
  ].filter(page => page.condition)

  return (
    <PageContainer>
      <Sidebar
        data={data}
        tag={tag === 'world' ? null : tag}
        hashtag={hashtag}
        fulltext={fulltext}
        language={language} />
      {navDrilldownHeaderLoading ? (
        <ContentContainer>
          <ProgressWrapper>
            <CircularProgress />
          </ProgressWrapper>
        </ContentContainer>
      ) : (
        <ContentContainer forNews={_.includes(location.pathname, 'news')}>
          <Toolbar
            links={explorePages}
            params={params}
            linkURL={url}
            pathname={location.pathname}
            fulltext={fulltext}
            language={language}
            scrollRef={contentRef}
          />
          <Content>
            <ScrollToTop componentRef={contentRef} />
            <Router history={history}>
              {explorePages.map((page, i) => (
                <Route key={i} path={`${path}${page.link}`} render={() => page.component()} />
              ))}
            </Router>
          </Content>
        </ContentContainer>
      )}
    </PageContainer>
  )
}

const mapStateToProps = ({ navDrilldownHeader }) => ({
  navDrilldownHeader,
})
export default connect(mapStateToProps)(Explore)

const PageContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const ContentContainer = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  ${props => !props.forNews && css`
    @media (max-width: 1024px) {
      height: auto;
    }
  `}
  @media (display-mode: standalone) {
    background-color: ${cssvar('mainColor')};
  }
`
const Content = styled.div`
  flex: 1 1 auto;
  background-color: ${cssvar('background')};
  overflow: scroll;
  padding: 0 max(0px, calc(calc(100% - 1440px) / 2));
`
const ProgressWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
