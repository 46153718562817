import axios from 'axios';
import { BASE_URL } from '../api';
import { handleLogin } from './auth';

export function handleRegistration(values = {}) {
  const url = `${BASE_URL}/oauth/register`;
  return axios
    .post(url, values).then((res) => {
      if (res.status === 200) {
        const {
          email,
          password,
        } = values;
        const params = {
          username: email,
          password,
        };
        handleLogin(params);
      }
    }).catch(err => console.log('registration failure', err));
}
