import axios from 'axios'
import { adapterAutocompleteData } from '../helpers'
import { BASE_URL } from '../api'

export function fetchAutocompleteData(string) {
  const url = `${BASE_URL}/autocomplete?q=${string}`

  return axios
    .get(url)
    .then(res => adapterAutocompleteData(res))
    .catch((error) => {
      console.log(`Errore : ${error} while retrieving autocomplete suggestions`)
    })
}
