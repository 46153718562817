import React, { Component } from 'react'
import { DatePicker } from 'antd'
import _zipObject from 'lodash/zipObject'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import 'moment/locale/it'
import 'moment/locale/es'
import 'moment/locale/en-gb'
import { connect } from 'react-redux'
import { ActiveTimeFilterOption, setActiveTimeFilter, setFilter } from '../../redux/ducks/filters'
import styled, { createGlobalStyle } from 'styled-components'
import { cssvar } from '../../styles/var'

function getKeys(t) {
  return [
    t('calendar-today'),
    t('calendar-yesterday'),
    t('calendar-last-30-days'),
    t('calendar-this-month'),
    t('calendar-last-month'),
    t('calendar-last-three-months'),
    t('calendar-last-six-months'),
    t('calendar-last-year'),
    t('calendar-all'),
  ]
}

function getRanges(t, today) {
  const yesterday = today.clone().subtract(1, 'days')
  const thirtyDaysAgo = today.clone().subtract(30, 'days')
  const oneMonthAgo = today.clone().subtract(1, 'months')
  const threeMonthAgo = today.clone().subtract(3, 'months')
  const sixMonthsAgo = today.clone().subtract(6, 'months')
  const oneYearsAgo = today.clone().subtract(1, 'year')
  const all = moment('2010/01/01')

  const values = [
    [
      today.clone().startOf('day'),
      today.clone().endOf('day'),
    ],
    [yesterday.clone().startOf('day'), yesterday.clone().endOf('day')],
    [thirtyDaysAgo.startOf('day'), today],
    [moment().startOf('month'), moment().endOf('month')],
    [oneMonthAgo.clone().startOf('month'), oneMonthAgo.clone().endOf('month')],
    [threeMonthAgo.clone().startOf('month'), today],
    [sixMonthsAgo.clone().startOf('month'), today],
    [oneYearsAgo.clone().startOf('month'), today],
    [all, today],
  ]

  const keys = getKeys(t)

  return _zipObject(keys, values)
}

class DatePickerWrapper extends Component {
  componentDidUpdate(prevProps) {
    const { currentLanguage } = this.props
    if (prevProps.currentLanguage !== currentLanguage) {
      const lan = currentLanguage.indexOf('en') > -1 ? 'en-gb' : currentLanguage.substr(0, 2)
      moment.locale(lan)
    }
  }

  onChange = (dates) => {
    const { setFilter, applyUpdate, handleDates } = this.props
    setFilter('from', dates[0].startOf('day').format())
    setFilter('to', dates[1].endOf('day').format())
    handleDates(dates)
    applyUpdate(true)
  }

  disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  render() {
    const {
      currentLanguage, t, dates, defaultDates,
    } = this.props
    const lan = currentLanguage.indexOf('en') > -1 ? 'en-gb' : currentLanguage.substr(0, 2)
    moment.locale(lan)

    const today = moment()
    const ranges = getRanges(t, today)

    const placeholderLength = t('range_picker_start_date').length + t('range_picker_end_date').length

    const width = Math.max(200, placeholderLength * 8 + 50)


    return (
      <PickerWrapper>
        <DatePicker.RangePicker
          allowClear={false}
          defaultValue={defaultDates}
          onChange={this.onChange}
          ranges={ranges}
          format="DD.MM.YYYY"
          disabledDate={this.disabledDate}
          value={this.props.filters.activeTimeFilter === ActiveTimeFilterOption.range ? dates || defaultDates : undefined}
          dropdownClassName="nf-datepicker"
          placeholder={[t('range_picker_start_date'), t('range_picker_end_date')]}
          style={{ width: `${width}px` }}
        />
        <DatePickerStyles />
      </PickerWrapper>
    )
  }
}


const mapStateToProps = ({ filters, navDrilldownHeader }) => ({
  filters,
  navDrilldownHeader,
})

function mapDispatchToProps(dispatch) {
  return {
    setFilter: (key, value) => {
      const action = setFilter({ id: key, data: value })
      dispatch(action)
      if (process.env.REACT_APP_ENV !== 'production') {
        dispatch(setActiveTimeFilter(ActiveTimeFilterOption.range))
      }
    },
  }
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(DatePickerWrapper)
const TranslatedComponent = withTranslation('translation')(ConnectedComponent)
export default TranslatedComponent

const PickerWrapper = styled.div`
  .ant-calendar-picker {
    width: 220px;
    cursor: pointer;
  }
  .ant-calendar-picker-input {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    align-items: center;
    justify-content: center;
  }
  .ant-calendar-range-picker-input {
    width: inherit;
    cursor: pointer;
  }
  .ant-calendar-picker-icon {
    display: none;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
const DatePickerStyles = createGlobalStyle`
  .nf-datepicker {
    .ant-calendar {
      transform: translateY(40px);
    }
    .ant-tag-blue {
      font-size: 14px;
      color: ${cssvar('primaryColor')};
      border-color: ${cssvar('primaryColor')};
      background-color: transparent;
      cursor: pointer;
    }
    .ant-calendar-selected-day .ant-calendar-date {
      background-color: ${cssvar('primaryColor')};
      &:hover {
        background-color: ${cssvar('primaryHoverColor')};
      }
    }
    .ant-calendar-in-range-cell:before {
      background-color: #f0f8fb;
    }
    .ant-calendar-date:hover {
      background-color: rgba(63, 140, 202, .17);
    }
    .ant-calendar-input-wrap, .ant-calendar-range-middle {
      display: none;
    }
  }
`
