import React from 'react'
import T from '../../Generic/T'
import styled, { keyframes } from 'styled-components'
import { cssvar } from '../../../styles/var'


class Notification extends React.Component {
  render() {
    const { addNewStreams, count } = this.props

    return (
      <NotificationStyled onClick={ () => addNewStreams() }>
        <T i18nKey='new-posts-to-display' />
        { count && ` (${count})` }
      </NotificationStyled>
    )
  }
}
export default Notification

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
`
const NotificationStyled = styled.div`
  animation: ${ fadeIn } .3s forwards ease-in;
  padding: 7px 30px;
  font-weight: 520;
  color: #fff;
  background-color: ${ cssvar('primaryColor') };
  border-radius: 50px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background-color: ${ cssvar('primaryHoverColor') };
  }
  @media (max-width: 640px) {
    padding: 5px 25px;
  }
`