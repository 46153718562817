import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactSVG from 'react-svg'

import tw from '../../../images/Twitter.svg'
import fb from '../../../images/Facebook.svg'
import ig from '../../../images/Instagram.svg'
import rss from '../../../images/Rss.svg'

import { toggleFilter } from '../../../redux/ducks/filters'
import styled, { css } from 'styled-components'

const socials = [
  { value: 'tw', icon: tw, color: '#0f0f0f' },
  { value: 'ig', icon: ig, color: '#E4405F' },
  { value: 'rss', icon: rss, color: '#DB7D36' },
]

const SocialFilterBar = ({ filter, updateFilter, filters }) => {
  const handleSocialClick = social => () => { updateFilter(filter, social) }

  return (
    <Fragment>
      <Socials>
        { socials.map(social => (
          <Social
            key={`social-filter-${social.value}`}
            active={filters[filter].includes(social.value)}
            color={social.color}
            onClick={handleSocialClick(social.value)}>
            <ReactSVG src={social.icon} />
          </Social>
        )) }
      </Socials>
    </Fragment>
  )
}


SocialFilterBar.propTypes = {
  filter: PropTypes.oneOf(['fromSocials', 'aboutSocials', 'analyticsSocials']).isRequired,
  updateFilter: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string,
    category: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.arrayOf(PropTypes.string),
    lang: PropTypes.arrayOf(PropTypes.string),
    applyUpdate: PropTypes.bool,
    fromSocials: PropTypes.arrayOf(PropTypes.string),
    aboutSocials: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

const mapStateToProps = ({ filters }) => ({ filters })

const mapDispatchToProps = dispatch => ({
  updateFilter: (key, value) => {
    const action = toggleFilter({ id: key, data: value })
    dispatch(action)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialFilterBar)

const Socials = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 15px;
  @media (max-width: 640px) {
    gap: 10px;
  }
`
const Social = styled.div`
  width: 20px;
  height: 20px;
  line-height: 1;
  cursor: pointer;
  svg {
    width: 20px;
    * {
      fill: ${({ active, color }) => (active ? color : '#ccc')};
      transition: .2s;
    }
  }
  @media (max-width: 640px) {
    width: 16px;
    height: 16px;
    svg {
      width: 16px;
    }
  }
  ${props => !props.active && css`
    &:hover svg * {
      fill: #aaa;
    }
  `}
`