import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import InjuryIcon from '../../../images/hospital.svg'
import BenchIcon from '../../../images/bench.svg'
import SkippedIcon from '../../../images/no-waiting.svg'
import TeamPlaceholder from '../../../images/team-placeholder.svg'
import DopingIconLight from '../../../images/dopingPillLight.svg'

import styled, { css } from 'styled-components'

const barColors = (minutes) => {
  if (minutes < 15) return '#235780'
  if (minutes < 30) return '#2b699a'
  if (minutes < 45) return '#327bb5'
  if (minutes < 60) return '#3f8cca'
  if (minutes < 80) return '#5a9cd1'
  return '#74acd9'
}


const barBackgroundColor = (injuredAtMatch, skippedReason, injury) => {
  if (injury && !!injury.other_event) {
    const category = injury.injury_category
    if (category === 'doping') return '#FFBC79'
  }

  if (injuredAtMatch || skippedReason === 'injury') {
    return '#f5cccf'
  }

  return '#f7f7f7'
}

const BottomIcon = ({
  skippedReason, label, minutesPlayed, injury,
}) => {
  const icon = () => {
    if (injury && !!injury.other_event) {
      const category = injury.injury_category
      if (category === 'doping') return DopingIconLight
    }
    if (skippedReason === 'injury') return InjuryIcon
    if (minutesPlayed === null) return SkippedIcon
    if (minutesPlayed === 0) return BenchIcon
  }
  if (skippedReason === 'injury' || !minutesPlayed) {
    return <SkipIcon icon={icon()} />
  }
  return (
    <MinutesPlayed>
      {label < 0 ? 1 : label}
      '
    </MinutesPlayed>
  )
}

const Bar = ({
  opponentLogo, opponentName, skippedReason, minutesPlayed, date, injuredAtMatch, mini, matches, duration: matchDuration, injury,
}) => {
  const minutes = Math.min(minutesPlayed, matchDuration)
  const label = minutesPlayed || '-'
  const Wrapper = mini ? ChartBarWrapperMini : ChartBarWrapper

  const chartBarTeamLogo = <ChartBarTeamLogo src={opponentLogo || TeamPlaceholder} alt={opponentName} />
  const bottomIcon = <BottomIcon label={label} minutesPlayed={minutesPlayed} skippedReason={skippedReason} injury={injury} />

  return (
    <Wrapper matches={matches}>
      <ChartBar backgroundColor={barBackgroundColor(injuredAtMatch, skippedReason, injury)} mini={mini}>
        <ChartBarValue
          injuredAtMatch={injuredAtMatch}
          color={barColors(minutes)}
          height={`${minutes / matchDuration * 100}%`}
          minutes={minutes}
        />
      </ChartBar>
      {chartBarTeamLogo}
      {bottomIcon}
      <ChartBarTooltip>
        <ChartBarTooltipInfo>
          {moment(date).format('DD.MM.YYYY')}
          <br />
          <b>{opponentName}</b>
        </ChartBarTooltipInfo>
        <ChartBarTooltipLogoWrapper>
          {chartBarTeamLogo}
          {bottomIcon}
        </ChartBarTooltipLogoWrapper>
      </ChartBarTooltip>
    </Wrapper>
  )
}

const AvailabilityChart = ({ matches, mini }) => {
  const orderedMatches = mini ? matches : _.reverse(matches)
  if (mini) {
    return (
      <>
        <MiniWidget>
          {orderedMatches.map(match => (
            <Bar key={match.id} {...match} mini matches={orderedMatches.length} />
          ))}
        </MiniWidget>
      </>
    )
  }
  return (
    <Centerer>
      <Widget matches={orderedMatches.length}>
        {orderedMatches.map(match => <Bar key={match.id} {...match} matches={orderedMatches.length} />)}
      </Widget>
    </Centerer>
  )
}

export default AvailabilityChart

const Centerer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Widget = styled.div`
  display: flex;
  gap: 12px;
  max-width: 100%;
  padding: 0 10px 10px;
  overflow-x: ${props => (props.matches > 8 ? 'scroll' : 'visible')};
  direction: rtl;
`
const MiniWidget = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0;
  padding: 15px;
`
const ChartBar = styled.div`
  width: 10px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${props => props.backgroundColor};
  border-radius: 20px;
  @media (max-width: 640px) {
    height: 150px;
  }
  ${props => props.mini && 'height: 130px'};
`
const ChartBarValue = styled.div`
  width: 100%;
  height: ${props => props.height};
  background-color: ${props => props.color};
  border-radius: 20px;
  position: relative;
  ${props => (props.injuredAtMatch) && (props.injuredAtMatch !== 'match_warmup' || !props.minutes) && css`
    &:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background-image: url(${InjuryIcon});
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      border: 2px solid #ca3f3f;
      position: absolute;
      ${props => (props.injuredAtMatch !== 'match_warmup' ? 'top:0;' : 'bottom:0;')}
      left: 50%;
      transform: translateX(-50%);
    }
  `}
`
const ChartBarTeamLogo = styled.img`
  width: 23px;
  height: 23px;
  margin-top: 8px;
  margin-bottom: 5px;
`
const MinutesPlayed = styled.div`
  margin-top: 2px;
  margin-bottom: 1px;
  font-size: 12px;
  line-height: 1;
`
const SkipIcon = styled.div`
  width: 15px;
  height: 15px;
  background-image: url(${props => props.icon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
const ChartBarTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0,0,0, .3);
  border-radius: 4px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  opacity: 0;
  transition: .3s;
  pointer-events: none;
  white-space: nowrap;
  z-index: 3;
`
const ChartBarTooltipInfo = styled.div`
  display:block;
  font-size: 12px;
  margin-bottom: -2px;
  b {
    font-weight: 600;
  }
`
const ChartBarTooltipLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const ChartBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: ltr;
  position: relative;
  &${props => (props.matches > 8 ? ':nth-last-child(-n+3)' : ':last-child')} ${ChartBarTooltip} {
    align-items: flex-start;
    text-align: left;
    left: -10px;
    transform: none;
  }
  &${props => (props.matches > 8 ? ':nth-child(-n+3)' : ':first-child')} ${ChartBarTooltip} {
    align-items: flex-end;
    text-align: right;
    left: auto;
    right: -10px;
    transform: none;
  }
  &:hover ${ChartBarTooltip} {
    opacity: 1;
  }
`
const ChartBarWrapperMini = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &${props => (props.matches > 5 ? ':nth-child(-n+3)' : ':first-child')} ${ChartBarTooltip} {
    align-items: flex-start;
    text-align: left;
    left: -10px;
    transform: none;
  }
  &${props => (props.matches > 5 ? ':nth-last-child(-n+3)' : props.matches > 1 ? ':last-child' : '.dont')} ${ChartBarTooltip} {
    align-items: flex-end;
    text-align: right;
    left: auto;
    right: -10px;
    transform: none;
  }
  &:hover ${ChartBarTooltip} {
    opacity: 1;
  }
  @media (max-width: 1280px) {
    &:first-child {
      display: none;
    }
  }
`