import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setPlayersFilter } from '../../redux/ducks/filters'
import { Icon, Select } from 'antd'
import ListItem from './ListItem'
import styled, { css } from 'styled-components'
import { cssvar } from '../../styles/var'

const List = ({ section, links, foldable, isHashtag, isFulltext, onEdit, onDelete, filters, setPlayersFilter, currentPageTag }) => {
  const [fold, setFold] = useState(foldable ? true : false)
  const { t } = useTranslation()
  return (
    <ListSection>
      <ListSectionTitle foldable={ foldable } onClick={ () => foldable ? setFold(!fold) : null }>
        { t(section) }
        { foldable && <Icon type={ fold ? 'down' : 'up' } /> }
        { section === 'player' && (
          <Select
            onChange={ value => setPlayersFilter(value) }
            defaultValue={ filters.playersFilter }
            className="list-select">
            <Select.Option value="all">
              <PlayerFilterOption>{ t('all') }</PlayerFilterOption>
            </Select.Option>
            <Select.Option value="injured">
              <PlayerFilterOption>
                { t('injured-players') } ({ _.filter(links, link => !!link.current_injury).length })
              </PlayerFilterOption>
            </Select.Option>
            <Select.Option value="not_injured">
              <PlayerFilterOption>
                { t('not-injured-players') } ({ _.filter(links, link => !link.current_injury).length })
              </PlayerFilterOption>
            </Select.Option>
          </Select>
        ) }
      </ListSectionTitle>
      { links.map(link => {
        const { tag, image, label, current_injury } = link
        if (fold) return null
        if (section === 'player' && filters.playersFilter === 'injured' && !current_injury) return null
        if (section === 'player' && filters.playersFilter === 'not_injured' && !!current_injury) return null
        return (
          <ListItem
            key={ `${label}-${tag}` }
            tag={ tag }
            image={ image }
            label={ label }
            currentInjury={ current_injury }
            isHashtag={ isHashtag }
            isFulltext={ isFulltext }
            onEdit={ onEdit }
            onDelete={ onDelete }
            currentPageTag={ currentPageTag } />
        )
      }) }
    </ListSection>
  )
}

function mapStateToProps({ filters }) {
  return { filters }
}

function mapDispatchToProps(dispatch) {
  return {
    setPlayersFilter: value => {
      const action = setPlayersFilter(value)
      dispatch(action)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List)

const ListSection = styled.div`
  position: relative;
`
export const ListSectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
  padding-left: 15px;
  background-color: #fafafa;
  border-bottom: 1px solid #e6e6e6;
  color: #9e9e9e;
  font-size: 13px;
  font-weight: 650;
  letter-spacing: .3px;
  text-transform: uppercase;
  transition: .3s;
  position: sticky;
  top: 0;
  z-index: 2;
  ${ props => props.foldable && css`
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  ` }
  @media (max-width: 1280px) {
    font-size: 12px;
  }
  @media (max-width: 1024px) {
    background-color: #f5f5f5;
    color: #333;
    font-size: 14px;
  }
  .list-select {
    width: 170px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    transition: .3s;
    @media (max-width: 1440px) {
      width: 150px;
    }
    @media (max-width: 1280px) {
      width: 130px;
    }
    &:hover {
      border-color: ${ cssvar('primaryColor') };
    }
    .ant-select-selection {
      height: 24px;
      border: none;
      box-shadow: none;
    }
    .ant-select-selection__rendered {
      margin: 0;
      height: 24px;
      line-height: normal;
    }
    .ant-select-selection-selected-value {
      margin-left: 10px;
      color: ${ cssvar('mainColor') };
      font-weight: 650;
      letter-spacing: .3px;
      line-height: 24px;
    }
  }
`
const PlayerFilterOption = styled.div`
  font-size: 11px;
  @media (max-width: 1280px) {
    font-size: 10px;
  }
`