import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Icon } from 'antd'
import {
  postReadList as postReadListAction,
  removeReadList as removeReadListAction,
} from '../../../redux/readList/actions'
import styled from 'styled-components'


const ToggleFromReadlist = ({ id, readList, postReadList, removeReadList }) => {
  const inReadList = () => readList.indexOf(id) >= 0

  const handleClick = () => {
    if (inReadList()) {
      removeReadList(id)
    } else {
      postReadList(id)
    }
  }

  return (
    <IconWrapper onClick={ handleClick } saved={ inReadList() }>
      <IconStyled type="read" theme={ inReadList() ? 'filled' : 'outlined' } />
    </IconWrapper>
  )
}

ToggleFromReadlist.propTypes = {
  id: PropTypes.string.isRequired,
  readList: PropTypes.arrayOf(PropTypes.string).isRequired,
  postReadList: PropTypes.func.isRequired,
  removeReadList: PropTypes.func.isRequired
}

const mapStateToProps = ({ readList: { data: readList } }) => ({ readList })

const mapDispatchToProps = dispatch => ({
  postReadList: bindActionCreators(postReadListAction, dispatch),
  removeReadList: bindActionCreators(removeReadListAction, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ToggleFromReadlist)


const IconWrapper = styled.div`
  height: 16px;
  line-height: 16px;
  margin: 0 10px;
  cursor: pointer;
`
const IconStyled = styled(Icon)`
  vertical-align: middle;
  font-size: 16px;
  color: ${ ({ theme }) => theme === 'filled' ? '#13bee8' : '#fff' };
  cursor: pointer;
  transition: .3s;
  &:hover {
    color: #13bee8;
  }
`